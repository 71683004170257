<template>
  <!-- Modal cadastro -->
  <v-dialog v-model="mostralModal" persistent min-width="300px" max-width="90%">
    <div >
      <v-card>
        <v-card-text>
          <v-container>
            <v-row v-if="mostrarCarregamento">
                 
              <div  class="loading text-center">
                <h1 class="primary--text">Recuperando os pareceres...</h1>
                <v-progress-circular
                  :size="120"
                  :width="8"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </div>
            </v-row>
            <v-row v-if="!mostrarCarregamento">
              <v-col class="altura-fixa" cols="12">
                <!-- <span class="headline"> Adicionar parecer </span> -->
                <div>
                  <h2 class="mb-2">
                    <strong> Atendimento: </strong> <em class="mr-3">{{ model.id }}</em>  
                    <v-chip
                    class="ma-1 ml-0"
                    :color="obterCorPorStatus(model.status)"
                    text-color="white"
                  >
                     {{model.status.descricao}}
                  </v-chip>        
                  </h2>
      
                  <h4>
                   <strong> Cadastrado em: </strong> <em>{{ model.criadoEm | data }}</em>
                  </h4>
                  <h4>
                   <strong> Solicitante: </strong> <em>{{ model.solicitante.nome }}</em>
                  </h4>
                  <h4>
                    <strong> Resumo: </strong> <em>{{ model.resumo }}</em>
                  </h4>

                </div>
                <v-divider></v-divider>
              </v-col>

              <v-col v-if="model.pareceres.length" cols="12" xs="12" sm="12">
                <v-timeline  dense clipped>
                  <v-timeline-item
                    v-for="parecer in model.pareceres"
                    :key="parecer.id"
                    color="primary"
                    small
                  >
                    <template v-slot:append>
                      <v-btn class="mx-0" depressed> Post </v-btn>
                    </template>
                    <template v-slot:opposite>
                      <span
                        :class="`headline font-weight-bold ${years[0].color}--text`"
                        >{{ parecer.criadoEm | data }}
                      </span>
                    </template>
                    <v-card class="elevation-2">
                      <v-card-title :class="`headline ${!!(isDark === 'dark')
                        ? 'fundo-dark' : years[0].color + 'blue-grey mb-4 lighten-2'}`">
                        <h6 :class="`font-weight-light`">
                          <h3>
                            Data do contato: {{ parecer.criadoEm | data }}
                          </h3>
                        </h6>
                      </v-card-title>
                      <v-card-text>
                        <h4 class="mb-0">
                          <strong> Usuário: </strong>
                          {{ parecer.usuario.email }}
                        </h4>
                        <h4 class="mb-0">
                          <strong> Pessoa de contato: </strong>
                          <em>{{ parecer.pessoaDeContato }}</em>
                        </h4>

                        <v-divider></v-divider>
                        <div class="mt-5">
                          {{ parecer.descricao }}
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-timeline-item>
                </v-timeline>
              </v-col>

              <v-col v-if="!model.pareceres.length" class="mt-2" cols="12">
                <div class="text-center" >
                  <h2>Não foram encontrados pareceres para este atendimento.</h2>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn  
          elevation="0"
            @click="fecharModal()"
            >Fechar</v-btn
          >
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import AtendimentoModel from "../model/atendimento-model";
import moment from "moment";
import mixinGeral from "../mixin/mixin-geral";

export default {
  name: "VisualizacaoDePareceres",
  mixins:[mixinGeral],
  model: {
    prop: "itemSelecionado",
    event: "onChange",
  },
  props: {
    itemSelecionado: {
      type: AtendimentoModel,
      default: () => new AtendimentoModel(),
    },
    visivel: { type: Boolean, default: false },
    mostrarCarregamento: { type: Boolean, default: true },
  },
  watch: {
    itemSelecionado() {
      this.model = this.itemSelecionado;
      this.$emit("onChange", this.model);
    },

    visivel(){
      this.mostralModal = this.visivel;
    }
  },
  filters: {
    data(valor) {
      return moment(valor).format("DD/MM/YYYY");
    },
  },

  data() {
    return {
      model: this.itemSelecionado,
      mostralModal: this.visivel,
      
      years: [
        {
          color: "cyan",
          year: "1960",
        },
        {
          color: "green",
          year: "1970",
        },
        {
          color: "pink",
          year: "1980",
        },
        {
          color: "amber",
          year: "1990",
        },
        {
          color: "orange",
          year: "2000",
        },
      ],
    };
  },
  computed: {},
  components: {},
  created() {},
  methods: {
    emitirAtendimento() {
      this.$emit("onChange", this.model ? this.model : undefined);
    },
    fecharModal() {
     
      this.mostralModal = false;
      this.$emit("fechar-modal", this.mostralModal);
    },
      obterCorPorStatus(status) {
      switch (parseInt(status.id)) {
        case 1:
          return "grey";
        case 2:
          return "blue";
        case 3:
          return "green";
        case 4:
          return "orange";
        case 5:
          return "red";
      }
    },
  },
};
</script>
<style lang="scss"  >
div.container {
  padding-top: 0;
}
.altura-fixa {
  /* position: fixed;
  z-index: 9; */
  background-color: #fff;

  /* width: 500px; */
  padding-top: 20px;
}
.theme--dark .altura-fixa{
  background: transparent;
}

.altura-time-line {
  margin-top: 100px;
}
</style>
