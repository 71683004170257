import axios from 'axios';

const URL_BASE = "https://viacep.com.br/ws";

function obterEndereco(cep) {
  cep = cep || "";
  cep = cep.replace('-','').trim();
  return new Promise((resolve, reject) => {
    axios.get(`${URL_BASE}/${cep}/json/`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}



export default {
  obterEndereco 
};
