<template>
  <v-layout color="transparent" mt-0 :id="id">
    <v-autocomplete
      :hide-no-data="desabilitarMensagemNaoHaDados"
      :id="id ? 'autocomplete-pessoa-' + id : id"
      :class="classeId ? 'autocomplete-pessoa-' + classeId : classeId"
      @change="emitirItem"
      v-model="model"
      :items="items"
      :loading="isLoading"
      :search-input.sync="search"
      :disabled="desabilitar"
      :label="label"
      :placeholder="placeholder"
      :item-text="atributoExibicao"
      return-object
      @keyup="onKeyUp"
      auto-select-first
      clearable
      :autofocus="focus"
      ref="inputPessoa"
      autocomplete="off"
      outlined
      dense
    >
      <template v-slot:append-outer>
        <v-tooltip bottom v-if="true">
          <template v-slot:activator="{ on }">
  
            <v-icon
              :id="id ? 'icon-novo-atendimento-' + id : id"
              class="mr-1 "
             
              @click="exibirModalAdicionarPessoa()"
              v-text="'mdi-plus'"
              v-on="on"
            ></v-icon>
          </template>
          <span>Novo solicitante</span>
        </v-tooltip>

        <v-dialog v-model="dialog" persistent max-width="940px">
          <v-card class="card-localizacao-pessoa">
            <v-card-title
              :class="
                !!(isDark === 'dark') ? 'titulo-modal-dark' : 'titulo-modal'
              "
            >
              <span class="headline">
                <span class="headline"> Adicionar pessoa </span>
              </span>
            </v-card-title>

            <v-card-text>
              <div class="mt-3">
                <v-row>
                  <v-col cols="12" sm="4" md="5">
                    <v-text-field
                      label="Nome"
                      v-model="pessoa.nome"
                      autocomplete="off"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3">
                    <v-text-field
                      :label="
                        !pessoa.pessoaJuridica ? 'Apelido' : 'Nome fantasia'
                      "
                      v-model="pessoa.apelido"
                      autocomplete="off"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      label="E-mail"
                      v-model="pessoa.email"
                      autocomplete="off"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="3">
                    <v-text-field
                      label="Celular"
                      v-model="pessoa.celular"
                      v-mask="'(##) #####-####'"
                      autocomplete="off"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="3">
                    <v-switch
                      v-model="pessoa.pessoaJuridica"
                      label="Pessoa Juridica"
                      autocomplete="off"
                      outlined
                      dense
                    ></v-switch>
                  </v-col>

                  <v-col cols="12" sm="12" md="3">
                    <v-text-field
                      :label="!pessoa.pessoaJuridica ? 'CPF' : 'CNPJ'"
                      class="tipo-numero"
                      v-model="pessoa.cpfOuCnpj"
                      v-mask="
                        !pessoa.pessoaJuridica
                          ? '###.###.###-##'
                          : '##.###.###/####-##'
                      "
                      autocomplete="off"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col v-if="!pessoa.pessoaJuridica" cols="12" sm="12" md="3">
                    <v-autocomplete
                      :items="['Masculino', 'Feminino', 'Outros']"
                      label="Sexo"
                      clearable
                      v-model="pessoa.sexo"
                      autocomplete="off"
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="2" md="3">
                    <v-autocomplete
                      :items="listaTipoDePessoas"
                      label="Tipo de pessoa"
                      clearable
                      v-model="pessoa.tipoDePessoa"
                      autocomplete="off"
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col v-if="!pessoa.pessoaJuridica" cols="12" sm="12" md="3">
                    <v-autocomplete
                      :items="[
                        'Casado (a)',
                        'Solteiro (a)',
                        'Divorciado (a)',
                        'Viúvo (a)',
                      ]"
                      label="Estado civil"
                      v-model="pessoa.estadoCivil"
                      clearable
                      autocomplete="off"
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </div>

              <div v-if="!modoFiltro">
                <div v-if="pessoaNaoEncontrada" class="mensagem">
                  <h1>Nenhuma pessoa encontrada!</h1>
                </div>
                <v-list
                  v-if="
                    !!listaItens && !pessoaNaoEncontrada && !dialogCarregamento
                  "
                  dense
                >
                  <v-list-item-group color="primary">
                    <v-list-item
                      v-for="item in listaItens"
                      :key="item.identificador"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          :class="
                            classeId || id
                              ? 'list-item-pessoa-' + classeId || id
                              : id
                          "
                          @click="selecionarPessoa(item)"
                          v-text="item.codigoNome"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </div>

              <div
                v-show="dialogCarregamento"
                class="icone-de-progresso-circular-pesquisa"
              >
                <v-progress-circular
                  :size="130"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </div>
            </v-card-text>

            <v-card-actions :class="dialogCarregamento ? 'card-action' : ''">
              <v-spacer></v-spacer>

              <v-btn
                color="primary"
                class="btn-primario"
                elevation="0"
               :disabled="!pessoa.nome || desabilitarBotoes"
                @click="adicionarPessoa"
                >Salvar</v-btn
              >
              <v-btn
                class="btn-terciario ml-0"
                elevation="0"
                :disabled="desabilitarBotoes"
                @click="fecharModal"
                >Fechar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </v-autocomplete>

    <alerta
      :tipo="tipoAlerta"
      :mensagem="mensagemAlerta"
      :exibir="exibirAlerta"
      identificador="alerta-pesquisa-pessoa"
    ></alerta>
  </v-layout>
</template>

<script>
import apiPessoa from "@/api/pessoa-api.js";
import Alerta from "@/components/comum/Alerta.vue";
import PessoaModel from "@/model/pessoa-model";
import {
  TEMPO_ESPERA_PARA_PESQUISA_AUTOCOMPLETE,
  INPUT_KEY_CODES,
} from "@/constants/configuracoes-constants";

import { TIPOS_DE_PESSOA } from "@/constants/geral-constants";
import PessoaPesquisaAvancadaModel from "@/model/pessoa-pesquisa-avancada-model";
import mixinGeral from "../../mixin/mixin-geral";


export default {
  components: {
    Alerta,
  },
  mixins: [mixinGeral],
  model: {
    prop: "itemSelecionado",
    event: "onChange",
  },
  props: {
    id: { type: String, required: false },
    classeId: { type: String, required: false },
    itemSelecionado: { type: PessoaModel, default: () => new PessoaModel() },
    //Propriedade criada para casos onde apenas o identificador é passado para o componente pai.
    //Deve ser utilizada com .sync e apenas em extrema necessidade.
    identificadorPessoa: { type: String, default: undefined },
    label: { type: String, required: true },
    placeholder: {
      type: String,
      required: false,
      default: "Digite o código ou nome para pesquisar",
    },
    atributoExibicao: { type: String, required: true },
    desabilitar: { type: Boolean, default: false },
    focus: { type: Boolean, default: false },
    habilitarPesquisaAvancada: { type: Boolean, default: false },
    categoriasDePessoa: { type: Array, default: () => [] },
  },
  data() {
    return {
      desabilitarMensagemNaoHaDados: true,
      isLoading: false,
      listaItens: this.itemSelecionado.id ? [this.itemSelecionado] : [],
      search: null,
      model: (this.itemSelecionado && this.itemSelecionado.id) || undefined,
      pesquisando: false,
      mensagemAlerta: undefined,
      exibirAlerta: false,
      tipoAlerta: undefined,
      resultado: false,
      timeoutId: 0,
      emEdicao: true,
      modoFiltro: true,
      dialog: false,
      dialogCarregamento: false,
      pessoaNaoEncontrada: false,
      pessoaPesquisaAvancada: new PessoaPesquisaAvancadaModel(),
      pessoa: new PessoaModel(),
      listaTipoDePessoas: TIPOS_DE_PESSOA.sort(),
      desabilitarBotoes: false
    };
  },
  computed: {
    items() {
      return (
        (this.listaItens &&
          this.listaItens.map((item) => {
            return new PessoaModel(item);
          })) ||
        []
      );
    },
  },
  watch: {
    itemSelecionado() {
      this.listaItens = this.itemSelecionado.id ? [this.itemSelecionado] : [];
      this.model = this.itemSelecionado.id ? this.itemSelecionado : undefined;
    },
    identificadorPessoa() {
      // neste momento, esta propriedade esta sendo utilizada para limpar o conteudo do componente (o valor capturado sempre sera undefined para limpar o componente)
      // no futuro, caso seja necessario entrar com um identificador, a funcionalidade devera ser adicionada
      if (!this.identificadorPessoa && !this.itemSelecionado)
        this.model = undefined;
    },
    focus(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.inputPessoa.focus();
          this.onFocus();
        });
      }
    },


  },

  methods: {
    onFocus() {
      this.$emit("onFocus");
    },

    exibirModalPesquisaAvancada() {
      this.dialog = true;
      this.emEdicao = !this.emEdicao;
    },
    exibirModalAdicionarPessoa() {
      this.pessoa = new PessoaModel();
      this.dialog = true;
    },

    fecharModal() {
      this.modoFiltro = true;
      this.dialog = false;
      this.emEdicao = true;
      this.pessoaNaoEncontrada = false;
      this.pessoaPesquisaAvancada = new PessoaPesquisaAvancadaModel();
    },

    selecionarPessoa(item) {
      this.model = item;
      this.dialog = false;
      this.emitirItem();
      this.modoFiltro = true;
    },

    pesquisarPessoa() {
      if (!this.pessoaPesquisaAvancada.modeloValido()) {
        this.isLoading = false;
        return;
      }

      this.pessoaPesquisaAvancada.preencherOCodigoComZerosAEsquerda();
      this.isLoading = true;
      this.modoFiltro = false;
      this.dialogCarregamento = true;
      this.desabilitarMensagemNaoHaDados = true;
      this.localizarPessoaPesquisaAvancada(this.pessoaPesquisaAvancada);
    },

    onKeyUp(evt) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
        this.timeoutId = 0;
      }

      if (!this.search) {
        this.listaItens = [];
        this.isLoading = false;
        return;
      }

      this.isLoading = true;
      if (evt.keyCode === INPUT_KEY_CODES.enter) {
        this.localizarPessoasPorTrechoEAdicionarNaListagem(this.search);
        return;
      }

      this.timeoutId = setTimeout(() => {
        this.timeoutId = 0;
        this.desabilitarMensagemNaoHaDados = true;
        // this.localizarPessoasPorTrechoEAdicionarNaListagem(this.search);
        this.localizarTodos();
      }, TEMPO_ESPERA_PARA_PESQUISA_AUTOCOMPLETE);
    },

    localizarTodos() {
       apiPessoa
        .obterTodos()
        .then((resposta) => {
          this.listaItens = resposta.data.map((p) => new PessoaModel(p));
          this.pessoaNaoEncontrada = false;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally((this.isLoading = false));
    },
    localizarPessoaPesquisaAvancada(pessoaPesquisaAvancada) {
       apiPessoa
        .localizarPessoaPesquisaAvancada(
          pessoaPesquisaAvancada,
          this.tiposDeProduto
        )
        .then((resultado) => {
          this.dialogCarregamento = false;
          if (resultado.length == 0) {
            this.pessoaNaoEncontrada = true;
            return;
          }

          this.listaItens = resultado;
          this.pessoaNaoEncontrada = false;
          this.pessoaPesquisaAvancada = new PessoaPesquisaAvancadaModel();
        })
        .catch((erro) => {
          this.$mensagemFlutuante.erro({
            titulo: `Não foi possível obter as pessoas.`,
            mensagem: `Motivo: ${erro[0].statusText}`,
          });
          this.dialogCarregamento = false;
        })
        .finally((this.isLoading = false));
    },

    // localizarPessoasPorTrechoEAdicionarNaListagem(texto) {
    //   this.isLoading = true;
    //    apiPessoa
    //     .localizarPessoa(texto, this.categoriasDePessoa)
    //     .then(resultado => {
    //       this.listaItens = resultado || [];
    //     })
    //     .finally(() => {
    //       if (this.listaItens.length == 0) {
    //         this.desabilitarMensagemNaoHaDados = false;
    //       }
    //       this.isLoading = false;
    //     });
    // },

    emitirItem() {
      this.$emit("onChange", this.model ? this.model : undefined);
      this.$emit(
        "update:identificadorPessoa",
        this.model ? this.model.identificador : undefined
      );
    },

    adicionarPessoa(){
      this.desabilitarBotoes = true;
      apiPessoa
        .cadastrar(this.pessoa)
        .then((response) => {
          this.pessoa = new PessoaModel(response.data);
          this.listaItens.push(this.pessoa);
          this.model = this.pessoa;
          this.fecharModal();
          this.desabilitarBotoes = false;
        })
        .catch((erro) => {
          this.dispararMensagem(erro.response.data.mensagem, "error");
          this.desabilitarBotoes = false;
        });
    }
  },
};
</script>

<style lang="scss" scoped>
.card-localizacao-pessoa {
  min-height: 235px;
}
.subheader {
  height: 25px;
}

.hover-radio:hover {
  color: #165091;
}
.title-localizacao {
  display: block;
  width: 100%;
}

.micro-copia {
  font-size: 12px;
  color: #ccc;
  font-weight: 300;
}
.mensagem {
  text-align: center;
  margin-top: 55px;
}
.card-action {
  margin-top: 75px;
}
</style>