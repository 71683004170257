var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-8"},[_c('div',{staticStyle:{"width":"100%"}},[_c('v-snackbar',{attrs:{"vertical":false,"timeout":_vm.timeout,"top":"top","color":_vm.tipo,"elevation":"5","multi-line":"","right":"","shaped":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v("OK")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.mensagem)+" ")]),(_vm.loading)?_c('div',{staticClass:"loading text-center"},[_c('v-progress-circular',{attrs:{"size":70,"width":5,"color":"primary","indeterminate":""}}),_c('h1',{staticClass:"loading-titulo"},[_vm._v("Carregando")])],1):_vm._e(),(!_vm.loading)?_c('div',[_c('v-row',[_c('v-flex',[_c('v-layout',{attrs:{"wrap":""}},[_c('v-col',{staticClass:"pl-0 ml-0 mb-0",attrs:{"cols":"12","xs":"12","sm":"12"}},[_c('h1',{staticClass:"titulo mb-0"},[_vm._v("Controle de Usuarios")]),_c('v-divider')],1),_c('v-col',{staticClass:"pl-0 ml-0",attrs:{"cols":"12","sm":"6"}},[_c('v-btn',{staticClass:"btn-primario ml-0",attrs:{"color":"primary","elevation":"0"},on:{"click":function($event){_vm.dialog = !_vm.dialog}}},[_c('v-icon',{staticClass:"mr-2 icone"},[_vm._v("mdi-plus")]),_vm._v(" Adicionar")],1)],1),_c('v-card',{attrs:{"width":"100%"}},[_c('v-card-title',{staticClass:"pt-0"},[_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"2"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pesquisar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.colunas,"items":_vm.usuarios,"search":_vm.search,"sort-by":"id","sort-desc":""},scopedSlots:_vm._u([{key:"item.criadoEm",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("data")(item.criadoEm))+" ")]}},{key:"item.totalAtendimentos",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"totalizador",attrs:{"color":"info","text-color":"white"}},[_vm._v(" "+_vm._s(item.totalAtendimentos)+" ")])]}},{key:"item.acoes",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":"","offset-overflow":"","left":"","slide-y":""},on:{"click":function($event){$event.stopPropagation();return (function () {})($event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"btn-opcoes",attrs:{"text":""}},on),[_c('span',{staticClass:"align-text-icon"},[_vm._v("Opções")]),_c('v-icon',{staticClass:"seta-para-cima"},[_vm._v("arrow_drop_up")]),_c('v-icon',{staticClass:"seta-para-baixo"},[_vm._v("arrow_drop_down")])],1)]}}],null,true)},[_c('v-list',{staticClass:"lista-do-menu"},_vm._l((_vm.opcoes),function(opcao){return _c('v-list-item',{key:opcao.id,on:{"click":function($event){return opcao.metodo(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(opcao.titulo))])],1)}),1)],1)]}}],null,false,1454745139)})],1)],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"750px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{class:!!(_vm.isDark === 'dark') ? 'titulo-modal-dark' : 'titulo-modal'},[_c('span',{staticClass:"headline"},[_vm._v(" "+_vm._s(!_vm.modoEdicao ? "Adicionar " : "Editar ")+" usuário ")])]),_c('v-card-text',[_c('v-container',[_c('form',{attrs:{"autocomplete":"off"}},[_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Nome","autocomplete":"off","outlined":"","dense":""},model:{value:(_vm.usuario.nome),callback:function ($$v) {_vm.$set(_vm.usuario, "nome", $$v)},expression:"usuario.nome"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":"E-mail","autocomplete":"off","type":"email","outlined":"","dense":""},model:{value:(_vm.usuario.email),callback:function ($$v) {_vm.$set(_vm.usuario, "email", $$v)},expression:"usuario.email"}})],1),_c('v-col',{attrs:{"cols":"12","sm":""}},[_c('v-text-field',{attrs:{"label":"Função","autocomplete":"off","outlined":"","dense":""},model:{value:(_vm.usuario.funcao),callback:function ($$v) {_vm.$set(_vm.usuario, "funcao", $$v)},expression:"usuario.funcao"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-autocomplete',{attrs:{"items":[
                        { id: 1, descricao: 'Administrador' },
                        { id: 2, descricao: 'Assessor' } ],"label":"Perfil","item-text":"descricao","return-object":"","clearable":"","autocomplete":"off","outlined":"","dense":""},model:{value:(_vm.usuario.perfil),callback:function ($$v) {_vm.$set(_vm.usuario, "perfil", $$v)},expression:"usuario.perfil"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"label":"Senha","append-icon":_vm.value ? 'visibility_off' : 'visibility',"type":_vm.value ? 'password' : 'text',"rules":[
                        function (v) { return v.trim().length >= 6 ||
                          'Senha deve ter mínimo de 6 caracteres'; } ],"outlined":"","dense":""},on:{"click:append":function () { return (_vm.value = !_vm.value); }},model:{value:(_vm.usuario.senha),callback:function ($$v) {_vm.$set(_vm.usuario, "senha", $$v)},expression:"usuario.senha"}})],1)],1)],1)])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"btn-tertiary",attrs:{"text":"","disabled":!this.usuario.modeloValido() || _vm.desabilitarBotoes},on:{"click":_vm.salvar}},[_vm._v("Salvar")]),_c('v-btn',{staticClass:"btn-quaternary",attrs:{"disabled":_vm.desabilitarBotoes,"text":""},on:{"click":_vm.cancelar}},[_vm._v("Cancelar")])],1)],1)],1)],1):_vm._e(),_c('janela-confirmacao',{attrs:{"callbackPrimario":_vm.callbackConfirmarExclusao,"mensagem":_vm.mensagemDeExclusao},model:{value:(_vm.abrirJanelaConfirmacao),callback:function ($$v) {_vm.abrirJanelaConfirmacao=$$v},expression:"abrirJanelaConfirmacao"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }