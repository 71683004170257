
export default class MetadadosPessoa {
    constructor(metadados){
        metadados = metadados || [];
        this.totalDePessoas = metadados.length;
        this.totalPessoasSexo = this._preencherPorSexo(metadados);
        this.totalPorFaixasDeIdade = new TotalizadorPorFaixaEtariaDeIdade(metadados);
        this.tiposDePessoas = this._preencherPorTipo(metadados);
        this.totalPorEndereco = new TotalizadorPorEndereco(metadados);
    }
    _preencherPorTipo(metadados){
        let porTipo = [];

        let filtrados = metadados.filter(e => e.tipoDePessoa);
        filtrados.map(e => {
            if(!porTipo.some(ed => ed.tipoDePessoa == e.tipoDePessoa)){
                porTipo.push({
                    tipoDePessoa: e.tipoDePessoa,
                    pessoas: [e]
                });
            }else{
                porTipo.map(r => {
                    if(r.tipoDePessoa == e.tipoDePessoa){
                        r.pessoas.push(e);
                    }
                });
            }
        });

        metadados.filter(el => !el.tipoDePessoa)
        .map(e => { 
            if(porTipo.length){
                porTipo.map(r => {
                    if(r.tipoDePessoa == "Não informado"){
                        r.pessoas.push(e);
                    }
                })
            }else{
                porTipo.push({
                    tipoDePessoa: "Não informado",
                    pessoas: [e]
                });
            }
       
        })
       

        return porTipo;
    }
    _preencherPorSexo(metadados){
        let porSexo = [];

        let filtrados = metadados.filter(e => e.sexo);
        filtrados.map(e => {
            if(!porSexo.some(ed => ed.sexo == e.sexo)){
                porSexo.push({
                    sexo: e.sexo,
                    pessoas: [e]
                });
            }else{
                porSexo.map(r => {
                    if(r.sexo == e.sexo){
                        r.pessoas.push(e);
                    }
                });
            }
        });

        porSexo.push({
            sexo: "Não informado",
            pessoas:  metadados.filter(e => !e.sexo)
        });

        return porSexo;
    }
}

class TotalizadorPorFaixaEtariaDeIdade {
    constructor(metadados){
        metadados = metadados || [];
        this.pessoasComIdadeInferiorOuIgualADezoitoAnos = []
        this.pessoasComIdadeEntreDezenoveEVinteCincoAnos = [];
        this.pessoasComIdadeEntreVinteSeisEQuarentaAnos = [];
        this.pessoasComIdadeEntreQuarentaEUmSessentaAnos = [];
        this.pessoasComIdadeSuperioresASessentaAnos = [];
        this.pessoasSemDataDeNascimentoCadastrada = [];

        this._preencherFaixas(metadados);
    }

    _preencherFaixas(metadados){
        let filtrados = metadados.filter(p => p.dataDeNascimento);
       
        this.pessoasComIdadeInferiorOuIgualADezoitoAnos = filtrados
            .filter(p => this._calculateAge(p.dataDeNascimento) <= 18);

        this.pessoasComIdadeEntreDezenoveEVinteCincoAnos = filtrados
            .filter(p => this._calculateAge(p.dataDeNascimento) >=19 && 
                         this._calculateAge(p.dataDeNascimento) <= 25);

        this.pessoasComIdadeEntreVinteSeisEQuarentaAnos = filtrados
            .filter(p => this._calculateAge(p.dataDeNascimento) >=26 && 
                         this._calculateAge(p.dataDeNascimento) <= 40);

        this.pessoasComIdadeEntreQuarentaEUmSessentaAnos = filtrados
            .filter(p => this._calculateAge(p.dataDeNascimento) >= 41 && 
                         this._calculateAge(p.dataDeNascimento) <= 60); 

        this.pessoasComIdadeSuperioresASessentaAnos = filtrados
            .filter(p => this._calculateAge(p.dataDeNascimento) > 60); 
            
        this.pessoasSemDataDeNascimentoCadastrada = metadados.filter(p => !p.dataDeNascimento);
    }
   

    _calculateAge(birthday) { // birthday is a date
        birthday = new Date(birthday);

        var ageDifMs = Date.now() - birthday.getTime();

        var ageDate = new Date(ageDifMs); // miliseconds from epoch
        let age = Math.abs(ageDate.getUTCFullYear() - 1970);
        return age; 
    }
}

class TotalizadorPorEndereco{
    constructor(metadados){
        metadados = metadados || [];
        this.porUfs = [];
        this.porCidade = [];
        this.porBairro = [];

        this._preencherPorEnderecos(metadados);
    }

    _preencherPorEnderecos(metadados){
       this.porUfs = this._preencherPorUF(metadados);
       this.porCidade = this._preencherPorCidade(metadados);
       this.porBairro = this._preencherPorBairro(metadados);
    }

    _preencherPorUF(metadados){
        let porUF = [];
       
        let filtrados = metadados.filter(e => e.uf);
        filtrados.map(e => {
            if(!porUF.some(ed => ed.uf.toUpperCase() == e.uf.toUpperCase())){
                porUF.push({
                    uf: e.uf && e.uf.toUpperCase(),
                    pessoas: [e]
                });
            }else{
                porUF.map(r => {
                    if(r.uf.toUpperCase() == e.uf.toUpperCase()){
                        r.pessoas.push(e);
                    }
                });
            }
        });

        porUF.push({
            uf: "NÃO INFORMADO",
            pessoas: metadados.filter(e => !e.uf)
        });

        return porUF;
    }

    _preencherPorCidade(metadados){
        let porCidade = [];
       
        let filtrados = metadados.filter(e => e.cidade);
        filtrados.map(e => {
            if(!porCidade.some(ed => ed.cidade.toUpperCase() == e.cidade.toUpperCase())){
                porCidade.push({
                    cidade: e.cidade && e.cidade.toUpperCase(),
                    pessoas: [e]
                });
            }else{
                porCidade.map(r => {
                    if(r.cidade.toUpperCase() == e.cidade.toUpperCase()){
                        r.pessoas.push(e);
                    }
                });
            }
        });

        porCidade.push({
            cidade: "NÃO INFORMADO",
            pessoas: metadados.filter(e => !e.cidade)
        });

        return porCidade;
    }

    _preencherPorBairro(metadados){
        let porBairro = [];
       
        let filtrados = metadados.filter(e => e.bairro);
        filtrados.map(e => {

            e.bairro = this._padronizar(e.bairro);

            if(!porBairro.some(ed => ed.bairro.toUpperCase() == e.bairro.toUpperCase())){
                porBairro.push({
                    bairro: e.bairro && e.bairro.toUpperCase(),
                    pessoas: [e]
                });
            }else{
                porBairro.map(r => {
                    if(r.bairro.toUpperCase() == e.bairro.toUpperCase()){
                        r.pessoas.push(e);
                    }
                });
            }
        });

        porBairro.push({
            bairro: "NÃO INFORMADO",
            pessoas: metadados.filter(e => !e.bairro)
        });

        // Ordemando em ordem decrescente.
        porBairro.sort((a, b) => a.pessoas.length - b.pessoas.length).reverse();
        porBairro = porBairro.slice(0, 50);

        return porBairro;
    }

    _padronizar(bairro){
        return bairro.toUpperCase()
            .replaceAll('Á', 'A')
            .replaceAll('Â', 'A')
            .replaceAll('À', 'A')
            .replaceAll('Ã', 'A')
            .replaceAll('É', 'E')
            .replaceAll('È', 'E')
            .replaceAll('Ê', 'E')
            .replaceAll('Í', 'I')
            .replaceAll('Ò', 'O')
            .replaceAll('Ó', 'O')
            .replaceAll('Ô', 'O')
            .replaceAll('Õ', 'O')
            .replaceAll('Ú', 'U')
            .replaceAll('Ù', 'U')
            .replaceAll('Û', 'U')
            .replaceAll('Ç', 'C')
            .trim();
    }
}