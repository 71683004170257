<template>
  <v-container class="mt-6">
    <div :dark="!!(isDark === 'dark')" style="width: 100%">
      <v-snackbar
        v-model="snackbar"
        :vertical="false"
        :timeout="timeout"
        top="top"
        :color="tipo"
        elevation="5"
        multi-line
        right
        shaped
      >
        {{ mensagem }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false">OK</v-btn>
        </template>
      </v-snackbar>

      <div v-if="loading" class="loading text-center">
        <v-progress-circular
          :size="70"
          :width="5"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <h1 class="loading-titulo">Carregando</h1>
      </div>

      <div v-if="!loading">
        <v-layout>
          <v-flex>
            <v-layout wrap>
              <v-col class="pl-0 mt-2" cols="12" xs="12" sm="12">
                <h1 class="titulo mb-0">Controle de pessoas</h1>
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12" class="pl-0 ml-0" sm="6">
                <v-btn
                  class="btn-primario ml-0"
                  color="primary"
                  elevation="0"
                  @click="adicionarPessoa"
                >
                  <v-icon class="mr-2 icone">mdi-plus</v-icon>
                  Adicionar</v-btn
                >
              </v-col>
              <v-col
                v-if="false"
                class="pl-0 ml-0 pr-0 mr-0 alinhamento"
                cols="12"
                sm="6"
              >
                <v-btn
                  class="btn-tertiary width-icon mr-0 ml-4 my-2"
                  text
                  @click="exibirFiltros = !exibirFiltros"
                >
                  <v-icon small class="size-icon-filter" v-show="!exibirFiltros"
                    >arrow_back_ios</v-icon
                  >
                  Filtros
                  <v-icon
                    small
                    class="size-icon-filter ml-2"
                    v-show="exibirFiltros"
                    >arrow_forward_ios</v-icon
                  >
                </v-btn>
              </v-col>
              <v-card width="100%" elevation="">
                <v-card-title class="pt-0">
                  <v-col cols="12" xs="12" sm="1">
                    <v-tooltip color="#343434" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <download-excel
                          class="btn btn-default"
                          :data="pessoasPlanilha"
                          worksheet="Pessoas"
                          name="pessoas.xls"
                        >
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            text
                            outlined
                            class="btn-tertiary"
                            color="primary"
                          >
                            <v-icon small class="mr-1">
                              mdi-microsoft-excel
                            </v-icon>
                            Exportar
                          </v-btn>
                        </download-excel>
                      </template>
                      <span>Exportar pessoas para excel</span>
                    </v-tooltip>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="12" xs="12" sm="2">
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Pesquisar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-card-title>
                <v-data-table
                  :headers="colunas"
                  :items="pessoas"
                  :search="search"
                  show-expand
                  single-expand
                  sort-by="id"
                  sort-desc
                >
                  <template v-slot:item.criadoEm="{ item }">
                    {{ item.criadoEm | data }}
                  </template>
                  <template v-slot:item.totalAtendimentos="{ item }">
                    <v-chip class="totalizador" color="info" text-color="white">
                      {{ item.totalAtendimentos || 0 }}
                    </v-chip>
                  </template>

                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length" class="bloco-expansivel-td">
                      <strong class="primary--text">Endereço:</strong>
                      <v-row class="endereco-expansivel">
                        <v-col cols="12" xs="12" sm="2">
                          <div>
                            <strong>Cep: </strong>{{ item.endereco.cep }}
                          </div>
                        </v-col>
                        <v-col cols="12" xs="12" sm="3">
                          <div>
                            <strong>Rua: </strong>{{ item.endereco.rua }}
                          </div>
                        </v-col>
                        <v-col cols="12" xs="12" sm="2">
                          <div>
                            <strong>Numero: </strong>{{ item.endereco.numero }}
                          </div>
                        </v-col>
                        <v-col cols="12" xs="12" sm="3">
                          <div>
                            <strong>Complemento: </strong
                            >{{ item.endereco.complemento }}
                          </div>
                        </v-col>
                        <v-col cols="12" xs="12" sm="2">
                          <div>
                            <strong>Bairro: </strong>{{ item.endereco.bairro }}
                          </div>
                        </v-col>
                        <v-col cols="12" xs="12" sm="2">
                          <div>
                            <strong>Cidade: </strong>{{ item.endereco.cidade }}
                          </div>
                        </v-col>
                        <v-col cols="12" xs="12" sm="2">
                          <div>
                            <strong>Estado: </strong>{{ item.endereco.uf }}
                          </div>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" xs="12" sm="12">
                          <div>
                            <strong class="primary--text">Solicitação:</strong>
                            <v-textarea
                              class="solicitacao"
                              readonly
                              auto-grow
                              solo
                              rows="3"
                              v-model="item.observacao"
                              label=""
                            ></v-textarea>
                          </div>
                        </v-col>
                      </v-row>
                    </td>
                  </template>

                  <template v-slot:item.acoes="{ item }">
                    <v-menu
                      offset-y
                      offset-overflow
                      left
                      slide-y
                      @click.stop="() => {}"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn class="btn-opcoes" text v-on="on">
                          <span class="align-text-icon">Opções</span>
                          <v-icon class="seta-para-cima">arrow_drop_up</v-icon>
                          <v-icon class="seta-para-baixo"
                            >arrow_drop_down</v-icon
                          >
                        </v-btn>
                      </template>

                      <v-list class="lista-do-menu">
                        <v-list-item
                          v-for="opcao in opcoes"
                          :key="opcao.id"
                          @click="opcao.metodo(item)"
                        >
                          <v-list-item-title>{{
                            opcao.titulo
                          }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                </v-data-table>
              </v-card>
            </v-layout>
          </v-flex>

          <v-flex v-show="exibirFiltros" xs3 grow>
            <v-layout fill-height>
              <v-flex shrink>
                <v-divider vertical class="mx-3"></v-divider>
              </v-flex>
              <v-flex xm12>
                <!-- <filtros-pesquisa-orcamento
                :id="'filtros-pesquisa-orcamento-' + id"
                xm12
                ref="filtros"
                v-model="filtrosPesquisaOrcamento"
                @onFiltrar="filtrarOrcamentosSalvandoFiltro"
                :periodoDeEmissao="periodoDeEmissao"
                :listaDeStatusParaFiltro="listaStatus"
              ></filtros-pesquisa-orcamento> -->
                <h2>filtros aqui</h2>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>

        <v-dialog v-model="dialog" persistent max-width="1280px">
          <v-card>
            <v-card-title
              :class="
                !!(isDark === 'dark') ? 'titulo-modal-dark' : 'titulo-modal'
              "
            >
              <span class="headline">
                {{ !modoEdicao ? "Adicionar " : "Editar " }} pessoa
              </span>
            </v-card-title>
            <v-card-text>
              <form autocomplete="off">
                <v-row class="">
                  <v-col cols="12" col="12">
                    <v-card elevation="0">
                      <v-tabs>
                        <v-tab>
                          <v-icon left> mdi-account </v-icon>
                          Pessoais
                        </v-tab>
                        <v-tab>
                          <v-icon left> mdi-library </v-icon>
                          Contatos
                        </v-tab>
                        <!-- <v-tab>
                            <v-icon left> mdi-file-document </v-icon>
                            Documentos
                          </v-tab> -->
                        <v-tab>
                          <v-icon left> mdi-map-marker </v-icon>
                          Endereço
                        </v-tab>
                        <v-tab>
                          <v-icon left> mdi-account-details </v-icon>
                          Outros
                        </v-tab>

                        <!-- Tab Pessoais -->
                        <v-tab-item>
                          <v-card flat>
                            <v-card-text>
                              <div class="tamanho-tab-cadastro">
                                <v-row>
                                  <v-col cols="12" sm="4" md="4">
                                    <v-text-field
                                      label="Nome"
                                      v-model="pessoa.nome"
                                      autocomplete="off"
                                      outlined
                                      dense
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="3" md="2">
                                    <v-text-field
                                      :label="
                                        !pessoa.pessoaJuridica
                                          ? 'Apelido'
                                          : 'Nome fantasia'
                                      "
                                      v-model="pessoa.apelido"
                                      autocomplete="off"
                                      outlined
                                      dense
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="3" md="2">
                                    <v-switch
                                      v-model="pessoa.pessoaJuridica"
                                      label="Pessoa Juridica"
                                      autocomplete="off"
                                      outlined
                                      dense
                                    ></v-switch>
                                  </v-col>

                                  <v-col cols="12" sm="2">
                                    <v-text-field
                                      :label="
                                        !pessoa.pessoaJuridica ? 'CPF' : 'CNPJ'
                                      "
                                      class="tipo-numero"
                                      v-model="pessoa.cpfOuCnpj"
                                      v-mask="
                                        !pessoa.pessoaJuridica
                                          ? '###.###.###-##'
                                          : '##.###.###/####-##'
                                      "
                                      autocomplete="off"
                                      outlined
                                      dense
                                    ></v-text-field>
                                  </v-col>
                                  <v-col
                                    v-if="!pessoa.pessoaJuridica"
                                    cols="12"
                                    sm="2"
                                  >
                                    <v-text-field
                                      label="RG"
                                      class="tipo-numero"
                                      v-model="pessoa.rg"
                                      v-mask="'##.###.###-#'"
                                      autocomplete="off"
                                      outlined
                                      dense
                                    ></v-text-field>
                                  </v-col>
                                  <v-col
                                    v-if="!pessoa.pessoaJuridica"
                                    cols="12"
                                    sm="2"
                                    md="2"
                                  >
                                    <v-autocomplete
                                      :items="[
                                        'Masculino',
                                        'Feminino',
                                        'Outros',
                                      ]"
                                      label="Sexo"
                                      clearable
                                      v-model="pessoa.sexo"
                                      autocomplete="off"
                                      outlined
                                      dense
                                    ></v-autocomplete>
                                  </v-col>
                                  <v-col cols="12" sm="2" md="3">
                                    <v-autocomplete
                                      :items="listaTipoDePessoas"
                                      label="Tipo de pessoa"
                                      clearable
                                      v-model="pessoa.tipoDePessoa"
                                      autocomplete="off"
                                      outlined
                                      dense
                                    ></v-autocomplete>
                                  </v-col>

                                  <v-col
                                    v-if="!pessoa.pessoaJuridica"
                                    cols="12"
                                    sm="3"
                                  >
                                    <date-picker
                                      label="Data de nascimento"
                                      v-model="pessoa.dataDeNascimento"
                                      data-minima="1900-01-01"
                                     
                                    >
                                    </date-picker>
                                  </v-col>
                                  <v-col
                                    v-if="!pessoa.pessoaJuridica"
                                    cols="12"
                                    sm="2"
                                  >
                                    <v-autocomplete
                                      :items="[
                                        'Casado (a)',
                                        'Solteiro (a)',
                                        'Divorciado (a)',
                                        'Viúvo (a)',
                                      ]"
                                      label="Estado civil"
                                      v-model="pessoa.estadoCivil"
                                      clearable
                                      autocomplete="off"
                                      outlined
                                      dense
                                    ></v-autocomplete>
                                  </v-col>
                                  <v-col
                                    v-if="!pessoa.pessoaJuridica"
                                    cols="12"
                                    sm="2"
                                    
                                  >
                                    <v-text-field
                                      :class="'tipo-numero'"
                                      v-model="pessoa.quantidadeDeFilhos"
                                      v-mask="'###'"
                                      label="Filhos"
                                      autocomplete="off"
                                      outlined
                                      dense
                                    >
                                    </v-text-field>
                                  </v-col>
                                  <v-col
                                    v-if="!pessoa.pessoaJuridica"
                                    cols="12"
                                    sm="2"
                                  >
                                    <v-autocomplete
                                      :items="listaDeReligioes"
                                      label="Religião"
                                      v-model="pessoa.religiao"
                                      clearable
                                      autocomplete="off"
                                      outlined
                                      dense
                                    ></v-autocomplete>
                                  </v-col>

                                  <v-col
                                    v-if="!pessoa.pessoaJuridica"
                                    cols="12"
                                    sm="3"
                                    md="2"
                                  >
                                    <v-autocomplete
                                      :items="listaDeProfissoesEOcupacoes"
                                      label="Profissão"
                                      clearable
                                      return-object
                                      v-model="pessoa.profissao"
                                      autocomplete="off"
                                      outlined
                                      dense
                                      @focus="
                                        () => (pessoa.profissao = undefined)
                                      "
                                    ></v-autocomplete>
                                  </v-col>
                                  <v-col cols="12" sm="3" md="3">
                                    <v-switch
                                      v-model="pessoa.membroDaEquipe"
                                      label="Membro da equipe"
                                      autocomplete="off"
                                    ></v-switch>
                                  </v-col>
                                </v-row>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-tab-item>

                        <!-- Tab Contatos -->
                        <v-tab-item>
                          <v-card flat>
                            <v-card-text>
                              <div class="tamanho-tab-cadastro">
                                <v-row>
                                  <v-col cols="12" sm="3" md="3">
                                    <v-text-field
                                      label="Telefone celular"
                                      v-model="pessoa.celular"
                                      v-mask="'(##) #####-####'"
                                      autocomplete="off"
                                      outlined
                                      dense
                                    ></v-text-field>
                                  </v-col>

                                  <v-col cols="12" sm="3">
                                    <v-text-field
                                      label="Telefone fixo"
                                      v-model="pessoa.fixo"
                                      v-mask="'(##) ####-####'"
                                      autocomplete="off"
                                      outlined
                                      dense
                                    ></v-text-field>
                                  </v-col>

                                  <v-col cols="12" sm="6">
                                    <v-text-field
                                      label="E-mail"
                                      v-model="pessoa.email"
                                      autocomplete="off"
                                      outlined
                                      dense
                                    ></v-text-field>
                                  </v-col>
                                </v-row>

                                <v-row>
                                  <v-col cols="12" sm="12">
                                    <v-text-field
                                      label="Rede social principal"
                                      v-model="pessoa.redeSocialPrincipal"
                                      type="email"
                                      autocomplete="off"
                                      outlined
                                      dense
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-tab-item>
                        <!-- Tab Endereco -->
                        <v-tab-item>
                          <v-card flat>
                            <v-card-text>
                              <div class="tamanho-tab-cadastro">
                                <v-row>
                                  <v-col cols="12" sm="2" md="2">
                                    <v-text-field
                                      label="Cep"
                                      class="tipo-numero"
                                      v-model="pessoa.endereco.cep"
                                      v-mask="'#####-###'"
                                      autocomplete="off"
                                      name="cep-endereco"
                                      outlined
                                      dense
                                      @change="preencherEndereco"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="8" md="8">
                                    <v-text-field
                                      label="Logradouro"
                                      v-model="pessoa.endereco.rua"
                                      autocomplete="off"
                                      outlined
                                      dense
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="2" md="2">
                                    <v-text-field
                                      label="Número"
                                      class="tipo-numero"
                                      v-model="pessoa.endereco.numero"
                                      v-mask="'######'"
                                      autocomplete="off"
                                      outlined
                                      dense
                                    ></v-text-field>
                                  </v-col>
                                </v-row>

                                <v-row>
                                  <v-col cols="12" sm="4" md="4">
                                    <v-text-field
                                      label="Complemento"
                                      v-model="pessoa.endereco.complemento"
                                      autocomplete="off"
                                      outlined
                                      dense
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="3" md="3">
                                    <v-text-field
                                      label="Bairro"
                                      v-model="pessoa.endereco.bairro"
                                      autocomplete="off"
                                      outlined
                                      dense
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="2" md="2">
                                    <v-autocomplete
                                      :items="listaDeUFs"
                                      label="UF"
                                      v-model="pessoa.endereco.uf"
                                      autocomplete="off"
                                      clearable
                                      name="uf-endereco"
                                      outlined
                                      dense
                                    ></v-autocomplete>
                                  </v-col>
                                  <v-col cols="12" sm="3" md="3">
                                    <v-autocomplete
                                      :items="listaDeCidades"
                                      label="Cidade"
                                      v-model="pessoa.endereco.cidade"
                                      autocomplete="off"
                                      clearable
                                      name="cidade-endereco"
                                      outlined
                                      dense
                                    ></v-autocomplete>
                                  </v-col>
                                </v-row>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-tab-item>

                        <!-- Tab Outros -->
                        <v-tab-item>
                          <v-card flat>
                            <v-card-text>
                              <div class="tamanho-tab-cadastro">
                                <v-row>
                                  <v-col cols="12" sm="12" md="12">
                                    <v-textarea
                                      auto-grow
                                      outlined
                                      dense
                                      rows="8"
                                      v-model="pessoa.observacao"
                                      label="Observação"
                                    ></v-textarea>
                                  </v-col>
                                </v-row>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-tab-item>
                      </v-tabs>
                    </v-card>
                  </v-col>
                </v-row>
              </form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="primary"
                class="btn-primario"
                :disabled="!this.pessoa.modeloValido() || desabilitarBotoes"
                @click="salvar"
                elevation="0"
                >Salvar
              </v-btn>
              <v-btn
                class="btn-terciario ml-0"
                elevation="0"
                :disabled="desabilitarBotoes"
                @click="cancelar"
                >Cancelar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>

      <enviar-mensagem-whats-app
        :janelaMensagemWhatsApp="janelaMensagemWhatsApp"
        @fechar-modal="janelaMensagemWhatsApp = false"
        v-model="pessoa"
      >
      </enviar-mensagem-whats-app>

      <visualizar-detalhes-pessoa
        :visivel="abrirModalDetalhesPessoa"
        @fechar-modal="abrirModalDetalhesPessoa = false"
        v-model="pessoa"
      >
      </visualizar-detalhes-pessoa>

      <janela-confirmacao
        v-model="abrirJanelaConfirmacao"
        :callbackPrimario="callbackConfirmarExclusao"
        :mensagem="mensagemDeExclusao"
      />
    </div>
  </v-container>
</template>
<script>
import apiPessoa from "../api/pessoa-api";
import apiIBGE from "../api/ibge-api";
import apiCEP from "../api/cep-api";
import PessoaModel from "../model/pessoa-model";
import mixinMensagen from "../mixin/mixin-mensagem";
import mixinGeral from "../mixin/mixin-geral";
import moment from "moment";
import DatePicker from "@/components/comum/Datepicker";
import VisualizarDetalhesPessoa from "@/components/VisualizarDetalhesPessoa";
import JanelaConfirmacao from "@/components/JanelaConfirmacao";
import JsonExcel from "vue-json-excel";
import EnviarMensagemWhatsApp from "@/components/EnviarMensagemWhatsApp";
// import utilsValidarCPFECNPJ from "../utils/validadorCpfOuCnpj";
import mixpanel from 'mixpanel-browser'
import {
  PARTIDOS_POLITICOS,
  TIPOS_DE_PESSOA,
  RELIGIOES,
  PROFICOES_OCUPACOES,
} from "@/constants/geral-constants";

export default {
  name: "controle-pessoas",
  mixins: [mixinMensagen, mixinGeral],
  components: {
    DatePicker,
    VisualizarDetalhesPessoa,
    JanelaConfirmacao,
    downloadExcel: JsonExcel,
    EnviarMensagemWhatsApp,
  },
  filters: {
    data(valor) {
      return moment(valor).format("DD/MM/YYYY");
    },
    celular(valor) {
      if (!valor) return "";

      let r = valor.replace(/\D/g, "");
      r = r.replace(/^0/, "");
      if (r.length > 10) {
        r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
      } else if (r.length > 5) {
        r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
      } else if (r.length > 2) {
        r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
      } else {
        r = r.replace(/^(\d*)/, "($1");
      }
      return r;
    },
  },
  watch: {
    "pessoa.endereco.uf": function (uf) {
      this.obterObterCidadesPorUF(uf);
    },
  },
  data() {
    return {
      abrirModalDetalhesPessoa: false,
      janelaMensagemWhatsApp: false,
      file: undefined,
      dialog: false,
      loading: true,
      modoEdicao: false,
      exibirFiltros: false,
      desabilitarBotoes: false,
      listaDeReligioes: RELIGIOES.sort(),
      listaDePartidos: PARTIDOS_POLITICOS.sort(),
      listaDeProfissoesEOcupacoes: PROFICOES_OCUPACOES.sort(),
      listaTipoDePessoas: TIPOS_DE_PESSOA.sort(),
      listaDeCidades: [],
      listaDeUFs: [],
      abrirJanelaConfirmacao: false,
      mensagemDeExclusao: "",
      search: "",
      colunas: [
        { text: "Código", value: "id" },
        { text: "Nome", value: "nome", align: "left" },
        { text: "Tipo", value: "tipoDePessoa", align: "left" },
        { text: "Bairro", value: "endereco.bairro" },
        { text: "Celular", value: "celular", align: "center" },
        {
          text: "Total de atendimentos",
          value: "totalAtendimentos",
          align: "center",
        },
        { text: "Data de cadastro", value: "criadoEm", align: "center" },
        { text: "", value: "acoes", align: "right", sortable: false },
      ],
      pessoa: new PessoaModel(),
      pessoas: [],
      opcoes: [],
      pessoasPlanilha: [],
      callbackConfirmarExclusao: () => {},
    };
  },
  mounted() {
    this.obterUFs();
    mixpanel.track("page_view");
    this.opcoes = this.montarOpcoes();
    apiPessoa
      .obterPorFiltros({})
      .then((response) => {
        this.pessoas = response.data.map((p) => new PessoaModel(p));
        this.preencherTabelaParaExportacao();
        this.limparCampos();
        this.loading = false;
      })
      .catch((erro) => {
        this.dispararMensagem(erro.response.data.mensagem, "error");
        this.loading = false;
      });
  },
  methods: {
    preencherEndereco(cep){
      apiCEP.obterEndereco(cep)
      .then(resposta =>{ 
        this.pessoa.endereco.uf = resposta.data && resposta.data.uf || undefined;
        this.pessoa.endereco.cidade = resposta.data && resposta.data.localidade || undefined;
        this.pessoa.endereco.bairro = resposta.data && resposta.data.bairro || undefined;
        this.pessoa.endereco.complemento = resposta.data && resposta.data.complemento || undefined;
        this.pessoa.endereco.rua = resposta.data && resposta.data.logradouro || undefined;
      })
      .catch(/* Não precisa tratar aqui */)
    },

    adicionarPessoa() {
      mixpanel.track('click',{
        nome_botao: 'btn_adicionar_pessoa',
      })
      this.pessoa = new PessoaModel();
      this.dialog = true;
    },
    obterObterCidadesPorUF(uf) {
      apiIBGE.obterMunicipios(uf).then((resposta) => {
        this.listaDeCidades = resposta.data.map((c) => c.nome).sort();
      });
    },
    obterUFs() {
      apiIBGE.obterEstados().then((resposta) => {
        this.listaDeUFs = resposta.data.map((uf) => uf.sigla).sort();
      });
    },
    montarOpcoes() {
      return [
        { id: 1, titulo: "Editar", metodo: this.editarPessoa },
        { id: 2, titulo: "Excluir", metodo: this.excluirPessoa },
        {
          id: 3,
          titulo: "Enviar mensagem",
          metodo: this.abriJanelaMensagem,
        },
      ];
    },

    preencherTabelaParaExportacao() {
      this.pessoasPlanilha = this.pessoas.map((p) => {
        return {
          Codigo: p.id,
          Nome: p.nome,
          Apelido: p.apelido,
          Sexo: p.sexo,
          "Estado Civil": p.estadoCivil,
          "Quantidade de Filhos": p.quantidadeDeFilhos,
          "Data de Nascimento":
            p.dataDeNascimento &&
            moment(p.dataDeNascimento).format("DD/MM/YYYY"),
          PessoaJuridica: p.pessoaJuridica ? "Sim" : "Não",
          CPFOuCNPJ: p.cpfOuCnpj,
          RG: p.rg,
          Email: p.email,
          Celular: p.celular,
          "Rede Social": p.redeSocialPrincipal,
          partido: p.partido,
          Cep: p.endereco.cep,
          Bairro: p.endereco.bairro,
          Cidade: p.endereco.Cidade,
          Rua: p.endereco.rua,
          Numero: p.endereco.numero,
          Complemento: p.endereco.complemento,
          "Tipo de Pessoa": p.tipoDePessoa,
          "Data de Cadadastro":
            p.criadoEm && moment(p.criadoEm).format("DD/MM/YYYY"),
        };
      });
    },

    visualizarDetalhesDaPessoa(pessoa) {
      mixpanel.track('click',{
        nome_botao: 'btn_visualizar_detalhes',
      })
      this.abrirModalDetalhesPessoa = true;
      this.pessoa = new PessoaModel(pessoa);
    },

    salvar() {
      !this.modoEdicao ? this.cadastrarPessoa() : this.atualizarPessoa();
    },

    dataURLtoFile(dataurl, filename) {
      if (!dataurl) return undefined;

      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },

    editarPessoa(pessoa) {
      this.modoEdicao = true;
      this.pessoa = new PessoaModel(pessoa);
      this.file = this.dataURLtoFile(pessoa.foto, "foto.png");
      this.dialog = true;
    },

    cancelar() {
      this.pessoa = new PessoaModel();
      this.file = undefined;
      this.modoEdicao = false;
      mixpanel.track('click',{
        nome_botao: 'btn_cancelar',
      })

      this.$nextTick(() => {
        this.dialog = false;
      });
    },
    selecionarImagem(fileStream) {
      var self = this;
      var file = fileStream;

      if (file.size > 50000) {
        this.dispararMensagem(
          `Selecione uma imagem com tamanho inferior a 50KB`,
          "error"
        );
        self.file = undefined;
        self.pessoa.foto = undefined;
        return;
      }
      var imageType = /image.*/;

      if (file.type.match(imageType)) {
        var reader = new FileReader();

        reader.onload = function () {
          self.pessoa.foto = reader.result;
        };

        reader.readAsDataURL(file);
      } else {
        self.file = undefined;
        self.pessoa.foto = undefined;
        self.dispararMensagem(
          "Selecione um arquivo de imagem nos formatos .png ou .jpg",
          "error"
        );
      }
    },

    limparCampos() {
      this.pessoa = new PessoaModel();
    },
    cadastrarPessoa() {
      this.modoEdicao = false;
      this.desabilitarBotoes = true;
      mixpanel.track('click',{
        nome_botao: 'btn_cadastrar',
      })
      apiPessoa
      .cadastrar(this.pessoa)
      .then((response) => {
        this.dispararMensagem(`Cadastrado efetuado com sucesso!`, "success");
        this.pessoas.push(new PessoaModel(response.data));
        this.limparCampos();
        this.dialog = false;
        this.desabilitarBotoes = false;
        mixpanel.track("cadastrou_pessoa", {
            pessoa_id: response.data.id
          });
        })
        .catch((erro) => {
          this.dispararMensagem(erro.response.data.mensagem, "error");
          this.desabilitarBotoes = false;
        });
    },
    atualizarPessoa() {
      this.modoEdicao = true;
      this.desabilitarBotoes = true;
      apiPessoa
        .atualizar(this.pessoa)
        .then((response) => {
          mixpanel.track("editou_pessoa",{
            pessoa_id: this.pessoa.id
          });

          this.dispararMensagem(
            `${this.pessoa.nome} foi atualizado(a) com sucesso!`,
            "success"
          );
          this.atualizarPessoaNaLista(new PessoaModel(response.data));
          this.limparCampos();
          this.dialog = false;
          this.desabilitarBotoes = false;
        })
        .catch((erro) => {
          this.dispararMensagem(erro.response.data.mensagem, "error");
          this.desabilitarBotoes = false;
        });
    },

    abriJanelaMensagem(pessoa) {
      this.janelaMensagemWhatsApp = true;
      this.pessoa = new PessoaModel(pessoa);
    },

    excluirPessoa(pessoa) {
      this.pessoa = new PessoaModel(pessoa);
      this.mensagemDeExclusao = `Deseja realmente excluir a usuario &nbsp; <strong>${pessoa.nome}</strong>?`;
      this.callbackConfirmarExclusao = this.deletarPessoa;
      this.abrirJanelaConfirmacao = true;
    },
    deletarPessoa() {
      apiPessoa
        .deletar(this.pessoa)
        .then(() => {
          mixpanel.track("excluiu_pessoa",{
            pessoa_id: this.pessoa.id
          });

          this.dispararMensagem(
            `Pessoa ${this.pessoa.nome} foi deletada com sucesso!`,
            "success"
          );
          this.atualizarPessoaNaLista(this.pessoa, true);
        })
        .catch((erro) =>
          this.dispararMensagem(erro.response.data.mensagem, "error")
        )
        .finally((this.abrirJanelaConfirmacao = false));
    },

    atualizarPessoaNaLista(pessoa, deletarDaLista) {
      var index = this.pessoas
        .map((p) => parseInt(p.id))
        .indexOf(parseInt(pessoa.id));
      !deletarDaLista
        ? this.pessoas.splice(index, 1, pessoa)
        : this.pessoas.splice(index, 1);
    },
  },
};
</script>
<style scoped>
.tamanho-tab-cadastro {
  min-height: 300px;
}
.alinhamento {
  text-align: end;
}
form {
  width: 100%;
}

.mensagem-validacao {
  color: red;
}

.endereco-expansivel {
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  margin: 2px;
  background: #fff;
}
.totalizador {
  padding: 0 10px;
  height: 25px;
}

.loading-titulo {
  font-size: 1.2em;
  font-weight: 400;
}
</style>