<template>
  <v-container  class="mt-8">
    <div :dark="!!(isDark === 'dark')" style="width: 100%">
      <v-snackbar
        v-model="snackbar"
        :vertical="false"
        :timeout="timeout"
        top="top"
        :color="tipo"
        elevation="5"
        multi-line
        right
        shaped
      >
        {{ mensagem }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false">OK</v-btn>
        </template>
      </v-snackbar>

      <v-row>
        <v-flex>
          <v-layout wrap>
            <v-col class="pl-0 ml-0 mb-0" cols="12" xs="12" sm="12">
              <h1 class="titulo mb-0">Controle de templates</h1>
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" class="pl-0 ml-0" sm="6">
              <v-btn
                class="btn-primario ml-0"
                color="primary"
                elevation="0"
                @click="adicionarTemplate"
              >
                <v-icon class="mr-2 icone">mdi-plus</v-icon>
                Adicionar</v-btn
              >
            </v-col>

            <v-card width="100%">
              <v-card-title class="pt-0">
                <v-spacer></v-spacer>
                <v-col cols="12" xs="12" sm="2">
                  <v-text-field
                    v-model="pesquisa"
                    append-icon="mdi-magnify"
                    label="Pesquisar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-card-title>
              <v-data-table
                :headers="colunas"
                :items="templates"
                :search="pesquisa"
                dense
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="item in items" :key="item.id">
                      <td class="text-left">
                        {{ item.id }}
                      </td>
                      <td class="text-left">
                        {{ item.descricao }}
                      </td>
                      <td class="text-left">
                        {{ item.tipo }}
                      </td>
                      <td class="text-center">
                        <v-chip :class="item.principal | cores" dark>
                          {{ item.principal == false ? "Não" : "Sim" }}
                        </v-chip>
                      </td>
                      <td class="text-left">
                        {{ item.criadoEm | data_br }}
                      </td>
                      <td class="text-right">
                        <v-btn
                          class="btn-secundario"
                          text
                          @click="imprimir(item)"
                        >
                          <v-icon left size="17"> print</v-icon> imprimir
                        </v-btn>
                        <v-btn
                          class="btn-secundario"
                          text
                          @click="editar(item)"
                        >
                          <v-icon left size="17"> edit </v-icon> Editar
                        </v-btn>
                        <v-btn
                          class="btn-secundario"
                          text
                          @click="deletar(item)"
                        >
                          <v-icon left size="17"> delete </v-icon> Excluir
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-card>
          </v-layout>
        </v-flex>
      </v-row>
    </div>

    <alteracao-status
      idBotaoPrimario="btn-confirmar-cancelar-template"
      idBotaoSecundario="btn-desfazer-cancelar-template"
      v-model="abrirJanelaExclusao"
      :callbackPrimario="callbackExcluirTemplate"
      :mensagem="mensagemExclusao"
    />
  </v-container >
</template>

<script>
import mixinMensagen from "../mixin/mixin-mensagem";
import mixinGeral from "../mixin/mixin-geral";
import apiTemplate from "@/api/template-api";
import Template from "@/model/template-model";
import dataUtils from "@/utils/data.js";
import AlteracaoStatus from '@/components/JanelaConfirmacao.vue'
import impressaoUtils from "@/utils/impressao";
import mixpanel from "mixpanel-browser";
export default {
  name: "ControleDeTempletes",
  mixins: [mixinMensagen, mixinGeral],
  components: {
    "alteracao-status": AlteracaoStatus,
  },
  data() {
    return {
      mensagemExclusao: "Deseja realmente excluir essa template",
      abrirJanelaExclusao: false,
      callbackExcluirTemplate: () => {},
      template: new Template(),
      pesquisa: undefined,
      loading: false,
      carregando: "Buscando templates ...",
      colunas: [
        { text: "Id", align: "start", sortable: true, value: "id" },
        {
          text: "Descricao",
          align: "left",
          sortable: true,
          value: "descricao",
        },
        {
          text: "Tipo",
          align: "left",
          sortable: true,
          value: "tipo",
        },
        {
          text: "Principal",
          align: "center",
          sortable: true,
          value: "principal",
        },
        {
          text: "Data de criação",
          align: "left",
          sortable: true,
          value: "criadoEm",
        },
        { text: "", align: "right", sortable: false, value: "opcoes" },
      ],
      templates: [],
    };
  },
  filters: {
    data_br(dataIso) {
      return dataUtils.aplicarMascaraEmDataIso(dataIso);
    },
    cores(valor) {
      return !valor ? "red" : "green";
    },
  },
  mounted() {
    mixpanel.track("page_view");
    apiTemplate
      .obterTodos()
      .then((resposta) => {
        this.templates = resposta.data.map((t) => new Template(t));
      })
      .catch((erro) => {
        this.dispararMensagem(erro.response.data.mensagem, "error");
      });
  },
  methods: {
    imprimir(item){
      console.log(item)
      mixpanel.track("click",{
        nome_botao: 'btn_imprimir_template',
      });
      impressaoUtils.imprimir(
        `Impressão - ${item.tipo} - ${item.descricao}`,
        item.valor
      );
    },
    adicionarTemplate(){
      this.$router.push({
        name: "ModeloTemplate",
      });
    },
    
    editar(template) {
      this.$router.push({
        name: "EdicaoTemplate",
        params: { idTemplate: template.id },
      });
    },

    removerTemplateDaTabela(id) {
      let index = this.templates
        .map((t) => parseInt(t.id))
        .indexOf(parseInt(id));
      if (index < 0) {
        return;
      }
      this.templates.splice(index, 1);
    },

    deletar(template) {
      this.abrirJanelaExclusao = true;
      this.template = new Template(template);
      this.callbackExcluirTemplate = this.excluir;
    },
    excluir() {
      apiTemplate
        .deletar(this.template.id)
        .then(() => {
          this.removerTemplateDaTabela(this.template.id);
          this.abrirJanelaExclusao = false;
          mixpanel.track("excluiu_template",{
            template_id: this.template.id
          });
        })
        .catch((erro) => {
          this.dispararMensagem(erro.response.data.mensagem, "error");
        });
    },

    adicionar() {
      mixpanel.track("click",{
        nome_botao: 'btn_adicionar_template',
      });
      this.$router.push({
        name: "modelo-de-template",
      });
    },
  },
};
</script>
<style scoped>
.mensagem-embreve {
  text-align: center;
}
.titulo-embreve {
  color: #00a82d;
}
.texto-embreve {
  font-size: 15px;
}
</style>
