import api from './api'
function logar(usuario){
  usuario = usuario || {};
  usuario.senha = usuario.senha && btoa(usuario.senha) || undefined;
  return  new Promise((resolve, reject) =>{
    return api.post('/login', {email: usuario.email, senha: usuario.senha})
    .then(response => resolve(response))
    .catch(error => reject(error))
  });
}

function deslogar(){
  return  new Promise((resolve, reject) =>{
    return api.delete('/logout')
    .then(response => resolve(response))
    .catch(error => reject(error))
  });
}

function obterTodos(){
  return  new Promise((resolve, reject) =>{
    return api.get('/usuarios')
    .then(response => resolve(response))
    .catch(error => reject(error))
  });
}

function cadastrar(usuario){
  usuario = usuario || {};
  usuario.senha = usuario.senha && btoa(usuario.senha) || undefined;
  return  new Promise((resolve, reject) =>{
    return api.post('/usuarios', usuario)
    .then(response => resolve(response))
    .catch(error => reject(error))
  });
}


function atualizar(usuario){
  usuario = usuario || {};
  usuario.senha = usuario.senha && btoa(usuario.senha) || undefined;
  return  new Promise((resolve, reject) =>{
      return api.put(`/usuarios/${usuario.id}`, usuario)
      .then(response => resolve(response))
      .catch(error => reject(error))
  });
}

function deletar(usuario){
  usuario = usuario || {};
  return  new Promise((resolve, reject) =>{
      return api.delete(`/usuarios/${usuario.id}`)
      .then(response => resolve(response))
      .catch(error => reject(error))
  });
}

function atualizarPerfil(usuario, senhaAtual, novaSenha){
  usuario = usuario || {};
  senhaAtual = senhaAtual && btoa(senhaAtual) || undefined;
  novaSenha = novaSenha && btoa(novaSenha) || undefined;
  return  new Promise((resolve, reject) =>{
      return api.post(`/usuarios/${usuario.id}/gerenciar-perfil`, {
        usuario,
        senhaAtual,
        novaSenha
      })
      .then(response => resolve(response))
      .catch(error => reject(error))
  });
}

  function enviarSugestaoDeMelhoriaOuNovoRecurso(usuario ,melhoriaOuNovoRecurso){
    usuario = usuario || {};
    return  new Promise((resolve, reject) =>{
        return api.post(`/usuarios/${usuario.id}/melhoria-novorecurso`, {
          melhoriaOuNovoRecurso
        })
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  }


  function redefinirSenha(email){
    return  new Promise((resolve, reject) =>{
        return api.post(`/usuarios/redefinir-senha`, {
          email
        })
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  }


export default {
  logar,
  deslogar,
  obterTodos,
  cadastrar,
  atualizar,
  deletar,
  atualizarPerfil,
  enviarSugestaoDeMelhoriaOuNovoRecurso,
  redefinirSenha
}