<template>
  <div class="fundo-login">
    <div id="login">
      <v-snackbar
        v-model="snackbar"
        :vertical="false"
        :timeout="timeout"
        top="top"
        :color="tipo"
        elevation="5"
        multi-line
        right
        shaped
      >
        {{ mensagem }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false">OK</v-btn>
        </template>
      </v-snackbar>

      <v-card :loading="loading" class="box box-login" elevation="12">
        <v-col class="logo" cols="12" col="12">
          <!-- <img src="../assets/img/SVG/logo-preto.svg" alt="" /> -->
        <v-img
          v-if="!!(isDark !== 'dark')"
          width="290px"
          alt="Logotipo Gestão Política Fácil"
          src="../assets/img/SVG/logo-preto.svg"
        ></v-img>
        <v-img
          v-else
          width="290px"
          alt="Logotipo Gestão Política Fácil"
          src="../assets/img/SVG/logo-branco.svg"
        ></v-img>
        </v-col>

        <form class="form-login" autocomplete="off">
          <!-- Login -->
          <v-col v-if="!telaEsqueciSenha" cols="12" class="m-0 p-0">
            <v-text-field
              color="primary"
              label="Email"
              v-model="usuario.email"
            ></v-text-field>
            <v-text-field
              @keyup.enter="logar"
              label="Senha"
              v-model="usuario.senha"
              :append-icon="value ? 'visibility_off' : 'visibility'"
              @click:append="() => (value = !value)"
              :type="value ? 'password' : 'text'"
            ></v-text-field>
            <v-spacer class="py-1"></v-spacer>
            <v-btn
              color="primary"
              @click="logar"
              type="button"
              class="btn-entrar"
              elevation="0"
            >
              Entrar
            </v-btn>
            <div class="lembrar-senha pt-2">
              <a @click="telaEsqueciSenha = true">Esqueci minha senha</a>
            </div>
          </v-col>
          
          <!-- Redefinir Senha -->
          <v-col v-if="telaEsqueciSenha" cols="12" class="m-0 p-0">
            <h3 class="pb-3">Preencha seu e-mail para redefinir sua senha</h3>
            <v-text-field
              color="primary"
              label="Email"
              v-model="usuario.email"
            ></v-text-field>
            <div class="lembrar-senha pt-2">
              <v-btn
                color="primary"
                @click="recuperarSenha"
                type="button"
                class="btn-entrar"
                elevation="0"
              >
                Enviar nova senha
              </v-btn>
              <v-spacer class="py-1"></v-spacer>
                <v-btn
              color="secundary"
              @click="telaEsqueciSenha=false"
              type="button"
              class="btn-entrar"
              elevation="0"
            >
              Voltar
            </v-btn>
            </div>
          </v-col>

          <v-col>
            <v-divider class="pt-5"></v-divider>
          </v-col>
          <div class="nova-conta">
            <p>Ainda não possui uma conta?</p>
          </div>
          <v-btn color="light" @click="criarConta" type="button" elevation="0">
            Criar minha conta grátis!
          </v-btn>
        </form>
      </v-card>
    </div>
  </div>
</template>
<script>
import apiUsuario from "../api/usuario-api";
import utilsStorage from "../utils/storage";
import mixinMensagen from "../mixin/mixin-mensagem";
import mixinGeral from "../mixin/mixin-geral";
import UsuarioModel from "../model/usuario-model";

// Tracking
import mixpanel from "mixpanel-browser";

export default {
  name: "login",
  mixins: [mixinMensagen, mixinGeral],
  data() {
    return {
      loading: false,
      usuario: new UsuarioModel(),
      value: "password",
      telaEsqueciSenha: false,
    };
  },
  mounted(){
    mixpanel.track("page_view");
  },
  methods: {
    recuperarSenha() {
      mixpanel.track('click', {
        nome_botao: 'btn_esqueci_senha'
      })
      if (!this.usuario.email) {
        this.dispararMensagem(
          "Preencha o e-mail para recuperar a senha.",
          "error"
        );
        return;
      }

      apiUsuario
        .redefinirSenha(this.usuario.email)
        .then(() => {
          this.dispararMensagem(
            "Enviamos as informações para recuperar a senha no seu email informado.",
            "success"
          );
          this.telaEsqueciSenha=false
        })
        .catch((error) =>   
        this.dispararMensagem(
            (error.response &&
              error.response.data &&
              error.response.data.mensagem) ||
              "Não foi possível se comunicar com o servidor!",
            "error")
          );
    },
    logar() {
      mixpanel.track('click', {
        nome_botao: 'btn_login'
      })
      if (!this.usuario.email || !this.usuario.senha) {
        this.dispararMensagem("Informe o e-mail e senha para acesso.", "error");
        return;
      }

      this.loading = true;

      // Criei um novo objeto para não ficar mudando a senha por baixo e o usuario ver.
      let objEnvio = Object.assign({},this.usuario);
      
      apiUsuario
        .logar(objEnvio)
        .then((response) => {
          this.loading = false;
          this.iniciarTracking(response.data)
          this.salvarInformacoesLocalStorage(
            response.data.usuario,
            response.data.token
          );
          this.$router.push({ name: "DashboardPessoa" });
        })
        .catch((error) => {
          this.dispararMensagem(
            (error.response &&
              error.response.data &&
              error.response.data.mensagem) ||
              "Não foi possível se comunicar com o servidor!",
            "error"
          );
          utilsStorage.removerAutenticacao();
          this.loading = false;
        });
    },
    iniciarTracking(dados){
      // Inicia pendo
      window.pendo.initialize({
        visitor: {
            id: dados.usuario.id,
            email: dados.usuario.email,
            full_name: dados.usuario.nome,
            role: dados.usuario.perfil.descricao
        },
        account: {
            id: 'CLIENTES',
            name: 'CLIENTES_GERAIS' 
        }
      });
      mixpanel.identify(dados.usuario.id);
      mixpanel.people.set({
        $distinct_id: dados.usuario.id,
        $nome: dados.usuario.nome,
        $email: dados.usuario.email,
        $perfil: dados.usuario.idPerfil,
      });
      mixpanel.people.set_once({
        $primeiro_login: new Date().toISOString(),
      })
      mixpanel.people.set({
        $ultimo_login: new Date().toISOString()
      })
      },

    criarConta() {
      mixpanel.track('click', {
        nome_botao: 'btn_criar_conta'
      })
      window.location.href = "https://www.gestaopoliticafacil.com.br/";
    },

    salvarInformacoesLocalStorage(usuario, token) {
      utilsStorage.salvarTokenNaStorage(token);
      utilsStorage.salvarUsuarioNaStorage(usuario);
    },
  },
};
</script>

<style scoped>
.fundo-login {
  background-image: url("../assets/img/bg.jpg");
  background-size: cover !important;
  width: 100vw !important;
  height: 100vh;
  padding: 0;
  margin: 0;
}

.box-login {
  width: 350px;
  text-align: center;
  border-radius: 10px;
}

.logo {
  font-size: 39px;
  font-weight: 800;
  text-align: center;

  display: block;
  height: 90px;
  margin: auto auto;
  margin-top: 35px;
  width: 270px;
}

.form-login {
  width: 100%;
  margin-bottom: 20px;
}

.btn-entrar {
  width: 100%;
  margin: 0;
}

.btn-entrar:hover {
  cursor: pointer;
  background-color: rgb(217, 223, 217);
}

.btn-entrar:focus {
  outline: none;
}
.lembrar-senha {
  text-align: center;
  font-size: 12px;
  margin-top: 10px;
}
.nova-conta {
  text-align: center;
  font-size: 12px;
}

.nova-conta a:hover {
  text-align: center;
  font-size: 12px;
}

div {
  width: 100%;
}

@media only screen and (max-width: 400px) {
  .box {
    width: 0;
  }
}
</style>
