<template>
  <div>
    <v-snackbar
      v-model="snackbar"
      :vertical="false"
      :timeout="timeout"
      top="top"
      :color="tipo"
      elevation="5"
      multi-line
      right
      shaped
    >
      {{ mensagem }}
      <template v-slot:action="{ attrs }">
        <v-btn
          class="btn-tertiary"
          text
          v-bind="attrs"
          @click="snackbar = false"
          >OK</v-btn
        >
      </template>
    </v-snackbar>
    <!-- menu lateral  -->
    <v-navigation-drawer
      v-model="drawer"
      expand-on-hover
      mini-variant
      app
      :dark="!!(isDark === 'dark')"
    >
      <MenuStatic></MenuStatic>
    </v-navigation-drawer>

    <!-- Barra de cima -->
    <v-app-bar elevation="0" clipped-right :dark="!!(isDark === 'dark')">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title style="width: 300px" class="ml-0 pl-6">
        <v-img
          v-if="!!(isDark !== 'dark')"
          width="180px"
          alt="Logotipo Gestão Política Fácil"
          src="../assets/img/SVG/logo-preto.svg"
        ></v-img>
        <v-img
          v-else
          width="180px"
          alt="Logotipo Gestão Política Fácil"
          src="../assets/img/SVG/logo-branco.svg"
        ></v-img>
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-tooltip bottom >
          <template v-slot:activator="{ on }">
           <v-btn @click="chamarSuporte()" v-on="on" icon>
            <v-icon color="primary">mdi-whatsapp</v-icon>
          </v-btn>
          </template>
          <span>Suporte</span>
      </v-tooltip>
   
      <!-- Menu de notificações -->
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :disabled="!aniversariantesDoMes.length"
            v-bind="attrs"
            v-on="on"
            icon
          >
            <v-badge
              :color="!aniversariantesDoMes.length ? '' : 'error'"
              overlap
            >
              <template v-slot:badge>
                <span v-if="aniversariantesDoMes.length">{{
                  aniversariantesDoMes.length
                }}</span>
              </template>

              <v-icon>mdi-bell-outline</v-icon>
            </v-badge>
          </v-btn>
        </template>

        <v-card class="mx-auto" max-width="400" max-height="300">
          <v-card-text class="text--primary">
            <v-card max-width="400" class="mx-auto">
              <v-list subheader two-line>
                <v-subheader>Aniversariantes do mês</v-subheader>
                <v-list-item
                  v-for="aniversariante in aniversariantesDoMes"
                  :key="aniversariante.title"
                >
                  <v-list-item-avatar>
                    <v-icon
                      color="primary"
                      v-text="aniversariante.icon"
                    ></v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title
                      v-text="aniversariante.nome"
                    ></v-list-item-title>

                    <!-- <v-list-item-subtitle  class="aniversariantes-detalhes"           
                    >Nascido em: <strong>{{ aniversariante.dataDeNascimento | data }}</strong> <br>
                    Celular: {{aniversariante.celular}} </v-list-item-subtitle> -->

                    <v-list-item-subtitle class="aniversariantes-detalhes"
                      >Nascido em:
                      <strong>{{
                        aniversariante.dataDeNascimento | data
                      }}</strong>
                      <br />
                      Celular: {{ aniversariante.celular }} <br />
                      <a
                        href="#"
                        @click="enviiarMensagem(aniversariante)"
                        text
                        link
                        >Enviar mensagem</a
                      >
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-card-text>
        </v-card>
      </v-menu>

      <!-- Menu de gerenciamento de perfil -->
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon large v-bind="attrs" v-on="on">
            <v-avatar color="#EDEDED" size="42px">
              <!-- <v-img src="https://randomuser.me/api/portraits/women/85.jpg"></v-img> -->
              <span class="iniciais"> {{ iniciaisDoUsuario }}</span>
            </v-avatar>
          </v-btn>
        </template>

        <v-card class="mx-auto text-center" max-width="400">
          <v-card-subtitle class="pb-6 pt-8">
            <v-btn icon large>
              <v-avatar color="#ededed" size="75px">
                <!-- <v-img src="https://randomuser.me/api/portraits/women/85.jpg"></v-img> -->
                <span class="iniciais"> {{ iniciaisDoUsuario }}</span>
              </v-avatar>
            </v-btn>
          </v-card-subtitle>

          <v-card-text class="text--primary">
            <p class="nome-usuario">{{ usuario.nome }}</p>
            <p>{{ usuario.email }}</p>

            <div
              @click="abrirJanelaGerenciadorPerfil = true"
              class="container-gerenciar-usuarios"
            >
              <a
                @click="abrirJanelaGerenciadorPerfil = true"
                :class="`gerenciar-usuarios ${
                  !!(isDark === 'dark') ? 'dark' : ''
                }`"
                href="#"
                >Gerenciar seu perfil de usuário</a
              >
            </div>

            <v-list>
                <a
                @click="logout()"
                :class="`ma-5 sair ${!!(isDark === 'dark') ? 'dark' : ''}`"
                outlined
                >Sair</a
              >

              <v-divider></v-divider>
              <div
                :class="`rodape-card-perfil ${
                  !!(isDark === 'dark') ? 'dark' : ''
                }`"
              >
                <!-- <a href="#">Novidadades da versão</a> |  -->
                <a @click="abrirJanelaMelhoria = true" href="#"
                  >Sugestões de melhorias ou novos recursos</a
                >
              </div>
            </v-list>
          </v-card-text>

          <v-card-actions></v-card-actions>
        </v-card>
      </v-menu>

      <!-- Menu de novidades da versão -->
      <v-menu v-if="false" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" v-bind="attrs" v-on="on" icon>
            <v-icon color="primary">mdi-help-circle-outline</v-icon>
          </v-btn>
        </template>

        <v-card class="mx-auto text-center" max-width="400">
          <v-card-text class="text--primary">
            <v-card max-width="450" class="mx-auto">
              <v-toolbar color="primary" dark>
                <v-spacer></v-spacer>
                <v-toolbar-title>
                  <h4>Novidadades da versão V1.0.5</h4>
                </v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>

              <v-list three-line>
                <template v-for="(item, index) in items">
                  <v-subheader
                    v-if="item.header"
                    :key="item.header"
                    v-text="item.header"
                  ></v-subheader>

                  <v-divider
                    v-else-if="item.divider"
                    :key="index"
                    :inset="item.inset"
                  ></v-divider>

                  <v-list-item v-else :key="item.title">
                    <!-- <v-list-item-avatar>
                      <v-img :src="item.avatar"></v-img>
                    </v-list-item-avatar> -->

                    <v-list-item-content>
                      <v-list-item-title
                        v-html="item.title"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="item.subtitle"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list>
            </v-card>
          </v-card-text>
        </v-card>
      </v-menu>

      <!-- Darkmode, configurar esse cara assim que possível. -->
      <v-btn icon id="mode-switcher" @click="alterarTema">
        <moon-icon
          v-if="$vuetify.theme.dark"
          size="1.5x"
          class="custom-class"
        ></moon-icon>
        <sun-icon v-else size="1.5x" class="custom-class"></sun-icon>
      </v-btn>
    </v-app-bar>

    <gerenciador-de-perfil
      v-model="abrirJanelaGerenciadorPerfil"
      :usuario="usuario"
    />

    <enviar-mensagem-whats-app
      :janelaMensagemWhatsApp="janelaMensagemWhatsApp"
      @fechar-modal="janelaMensagemWhatsApp = false"
      v-model="pessoa"
    >
    </enviar-mensagem-whats-app>

    <v-dialog v-model="abrirJanelaMelhoria" persistent max-width="700px">
      <v-card class="pl-5 pr-5 pb-5 pt-2">
        <!-- <v-card-title class=""> Alterar informações do usuário </v-card-title> -->
        <v-card-text>
          <v-row>
            <v-col cols="12" sm6="6" xs="12">
              <h2>Sugestão de melhoria ou novo recurso.</h2>
              <br />
              <span
                ><em>
                  Todas sugestões de melhorias ou novos recursos, serão
                  analisados pela nossa equipe técnica para verificar a
                  viabilidade, caso seja viável, a mesma será integrada no
                  sistema em versões futuras.</em
                >
              </span>
            </v-col>

            <v-col cols="12" sm6="6" xs="12">
              <v-textarea
                auto-grow
                outlined
                rows="6"
                v-model="melhoriaOuNovoRecurso"
                label="Sugestão"
                hint="Se possível, informe o máximo de detalhes em sua sugestão."
                persistent-hint
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!melhoriaOuNovoRecurso"
            color="primary"
            @click="enviarMelhoria"
          >
            Enviar
          </v-btn>
          <v-btn @click="abrirJanelaMelhoria = false"> Cancelar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import utilsStorage from "../utils/storage";
import routes from "../router/routes";
import apiUsuarios from "../api/usuario-api";
import apiPessoas from "../api/pessoa-api";
import GerenciadorDePerfil from "@/components/GerenciadorDePerfil";
import EnviarMensagemWhatsApp from "@/components/EnviarMensagemWhatsApp";
import mixinMensagen from "../mixin/mixin-mensagem";
import mixinGeral from "../mixin/mixin-geral";
import moment from "moment";
import PessoaModel from "../model/pessoa-model";
import MenuStatic from "./MenuStatic";
// Tracking
import mixpanel from 'mixpanel-browser'

export default {
  name: "Menu",
  mixins: [mixinMensagen, mixinGeral],
  components: {
    GerenciadorDePerfil,
    EnviarMensagemWhatsApp,
    MenuStatic,
  },
  filters: {
    data(valor) {
      return moment(valor).format("DD/MM/YYYY");
    },
  },
  data() {
    return {
      dialog: false,
      drawer: null,
      rotas: [],
      usuario: undefined,
      abrirJanelaGerenciadorPerfil: false,
      abrirJanelaMelhoria: false,
      melhoriaOuNovoRecurso: undefined,
      items: [
        // { header: "Recursos liberados em 05/03/2021 as 07:15hs" },
        {
          // avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
          title:
            '<span class="primary--text mb-3" >Mudanças no visual da aplicação.<span>',
          subtitle: `<span class="text--primary">Fizemos melhorias no <strong>layout do sistema</strong> para ficar visivelmente mais agradável</span>`,
        },
        { divider: true, inset: true },
        {
          // avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
          title:
            '<span class="primary--text mb-3" >Quantidade de atendimentos por pessoa.<span>',
          subtitle: `<span class="text--primary">
          Criamos na tabela de pessoas uma coluna para trazer o <strong>total de atendimentos</strong>.</span>`,
        },
        // { divider: true, inset: true },
        // {
        //   // avatar: "https://cdn.vuetifyjs.com/images/lists/3.jpg",
        //    title: '<span class=" mb-3" >Adicinando campo de solicitante no filtro de atendimento.<span>',
        //   subtitle:
        //     `<span class="text--primary">Foi adicionado no filtro de atendimento uma nova opção para filtrar por <strong>solicitante</strong>.</span>`,
        // },
        { divider: true, inset: true },

        {
          // avatar: "https://cdn.vuetifyjs.com/images/lists/5.jpg",
          title:
            '<span class="primary--text mb-3" >Criamos 2 novos gráficos no Dashboard<span>',
          subtitle:
            '<span class="text--primary">Criamos um gráfico de Donuts e outro de Barras.</span>',
        },
      ],
      aniversariantesDoMes: [],
      pessoa: new PessoaModel(),
      janelaMensagemWhatsApp: false,
    };
  },
  computed: {
    mostrarRota() {
      return (
        this.$router.currentRoute.name != "Login" &&
        this.$router.currentRoute.name != "Assinatura"
      );
    },
    iniciaisDoUsuario() {
      var palavras =
        (this.usuario.nome && this.usuario.nome.toLowerCase().split(" ")) || "";
      if (!Array.isArray(palavras)) {
        return "";
      }
      palavras = palavras.filter((p) => p.length > 2);
      let inicial = palavras.map((p) => p[0].toUpperCase());
      return inicial.join("");
    },
  },
  created() {
    //TODO: Criar inteligencia aqui para filtrar o menu
    this.usuario = utilsStorage.obterUsuarioAtualStorage();
    if (this.usuario.perfil.id != 1) {
      this.rotas = routes.filter((r) => !r.meta.is_admin);
    } else {
      this.rotas = routes;
    }

    this.rotas = this.rotas.filter((r) => r.name != "Assinatura");
  },

  mounted() {
    this.$vuetify.theme.dark = utilsStorage.obterPreferenciaDaStorage();
    this.obterAniversariantes();
  },

  methods: {
    chamarSuporte(){
      mixpanel.track('click', {
        nome_pagina: this.$route.path,
        nome_botao: 'btn_suporte'
      })
      window.open('https://wa.me/5522998486801', '_blank');
    },

    alterarTema() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      utilsStorage.salvarPreferenciaNoStorage(this.$vuetify.theme.dark);
      mixpanel.track('click', {
        nome_botao: 'alterar_tema'
      })
      mixpanel.people.set({
        $tema_dark: this.$vuetify.theme.dark
      })
    },
    enviiarMensagem(aniversariante) {
      this.janelaMensagemWhatsApp = true;
      this.pessoa = new PessoaModel(aniversariante);
      mixpanel.track('click', {
        nome_botao: 'btn_enviar_mensagem'
      })
    },
    obterAniversariantes() {
      apiPessoas
        .obterAniversariantes()
        .then((resposta) => {
          this.aniversariantesDoMes = resposta.data["porMes"].map((p) => {
            return {
              color: "blue",
              icon: "mdi-cake-variant",
              apelido: p.apelido,
              nome: p.nome,
              dataDeNascimento: p.dataDeNascimento,
              celular: p.celular,
              id: p.id,
              cpfouCnpj: p.cpfouCnpj,
            };
          });
        })
        .then((error) => {
          console.log(error);
        });
    },
    logout() {
      mixpanel.track('click', {
        nome_botao: 'btn_logout'
      })
      apiUsuarios
        .deslogar()
        .then(() => {
          utilsStorage.removerAutenticacao();
          this.$router.push({ name: "Login" });
        })
        .catch((error) =>
          this.dispararMensagem(error.response.data.mensagem, "error")
        );
    },
    enviarMelhoria() {
      mixpanel.track('click', {
        nome_botao: 'btn_sugestao_melhoria',
      })
      apiUsuarios
        .enviarSugestaoDeMelhoriaOuNovoRecurso(
          this.usuario,
          this.melhoriaOuNovoRecurso
        )
        .then((resposta) => {
          this.abrirJanelaMelhoria = false;
          this.melhoriaOuNovoRecurso = undefined;
          this.dispararMensagem(
            `Sua sugestão foi registrada. Codigo: ${resposta.data.codigo}`,
            "success",
            15000
          );
        })
        .catch((error) => {
          this.dispararMensagem(error.response.data.mensagem, "error");
        });
    },
  },
};
</script>

<style scoped>
.v-list-item {
  min-height: 35px;
}
.v-list-item__action {
  margin: 0;
}
.btn-escrever {
  margin-top: 20px;
  margin-bottom: 10px;
}
.v-btn:not(.v-btn--round).v-size--default {
  /* padding: 25px 60px; */
}

.v-btn:not(.v-btn--round).v-size--default.btn-melhoria {
  padding: 10px;
}

.nome-usuario {
  font-weight: 500;
}
.v-application p {
  margin: 0px;
}
.v-card__text {
  padding: 0px;
}
.rodape-card-perfil {
  margin-top: 25px;
}
.rodape-card-perfil a {
  font-size: 12px;
  color: #5f6368;
  text-decoration: none;
}
.rodape-card-perfil.dark a {
  color: #ddd;
}
.sair {
  background-color: #ffffff;
  border: 1px solid #dadce0;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  display: inline-block;
  font: 500 14px/16px Google Sans, Quicksand, Helvetica, Arial, sans-serif;
  letter-spacing: 0.15px;
  margin: 16px;
  outline: 0;
  padding: 10px 24px;
  text-align: center;
  text-decoration: none;
  white-space: normal;
  font-size: 12px;
  color: #5f6368;
}
.sair.dark {
  background-color: #1b1b1b;
  border: 1px solid #dadce0;
  color: #ddd;
}
.adicionar-conta {
  color: #3c4043;
  font-size: 14px;
}
div.v-list-item__action.acao-conta-usuario {
  margin-right: 16px;
  margin-left: 43px;
  padding-top: 17px;
  padding-bottom: 17px;
}
.gerenciar-usuarios {
  background-color: #ffffff;
  border: 1px solid #dadce0;
  -webkit-border-radius: 100px;
  border-radius: 100px;
  color: #3c4043;
  display: inline-block;
  letter-spacing: 0.25px;
  margin: 16px 0 0;
  max-width: 254px;
  outline: 0;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
}
.gerenciar-usuarios.dark {
  background-color: #1b1b1b;
  border: 1px solid #dadce0;
  color: #ddd;
}
.container-gerenciar-usuarios {
  width: 310px;
}

.v-sheet.v-card {
  border-radius: 10px;
}
span.iniciais {
  font-size: 13px;
  color: #00a82d;
  font-weight: 600;
}
/*  mudando o menu lateral */
.v-item--active.v-list-item--active.v-list-item.v-list-item--link,
.v-list .v-list-item--active .v-icon {
  color: #00a82d !important;
}

.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled),
.v-icon {
  color: #9899ad !important;
}

.aniversariantes-detalhes {
  font-size: 12px;
}
</style>