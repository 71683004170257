
import api from './api'

export default {
  cadastrar(model) {
    model = model || {};
    return  new Promise((resolve, reject) =>{
      return api.post('/templates', model)
      .then(response => resolve(response))
      .catch(error => reject(error))
    });
  },

  obterTodos(){
    return  new Promise((resolve, reject) =>{
        return api.get('/templates')
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },

  obter(id) {
    return  new Promise((resolve, reject) =>{
      return api.get('/templates/' + id)
      .then(response => resolve(response))
      .catch(error => reject(error))
    });
  },

  deletar(id) {
    return  new Promise((resolve, reject) =>{
      return api.delete('/templates/'+id)
      .then(response => resolve(response))
      .catch(error => reject(error))
    });
  },

  atualizar(model) {
    model = model || {};
    return  new Promise((resolve, reject) =>{
      return api.put('/templates/' + model.id, model)
      .then(response => resolve(response))
      .catch(error => reject(error))
    });
  },
};
