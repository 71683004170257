<template>
  <v-dialog v-model="model" persistent max-width="50%">
    <v-card>
      <v-card-text>
        <v-row class="mt-5">
          <v-col cols="12">
            <h2>Enviar mensagem pelo whatsapp</h2>
          </v-col>
          <v-col cols="12">
            <p><strong>Nome:</strong> {{ this.pessoa.nome }} </p>
            <p><strong>Apelido:</strong> {{ this.pessoa.apelido }} </p>
            <p><strong>Celular:</strong> {{ this.pessoa.celular }} </p>
          </v-col>
          <v-col v-if="!pessoa.celular" cols="12" class="text-center mb-0 pb-0">
            <p class="mensagem-validacao">
              Só é possível enviar mensagens para pessoas que tenham celular
              cadastrado.
            </p>
          </v-col>
          <v-col cols="12">
            <v-textarea
              auto-grow
              outlined
              rows="6"
              v-model="mensagemWhatsApp"
              label="Mensagem"
              hint="Exemplo para formar em negrito: * Ola! *  e italico: _ Ola! _ "
              persistent-hint
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="btn-tertiary"
          text
          @click="enviarMensagemWhatsApp()"
          :disabled="!pessoa.celular"
          >Enviar
        </v-btn>
        <v-btn
          class="btn-quaternary"
          text
          @click="fecharJanela"
          >Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PessoaModel from "../model/pessoa-model";

export default {
  name: "EnviarMensagemWhatsApp",
  model: {
    prop: "itemSelecionado",
    event: "onChange",
  },
  props: {
    janelaMensagemWhatsApp: { type: Boolean, default: false },
    itemSelecionado: { type: PessoaModel, default: () => new PessoaModel() },
  },
  watch: {
    itemSelecionado() {
      this.pessoa = this.itemSelecionado;
    },
    janelaMensagemWhatsApp() {
      this.model = this.janelaMensagemWhatsApp;
    },
  },

  data() {
    return {
      mensagemWhatsApp: undefined,
      pessoa: this.itemSelecionado,
      model: this.janelaMensagemWhatsApp,
    };
  },
  methods: {
    enviarMensagemWhatsApp() {
      let mensagem = encodeURIComponent(this.mensagemWhatsApp)
        .replace(/'/g, "%27")
        .replace(/"/g, "%22");
      window.open(
        `http://wa.me/55${this.pessoa.celular}/?text=${mensagem}`,
        "_blank"
      );
      this.mensagemWhatsApp = ``;
      this.model = false;
    },

    fecharJanela() {
      this.model = false;
      this.$emit("fechar-modal", this.model);
    },
  },
};
</script>