<template>
  <div>
    <v-snackbar
      v-model="snackbar"
      :vertical="false"
      :timeout="timeout"
      top="top"
      :color="tipo"
      elevation="5"
      multi-line
      right
      shaped
    >
      {{ mensagem }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">OK</v-btn>
        >
      </template>
    </v-snackbar>

    <v-dialog v-model="visivel" persistent max-width="1024px">
      <v-card>
        <v-card-text>
          <v-container>
            <v-row class="mt-5">
              <v-col cols="12">
                <div>
                  <h2 class="mb-2">
                    <strong> Atendimento: </strong>
                    <em class="mr-3">{{ model.id }}</em>
                    <v-chip
                      class="ma-1 ml-0"
                      :color="obterCorPorStatus(model.status)"
                      text-color="white"
                    >
                      {{ model.status.descricao }}
                    </v-chip>
                  </h2>

                  <h4>
                    <strong> Cadastrado em: </strong>
                    <em>{{ model.criadoEm | data }}</em>
                  </h4>
                  <h4>
                    <strong> Solicitante: </strong>
                    <em>{{ model.solicitante.nome }}</em>
                  </h4>
                  <h4>
                    <strong> Resumo: </strong> <em>{{ model.resumo }}</em>
                  </h4>
                </div>
                <v-divider></v-divider>
              </v-col>

              <v-col cols="3" sm="12" md="4">
                <v-autocomplete
                  autocomplete="off"
                  :items="listaDeTiposDeContato"
                  item-text="descricao"
                  v-model="parecer.tipoDeContato"
                  label="Tipo de contato"
                  return-object
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <v-text-field
                  autocomplete="off"
                  label="Pessoa de contato"
                  v-model="parecer.pessoaDeContato"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <v-autocomplete
                  :items="listaDeStatus"
                  label="Alterar status"
                  item-text="descricao"
                  v-model="parecer.statusAtendimento"
                  return-object
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  auto-grow
                  outlined
                  rows="6"
                  v-model="parecer.descricao"
                  label="Descrição"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            :disabled="
              desabilitarBotaoSalvar ||
              !parecer.pessoaDeContato ||
              !parecer.descricao
            "
            class="btn-tertiary"
            text
            @click="salvarParecer"
            >Salvar</v-btn
          >
          <v-btn class="btn-quaternary" text @click="fecharModal"
            >Cancelar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import mixinMensagen from "../mixin/mixin-mensagem";

//Modelos
import AtendimentoModel from "../model/atendimento-model";
import ParecerModel from "../model/parecer-model";
import StatusModel from "../model/status-model";

//APIs
import apiAtendimentos from "../api/atentimento-api";
import apiTipoDeContatos from "../api/tipo-de-contato-api";
import apiStatus from "../api/status-api";
import TipoDeContatoModel from "../model/tipo-de-contato-model";
import moment from "moment";

export default {
  name: "CadastroDeParecer",
  mixins: [mixinMensagen],
  model: {
    prop: "itemSelecionado",
    event: "onChange",
  },
  props: {
    itemSelecionado: {
      type: AtendimentoModel,
      default: () => new AtendimentoModel(),
    },
    visivel: { type: Boolean, default: false },
  },
  filters: {
    data(valor) {
      return moment(valor).format("DD/MM/YYYY");
    },
  },

  data() {
    return {
      model: this.itemSelecionado,
      listaDeTiposDeContato: [],
      listaDeStatus: [],
      parecer: new ParecerModel(),
      desabilitarBotaoSalvar: false,
    };
  },
  watch: {
    itemSelecionado() {
      this.model = this.itemSelecionado;
      this.parecer.statusAtendimento = this.listaDeStatus.find(
        (s) => s.id == this.model.status.id
      );
      this.$emit("onChange", this.model);
    },
  },
  components: {},
  mounted() {
    this.obterListaDeTiposDeContato();
    this.obterStatus();
  },
  methods: {
    obterCorPorStatus(status) {
      switch (parseInt(status.id)) {
        case 1:
          return "grey";
        case 2:
          return "blue";
        case 3:
          return "green";
        case 4:
          return "orange";
        case 5:
          return "red";
      }
    },
    emitirAtendimento() {
      this.$emit("onChange", this.model ? this.model : undefined);
    },
    fecharModal() {
      this.visivel = false;
      this.$emit("fechar-modal", this.visivel);
    },
    obterStatus() {
      apiStatus.obterTodos().then((resposta) => {
        this.listaDeStatus = resposta.data.map((c) => new StatusModel(c));
      });
    },
    obterListaDeTiposDeContato() {
      apiTipoDeContatos
        .obterTodos()
        .then((resposta) => {
          this.listaDeTiposDeContato = resposta.data.map(
            (t) => new TipoDeContatoModel(t)
          );
        })
        .catch((error) => {
          this.dispararMensagem(error.response.data.mensagem, "error");
        });
    },
    salvarParecer() {
      this.desabilitarBotaoSalvar = true;
      this.parecer.idAtendimento = this.model.id;
      apiAtendimentos
        .cadastrarParecer(this.parecer)
        .then((resposta) => {
          let parecer = new ParecerModel(resposta.data[0]);
          this.model.pareceres.push(parecer);
          this.model.status = parecer.statusAtendimento;
          this.parecer = new ParecerModel();
          this.emitirAtendimento();
          this.fecharModal();
          this.dispararMensagem(`Parecer adicionado com sucesso!`, "success");
        })
        .catch((error) => {
          this.dispararMensagem(error.response.data.mensagem, "error");
        })
        .finally((this.desabilitarBotaoSalvar = false));
    },
  },
};
</script>
<style lang="scss">
</style>
