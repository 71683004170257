
import api from './api'

function cadastrar(formData){
    return  new Promise((resolve, reject) =>{
        return api({
            method: 'post',
            url: '/anexos/atendimentos',
            data: formData,
            headers: {'Content-Type': 'multipart/form-data' }
            })
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
}

    function obterPeloIdAtendimento(idAtendimento){
        return  new Promise((resolve, reject) =>{
            return api.get(`/anexos/atendimentos/${idAtendimento}`)
            .then(response => resolve(response))
            .catch(error => reject(error))
        });
    }

    function remover(anexo){
        return  new Promise((resolve, reject) =>{
            return api.delete('/anexos/'+ anexo.id, {
                headers: {           
                },
                data: anexo
              })
            .then(response => resolve(response))
            .catch(error => reject(error))
        });
    }

export default {
    cadastrar,
    obterPeloIdAtendimento,
    remover
}