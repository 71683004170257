
import api from './api'

function obterTodos(){
    return  new Promise((resolve, reject) =>{
        return api.get('/pessoas')
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
}

function obterPorFiltros(filtros){
    let filtroDataCadastro = `dataInicialCadastro=${filtros.dataInicialCadastro || ''}&dataFinalCadastro=${filtros.dataFinalCadastro || ''}`;
    let filtroDataResolucao = `dataInicialResolucao=${filtros.dataInicialResolucao || ''}&dataFinalResolucao=${filtros.dataFinalResolucao || ''}`;
    let filtroPorSolicitante = `idSolicitante=${filtros.idSolicitante || ''}`;

    let query = `idPessoa=${filtros.codigo || ''}&${filtroPorSolicitante}&${filtroDataCadastro}&${filtroDataResolucao}`;
    return  new Promise((resolve, reject) =>{
        return api.get(`/pessoas?${query}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
}


function cadastrar(pessoa){
    pessoa = pessoa || {};
    return  new Promise((resolve, reject) =>{
      return api.post('/pessoas', pessoa)
      .then(response => resolve(response))
      .catch(error => reject(error))
    });
}

function atualizar(pessoa){
    pessoa = pessoa || {};
    return  new Promise((resolve, reject) =>{
        return api.put(`/pessoas/${pessoa.id}`, pessoa)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
}

function deletar(pessoa){
    pessoa = pessoa || {};
    return  new Promise((resolve, reject) =>{
        return api.delete(`/pessoas/${pessoa.id}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
}

function obterAniversariantes(){
    return  new Promise((resolve, reject) =>{
        return api.get(`/aniversariantes`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
}

export default {
    obterTodos,
    cadastrar,
    atualizar,
    deletar,
    obterPorFiltros,
    obterAniversariantes
}