'use strict';
const ACESSO ={
  //  LOCAL: "http://localhost:3333",
  //  HOMOLOGACAO: "http://104.131.102.13:3333",
   PRODUCAO: "https://app.gestaopoliticafacil.com.br/api",
}

const URLS = {
  //BASE: ACESSO.LOCAL
  // BASE: ACESSO.HOMOLOGACAO
  BASE: ACESSO.PRODUCAO
};

const TEMPO_ESPERA_PARA_PESQUISA_AUTOCOMPLETE = 1500;

const INPUT_KEY_CODES = {
    enter: 13
};

const OBJETO_ALERTA = {
  disparar: false,
  mensagem: '',
  tipo: 'error',
};


const FILTROS_PESQUISA = {
  CODIGO: {
    descricao: "Código",
    valor: "codigo"
  },
  DATA_CADASTRO: {
    descricao: "Data de cadastro",
    valor: {
      INICIAL: "dataInicialCadastro",
      FINAL: "dataFinalCadastro"
    }
  },
  DATA_RESOLUCAO: {
    descricao: "Data de resolução",
    valor: {
      INICIO: "dataInicioResolucao",
      TERMINO: "dataFinalResolucao"
    }
  },

  EMPRESA: {
    descricao: "Empresa",
    valor: "empresa"
  },
  IDENTIFICADOR_ATENDIMENTO: {
    descricao: "Atendimento",
    valor: "id"
  },
  IDENTIFICADOR_PESSOA: {
    descricao: "Pessoa",
    valor: "idCliente"
  },
  STATUS: {
    descricao: "Status",
    valor: "listaStatus"
  }
}

const PERIODO_EMISSAO = {
  ULTIMOS_3_DIAS: {
    descricao: 'Últimos 3 dias',
    valor: 'ULTIMOS_3_DIAS'
  },
  ESSA_SEMANA: {
    descricao: 'Essa semana',
    valor: 'ESSA_SEMANA'
  },
  SEMANA_PASSADA: {
    descricao: 'Semana passada',
    valor: 'SEMANA_PASSADA'
  },
  ESSE_MES: {
    descricao: 'Esse mês',
    valor: 'ESSE_MES'
  },
  SELECIONAR_DATA: {
    descricao: 'Selecionar data',
    valor: 'SELECIONAR_DATA'
  }
};

const PERIODO_EMISSAO_LISTA = [
  PERIODO_EMISSAO.ULTIMOS_3_DIAS,
  PERIODO_EMISSAO.ESSA_SEMANA,
  PERIODO_EMISSAO.SEMANA_PASSADA,
  PERIODO_EMISSAO.ESSE_MES,
  PERIODO_EMISSAO.SELECIONAR_DATA
];

const STATUS_ATENDIMENTO = {
  PENDENTE: {
    valor: 'PENDENTE',
    descricao: 'Pendente',
    // classeExibicao: 'chip-exibicao-status-orcamento-em-digitacao',
    // classeSelecao: 'chip-color-em-digitacao',
    id: 1,
  },
  ANDAMENTO: {
    valor: 'ANDAMENTO',
    descricao: 'Andamento',
    // classeExibicao: 'chip-exibicao-status-orcamento-aguardando',
    // classeSelecao: 'chip-color-aguardando',
    id: 2,
  },
  RESOLVIDO: {
    valor: 'RESOLVIDO',
    descricao: 'Resolvido',
    // classeExibicao: 'chip-exibicao-status-orcamento-aprovado',
    // classeSelecao: 'chip-color-aprovado',
    id: 3,
  },
  INSATISFEITO: {
    valor: 'INSATISFEITO',
    descricao: 'Insatisfeito',
    // classeExibicao: 'chip-exibicao-status-orcamento-aprovado',
    // classeSelecao: 'chip-color-aprovado',
    id: 4,
  },
  CANCELADO: {
    valor: 'CANCELADO',
    descricao: 'Cancelado',
    // classeExibicao: 'chip-exibicao-status-orcamento-reprovado',
    // classeSelecao: 'chip-color-reprovado',
    id: 5,
  },
  // PARALIZADO: {
  //   valor: 'PARALIZADO',
  //   descricao: 'Paralizado',
  //   // classeExibicao: 'chip-exibicao-status-orcamento-reprovado',
  //   // classeSelecao: 'chip-color-reprovado',
  //   id: 6,
  // }
};

const STATUS_ATENDIMENTO_LISTA = [
  STATUS_ATENDIMENTO.PENDENTE,
  STATUS_ATENDIMENTO.ANDAMENTO,
  STATUS_ATENDIMENTO.RESOLVIDO,
  STATUS_ATENDIMENTO.INSATISFEITO,
  STATUS_ATENDIMENTO.CANCELADO,
  // STATUS_ATENDIMENTO.PARALIZADO
];
const PERIODO_DASHBOARD = [
  { descricao: 'Hoje', valor: 'HOJE' },
  { descricao: 'Semana passada', valor: 'SEMANA_PASSADA' },
  { descricao: 'Este mês', valor: 'ESTE_MES' },
  { descricao: 'Últimos 30 dias', valor: 'ULTIMOS_30_DIAS' },
  { descricao: 'Todos', valor: 'TODOS' }
]

export { 
    TEMPO_ESPERA_PARA_PESQUISA_AUTOCOMPLETE,
    INPUT_KEY_CODES,
    OBJETO_ALERTA,
    URLS,
    FILTROS_PESQUISA,
    PERIODO_EMISSAO,
    PERIODO_EMISSAO_LISTA,
    STATUS_ATENDIMENTO,
    STATUS_ATENDIMENTO_LISTA,
    PERIODO_DASHBOARD
};