<template>
  <v-container class="mt-8">
    <v-row>
      <v-snackbar
        v-model="snackbar"
        :vertical="false"
        :timeout="timeout"
        top="top"
        :color="tipo"
        elevation="5"
        multi-line
        right
        shaped
      >
        {{ mensagem }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false">OK</v-btn>
        </template>
      </v-snackbar>
      <v-col class="pb-0 mb-2" cols="12" xs="12" sm="12">
        <h1 class="titulo mb-2">Agenda</h1>
        <v-divider></v-divider>
      </v-col>

      <v-col sm="12" md="3" class="mb-4 controls">
        <v-btn
          fab
          small
          absolute
          left
          color="primary"
          @click="$refs.calendar.prev()"
        >
          <v-icon dark>mdi-chevron-left</v-icon>
        </v-btn>

        <v-btn
          fab
          small
          absolute
          right
          color="primary"
          @click="$refs.calendar.next()"
        >
          <v-icon dark>mdi-chevron-right</v-icon>
        </v-btn>
        <br /><br /><br />
        <center class="mb-2">
          <h3 class="titulo">{{ mesEAnoDeReferencia }}</h3>
        </center>

        <v-autocomplete
          :items="listaDeUsuarios"
          return-object
          v-model="usuario"
          item-text="nome"
          outlined
          dense
          clearable
          label="Usuário agendado"
          autocomplete="off"
        ></v-autocomplete>
        <v-select
          v-model="type"
          :items="typeOptions"
          label="Apresentação"
          hide-details
          outlined
          dense
        ></v-select>

        <v-select
          v-model="weekdays"
          :items="weekdaysOptions"
          dense
          outlined
          hide-details
          class="mt-3"
          label="Dias da semana"
        ></v-select>
      </v-col>
      <v-col sm="12" lg="9" class="pl-4">
        <v-sheet height="600">
          <v-calendar
            ref="calendar"
            v-model="start"
            :type="type"
            :start="start"
            :end="end"
            :min-weeks="minWeeks"
            :max-days="maxDays"
            :now="now"
            :dark="dark"
            :weekdays="weekdays"
            :first-interval="intervals.first"
            :interval-minutes="intervals.minutes"
            :interval-count="intervals.count"
            :interval-height="intervals.height"
            :show-interval-label="showIntervalLabel"
            :short-intervals="shortIntervals"
            :short-months="shortMonths"
            :short-weekdays="shortWeekdays"
            :color="color"
            :events="eventos"
            :event-overlap-mode="mode"
            :event-overlap-threshold="45"
            :event-color="getEventColor"
            locale="pt"
            @change="obterEventos"
            @click:event="abrirVisualizacaoAgendamentos"
            @click:more="mostrarMaisAgendamentos"
            @click:date="abrirTelaCadastroAgendamento"
          ></v-calendar>
        </v-sheet>
      </v-col>

      <!-- Visualização dos agendamentos -->
      
        <v-dialog v-model="visualizarAgendamento" persistent max-width="1024px" >
          <v-card flat>
            <v-toolbar
              :class="
                !!(isDark === 'dark') ? 'titulo-modal-dark' : 'titulo-modal'
              "
            >
              <v-col cols="12" sm="8">
                <v-toolbar-title>
                  <p>{{ agendamento.descricao }}</p></v-toolbar-title
                >
              </v-col>
              <v-col class="text-right" cols="12" sm="4">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="abrirJanelaParaEdicao()"
                    >
                      <v-icon color="primary">mdi-pencil</v-icon>
    
                    </v-btn>
                  </template>
                  <span>Editar agendamento</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="confirmarDelecaoAgendamento()"
                    >
                      <v-icon color="primary">mdi-delete</v-icon> 
                 
                    </v-btn>
                  </template>
                  <span>Excluir agendamento</span>
                </v-tooltip>
              </v-col>
            </v-toolbar>
            <v-card-text>
              <v-row class="mt-2">
                <v-col cols="12" sm="12">
                  <p>
                    <strong class="tamanho-informacao titulo"
                      >Tipo de agendamento</strong
                    >
                  </p>
                  <p class="mt-1">
                    <v-chip
                      class="geral"
                      :color="agendamento.tipoAgendamento.cor"
                      text-color="white"
                    >
                      {{ agendamento.tipoAgendamento.descricao }}
                    </v-chip>
                  </p>
                </v-col>
                <v-col cols="12" sm="4">
                  <p>
                    <strong class="tamanho-informacao titulo">Usuário</strong>
                  </p>
                  <p>
                    {{ agendamento.usuario.nome }}
                    <strong>{{ "(" + agendamento.usuario.email + ")" }}</strong>
                  </p>
                </v-col>
                <v-col cols="12" sm="4">
                  <p>
                    <strong class="tamanho-informacao titulo"
                      >Data e hora inicial</strong
                    >
                  </p>
                  <p>
                    {{ agendamento.dataInicial | dataEHora }}
                  </p>
                </v-col>
                <v-col cols="12" sm="4">
                  <p>
                    <strong class="tamanho-informacao titulo"
                      >Data e hora final</strong
                    >
                  </p>
                  <p>
                    {{ agendamento.dataFinal | dataEHora }}
                  </p>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    auto-grow
                    outlined
                    dense
                    readonly
                    rows="8"
                    v-model="agendamento.observacao"
                    label="Observação"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn @click="fecharVisualizacaoAgendamento()"> Fechar </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
   

      <!-- modal cadastro e edição -->
      <v-dialog v-model="abrirJanelaAgendamento" persistent max-width="1024px">
        <v-card>
          <v-card-title
            :class="
              !!(isDark === 'dark') ? 'titulo-modal-dark' : 'titulo-modal'
            "
          >
            {{ modoEdicao ? "Editar" : "Adicionar" }} agendamento
          </v-card-title>

          <v-card-text>
            <v-row class="mt-2">
              <v-col cols="12" sm="12" md="5">
                <v-text-field
                  v-model="agendamento.descricao"
                  label="Descrição"
                  counter="50"
                  maxlength="50"
                  outlined
                    dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="12" md="3">
                <v-autocomplete
                  :items="tiposDeAgendamento"
                  return-object
                  v-model="agendamento.tipoAgendamento"
                  item-text="descricao"
                  label="Tipo Agendamento"
                  autocomplete="off"
                  outlined
                    dense
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="12" md="4">
                <v-autocomplete
                  :items="listaDeUsuarios"
                  return-object
                  v-model="agendamento.usuario"
                  item-text="nome"
                  label="Usuário"
                  autocomplete="off"
                  outlined
                    dense
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="12" md="3">
                <date-picker
                  label="Data inicial"
                  v-model="agendamento.dataInicial"
                  data-minima="1900-01-01"
                >
                </date-picker>
              </v-col>
              <v-col cols="12" sm="12" md="3">
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="horarioInicial"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="horarioInicial"
                      label="Horário inicial"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                    dense
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="menu1"
                    format="24hr"
                    v-model="horarioInicial"
                    full-width
                    color="primary"
                    @click:minute="$refs.menu1.save(horarioInicial)"
                    outlined
                    dense
                  ></v-time-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" sm="12" md="3">
                <date-picker
                  label="Data final"
                  v-model="agendamento.dataFinal"
                  :data-minima="agendamento.dataInicial"
                >
                </date-picker>
              </v-col>
              <v-col cols="12" sm="12" md="3">
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="horarioFinal"
                      label="Horário final"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                    dense
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="menu2"
                    format="24hr"
                    v-model="horarioFinal"
                    full-width
                    color="primary"
                    @click:minute="$refs.menu2.save(horarioFinal)"
                    outlined
                    dense
                  ></v-time-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="12">
                <v-textarea
                  auto-grow
                  outlined
                    dense
                  rows="8"
                  v-model="agendamento.observacao"
                  label="Observação"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="aguardando"
              color="primary"
              @click="salvarAgendamento"
              >Salvar</v-btn
            >
            <v-btn text class="btn-quaternary" @click="cancelarAgendamento"
              >Cancelar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Janela de vários agendamentos no mesmo dia com a mesma descricão. -->
        <v-dialog v-model="visualizarMaisAgendamentos" persistent max-width="1024px">
          <v-card flat>
            <v-toolbar
              :class="
                !!(isDark === 'dark') ? 'titulo-modal-dark' : 'titulo-modal'
              "
            >
              <v-col cols="12" sm="8">
                <v-toolbar-title> <p>Agendamentos</p></v-toolbar-title>
              </v-col>
            </v-toolbar>
            <v-card-text>
              <v-row class="mt-2">
                <v-col cols="12" sm="12">
                  <v-list subheader three-line>
                    <template
                      v-for="agendamento of listaDeAgendamentosFiltradosPorDia"
                    >
                      <v-list-item :key="agendamento.id">
                        <v-list-item-content>
                          <v-list-item-title>
                            <v-chip
                              class="geral mb-2"
                              :color="agendamento.tipoAgendamento.cor"
                              text-color="white"
                            >
                              {{ agendamento.tipoAgendamento.descricao }}
                            </v-chip>
                            <h3 class="titulo">
                              {{ agendamento.descricao }}
                            </h3>

                            <p>
                              <strong> Data e hora inicial: </strong>
                              {{ agendamento.dataInicial | dataEHora }}
                            </p>
                            <p>
                              <strong> Data e hora final: </strong>
                              {{ agendamento.dataFinal | dataEHora }}
                            </p>

                            <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn class="ml-0"
                               
                                v-bind="attrs"
                                v-on="on"
                                @click="editarAgendamentoLista(agendamento)"
                              >
                                <v-icon color="primary">mdi-pencil</v-icon>
                                Editar
                              </v-btn>
                            </template>
                            <span>Editar agendamento</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                              
                                v-bind="attrs"
                                v-on="on"
                                @click="deletarAgendamentoLista(agendamento)"
                              > 
                                <v-icon color="primary">mdi-delete</v-icon>
                                Excluir
                              </v-btn>
                            </template>
                            <span>Excluir agendamento</span>
                          </v-tooltip>
                          </v-list-item-title>
                        </v-list-item-content>

                        <!-- <v-list-item-action>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click="editarAgendamentoLista(agendamento)"
                              >
                                <v-icon color="primary">mdi-pencil</v-icon>
                              </v-btn>
                            </template>
                            <span>Editar agendamento</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click="deletarAgendamentoLista(agendamento)"
                              >
                                <v-icon color="primary">mdi-delete</v-icon>
                              </v-btn>
                            </template>
                            <span>Excluir agendamento</span>
                          </v-tooltip>
                        </v-list-item-action> -->
                      </v-list-item>
                    </template>
                  </v-list>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn @click="visualizarMaisAgendamentos = false">
                Fechar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
   
      <janela-confirmacao
        v-model="abrirJanelaConfirmacao"
        :callbackPrimario="deletarAgendamento"
        :mensagem="mensagemDeExclusao"
      />
    </v-row>
  </v-container>
</template>

<script>
import utilsData from "@/utils/data";
import DatePicker from "@/components/comum/Datepicker";
import JanelaConfirmacao from "@/components/JanelaConfirmacao";
import apiAgenda from "@/api/agendamento-api";
import apiUsuario from "@/api/usuario-api";
import AgendamentoModel from "@/model/agendamento-model";
import TipoAgendamentoModel from "@/model/tipo-agendamento-model";
import UsuarioModel from "@/model/usuario-model";
import mixinMensagen from "../mixin/mixin-mensagem";
import mixinGeral from "../mixin/mixin-geral";
import moment from "moment";
import mixpanel from 'mixpanel-browser'
const weekdaysDefault = [0, 1, 2, 3, 4, 5, 6];
const intervalsDefault = {
  first: 0,
  minutes: 60,
  count: 24,
  height: 48,
};

export default {
  name: "Agenda",
  mixins: [mixinMensagen, mixinGeral],
  components: {
    DatePicker,
    JanelaConfirmacao,
  },
  computed: {
    mesEAnoDeReferencia() {
      let mes = this.meses.find(
        (m) => m.id == parseInt(this.start.substring(5, 7))
      );
      let ano = this.start.substring(0, 4);
      return `${mes.descricao} de ${ano}`;
    },
  },

  watch: {
    "usuario.id": function (id) {
      this.obterTodosAgendamentos(id);
    },
  },
  data: () => ({
    aguardando: false,
    modoEdicao: false,
    visualizarMaisAgendamentos: false,
    abrirJanelaConfirmacao: false,
    mensagemDeExclusao: "Confirma a exclusão deste agendamento?",
    time: undefined,
    horarioInicial: '00:00',
    horarioFinal: '00:00',
    menu1: false,
    menu2: false,
    usuario: undefined,
    agendamento: new AgendamentoModel({
      dataInicial: new Date().toISOString().substring(0, 10),
      dataFinal: new Date().toISOString().substring(0, 10),
    }),
    listaDeAgendamentos: [],
    listaDeAgendamentosFiltradosPorDia: [],
    listaDeUsuarios: [],
    tiposDeAgendamento: [],
    eventos: [],
    visualizarAgendamento: false,
    abrirJanelaAgendamento: false,
    dark: false,
    startMenu: false,
    start: new Date().toISOString().substring(0, 10),
    endMenu: false,
    end: new Date().toISOString().substring(0, 10),
    nowMenu: false,
    minWeeks: 1,
    now: null,
    type: "month",
    typeOptions: [
      { text: "Dia", value: "day" },
      { text: "4 Dias", value: "4day" },
      { text: "Semana", value: "week" },
      { text: "Mês", value: "month" },
    ],
    mode: "stack",
    meses: [
      { id: 1, descricao: "Janeiro" },
      { id: 2, descricao: "Fevereiro" },
      { id: 3, descricao: "Março" },
      { id: 4, descricao: "Abril" },
      { id: 5, descricao: "Maio" },
      { id: 6, descricao: "Junho" },
      { id: 7, descricao: "Julho" },
      { id: 8, descricao: "Agosto" },
      { id: 9, descricao: "Setembro" },
      { id: 10, descricao: "Outubro" },
      { id: 11, descricao: "Novembro" },
      { id: 12, descricao: "Dezembro" },
    ],

    weekdays: weekdaysDefault,
    weekdaysOptions: [
      { text: "De domingo a sábado", value: weekdaysDefault },
      { text: "Segunda, Quarta e Sexta", value: [1, 3, 5] },
      { text: "De segunda a sexta", value: [1, 2, 3, 4, 5] },
      { text: "De segunda a domingo", value: [1, 2, 3, 4, 5, 6, 0] },
    ],
    intervals: intervalsDefault,
    intervalsOptions: [
      { text: "Default", value: intervalsDefault },
      {
        text: "Workday",
        value: { first: 16, minutes: 30, count: 20, height: 48 },
      },
    ],
    maxDays: 7,
    maxDaysOptions: [
      { text: "7 days", value: 7 },
      { text: "5 days", value: 5 },
      { text: "4 days", value: 4 },
      { text: "3 days", value: 3 },
    ],
    styleInterval: "default",
    styleIntervalOptions: [
      { text: "Default", value: "default" },
      { text: "Workday", value: "workday" },
      { text: "Past", value: "past" },
    ],
    color: "primary",

    shortIntervals: true,
    shortMonths: false,
    shortWeekdays: false,
  }),

  filters: {
    dataEHora(valor) {
      return utilsData.aplicarMascaraDataHoraSemSegundos(valor);
    },
  },
  
  mounted() {
    this.obterTiposDeAgendamento();
    this.obterTodosAgendamentos();
    this.obterTodosOsUsuariosVinculadosAssinatura();
    mixpanel.track("page_view")
  },

  methods: {
    editarAgendamentoLista(agendamento) {
      this.agendamento = new AgendamentoModel(agendamento);
      this.abrirJanelaParaEdicao();
      mixpanel.track("click",{
        nome_botao: 'btn_editar_agendamento'
      });
    },

    deletarAgendamentoLista(agendamento) {
      this.agendamento = new AgendamentoModel(agendamento);
      this.confirmarDelecaoAgendamento();
      mixpanel.track("click",{
        nome_botao: 'btn_excluir_agendamento'
      });
    },

    mostrarMaisAgendamentos(dados) {
      this.listaDeAgendamentosFiltradosPorDia = this.listaDeAgendamentos.filter(
        (a) => {
          let dia = moment(a.dataInicial).format("DD");
          return !!(dia == dados.day);
        }
      );
      console.log(this.listaDeAgendamentosFiltradosPorDia);
      this.visualizarMaisAgendamentos = true;
      mixpanel.track("click",{
        nome_botao: 'btn_ver_mais_agendamentos'
      });
    },

    confirmarDelecaoAgendamento() {
      this.abrirJanelaConfirmacao = true;
    },
    removerAgendamentoDaLista() {
      let indice = this.listaDeAgendamentos.findIndex(
        (a) => a.id == this.agendamento.id
      );
      if (indice < 0) return;
      this.listaDeAgendamentos.splice(indice, 1);
      this.listaDeAgendamentosFiltradosPorDia = [];
    },
    atualizarAgendamentoNaLista() {
      let indice = this.listaDeAgendamentos.findIndex(
        (a) => a.id == this.agendamento.id
      );
      if (indice < 0) return;
      this.listaDeAgendamentos.splice(indice, 1, this.agendamento);
      this.listaDeAgendamentosFiltradosPorDia = [];
    },

    deletarAgendamento() {
      apiAgenda
        .deletar(this.agendamento.id)
        .then(() => {
          this.removerAgendamentoDaLista();
          this.obterEventos();
          this.abrirJanelaConfirmacao = false;
          this.visualizarAgendamento = false;
          this.visualizarMaisAgendamentos = false;
          this.agendamento = new AgendamentoModel();
          this.dispararMensagem(`Agendamento deletado com sucesso!`, "success");
          mixpanel.track("excluiu_agendamento",{
            agendamento_id: this.agendamento.id
          });
        })
        .catch((error) => {
          this.dispararMensagem(error.response.data.mensagem, "error");
        });
    },

    abrirJanelaParaEdicao() {
      
      this.modoEdicao = true;
      this.visualizarAgendamento = false;
      this.horarioInicial = utilsData.aplicarMascaraHoraSemSegundos(this.agendamento.dataInicial)
      this.horarioFinal = utilsData.aplicarMascaraHoraSemSegundos(this.agendamento.dataFinal);

      this.abrirJanelaAgendamento = true;
    },

    fecharVisualizacaoAgendamento() {
      this.limparCampos();
      this.visualizarAgendamento = false;
    },
    cancelarAgendamento() {
      this.limparCampos();
      this.abrirJanelaAgendamento = false;
      mixpanel.track("click",{
            nome_botao: 'btn_cancelar'
          });
    },
    limparCampos() {
      this.agendamento = new AgendamentoModel();
      this.horarioInicial = undefined;
      this.horarioFinal = undefined;
    },
    _validarHoraInicialEFinalAgendamento(){
      // TODO: melhorar isso aqui assim que tiver tempo.
      // Fiz essa Marreta assim correndo pois o moment esta zuado com o timezone.
      // Ajuste feito correndo para liberação. Ta funcionando, mas ta feio.
      let dataInicalTexto = this.agendamento.dataInicial && this.agendamento.dataInicial.substring(0,10);
      let dataFinalTexto = this.agendamento.dataFinal && this.agendamento.dataFinal.substring(0,10);

      var dataInicial = new Date(dataInicalTexto);
      var dataFinal = new Date(dataFinalTexto);

      if(dataInicial > dataFinal || !this.horarioInicial || !this.horarioFinal ){
        return false;

      } else if((this.agendamento.dataFinal == this.agendamento.dataInicial)  &&
                (this.horarioFinal < this.horarioInicial)){
        return false;
      }

      return true;
    },
    salvarAgendamento() {
      if (!this._validarHoraInicialEFinalAgendamento()) {
       this.dispararMensagem(
          "Data e hora inicial deve ser inferior a data e hora final",
          "error"
        );
        return;
      } 

      if (!this.agendamento.modeloValidoParaCadadastro()) {
        this.dispararMensagem(
          "Todos os campos são obrigatórios, exceto a observação.",
          "error"
        );
        return;
      }

      this.agendamento.dataInicial =
        this.agendamento.dataInicial.toString().substring(0, 11) +
        this.horarioInicial +
        ":00";
      this.agendamento.dataFinal =
        this.agendamento.dataFinal.toString().substring(0, 11) +
        this.horarioFinal +
        ":00";

      this.aguardando = true;
      if (this.modoEdicao) {
        apiAgenda
          .atualizar(this.agendamento)
          .then(() => {
            this.atualizarAgendamentoNaLista();
            this.obterEventos();
            this.dispararMensagem(
              `Agendamento atualizado com sucesso!`,
              "success"
            );
            mixpanel.track('atualizou_agendamento', {
            agendamento_id: this.agendamento.id
          })
            this.abrirJanelaAgendamento = false;
            this.visualizarMaisAgendamentos = false;
            this.limparCampos();
          })
          .catch((error) => {
            this.dispararMensagem(error.response.data.mensagem, "error");
          })
          .finally((this.aguardando = false));
        return;
      }

      apiAgenda
        .cadastrar(this.agendamento)
        .then((resposta) => {
          this.listaDeAgendamentos.push(new AgendamentoModel(resposta.data));
          this.obterEventos();
          this.dispararMensagem(
            `Agendamento realizado com sucesso!`,
            "success"
          );
          this.abrirJanelaAgendamento = false;
          this.limparCampos();
          mixpanel.track('cadatrou_agendamento', {
            agendamento_id: resposta.data.id
          })
        })
        .catch((error) => {
          this.dispararMensagem(error.response.data.mensagem, "error");
        })
        .finally((this.aguardando = false));
      return;
    },
    obterTiposDeAgendamento() {
      apiAgenda
        .obterTiposDeAgendamento()
        .then((resposta) => {
          this.tiposDeAgendamento = resposta.data.map(
            (t) => new TipoAgendamentoModel(t)
          );
        })
        .catch((erro) => console.log(erro));
    },
    obterTodosOsUsuariosVinculadosAssinatura() {
      apiUsuario
        .obterTodos()
        .then((resposta) => {
          this.listaDeUsuarios = resposta.data.map((u) => new UsuarioModel(u));
        })
        .catch((error) =>
          this.dispararMensagem(error.response.data.mensagem, "error")
        );
    },

    abrirVisualizacaoAgendamentos(evento) {
      this.visualizarAgendamento = true;
      this.agendamento = new AgendamentoModel(evento.event.agendamento);
    },
    abrirTelaCadastroAgendamento(objdata) {
      mixpanel.track("click",{
        nome_botao: 'btn_cadastrar_agendamento'
      });
      this.agendamento.dataInicial = objdata.date + "T00:00:00";
      this.agendamento.dataFinal = objdata.date + "T00:00:00";
      this.modoEdicao = false;
      this.abrirJanelaAgendamento = true;
    },
    obterTodosAgendamentos(id) {
      apiAgenda
        .obterTodos()
        .then((resposta) => {
          if (id) {
            let filtrados = resposta.data.filter((a) => a.usuario.id == id);
            this.listaDeAgendamentos = filtrados.map(
              (a) => new AgendamentoModel(a)
            );
          } else {
            this.listaDeAgendamentos = resposta.data.map(
              (a) => new AgendamentoModel(a)
            );
          }

          this.obterEventos();
        })
        .catch((error) =>
          this.dispararMensagem(error.response.data.mensagem, "error")
        );
    },
    viewDay({ date }) {
      this.start = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    showIntervalLabel(interval) {
      return interval.minute === 0;
    },
    obterEventos() {
      this.eventos = this.listaDeAgendamentos.map((a) => {
        return {
          name: a.descricao,
          start: new Date(a.dataInicial).getTime(),
          end: new Date(a.dataFinal).getTime(),
          timed: a.diaInteiro,
          color: a.tipoAgendamento.cor,
          agendamento: a,
        };
      });
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
  },
};
</script>

<style scoped>
.controls {
  position: relative;
}
.tamanho-informacao {
  font-size: 18px;
  font-weight: 600;
}
.titulo-agendamento {
  color: #fff;
  font-size: 22px;
}

div.v-list-item {
  border-bottom: 1px solid #ddd;
}
</style>