<template>
      <v-list tile>
        <v-list-item-group class="pt-15">
          
            <v-list-item  @click="dashPessoasMenu()">
              <v-list-item-action >
                <pie-chart-icon size="1.5x" class="custom-class"></pie-chart-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Painel de pessoas</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="dashAtendimentosMenu()">
              <v-list-item-action >
                <activity-icon size="1.5x" class="custom-class"></activity-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Painel de atendimentos</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="my-5"></v-divider>
            <v-list-item  @click="controlePessoasMenu()">
              <v-list-item-action >
                <users-icon size="1.5x" class="custom-class"></users-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Pessoas</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item  @click="controleAtendimentosMenu()">
              <v-list-item-action >
                <check-square-icon size="1.5x" class="custom-class"></check-square-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Atendimentos</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="agendaMenu()">
              <v-list-item-action >
                <calendar-icon size="1.5x" class="custom-class"></calendar-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Agenda</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item  @click="controleTemplatesMenu()">
              <v-list-item-action >
                <edit-3-icon size="1.5x" class="custom-class"></edit-3-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Templates</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="controleVisitasMenu()">
              <v-list-item-action >
                <eye-icon size="1.5x" class="custom-class"></eye-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Visitas</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="controleCelulasMenu()">
              <v-list-item-action >
                <grid-icon size="1.5x" class="custom-class"></grid-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Células <v-chip class="ml-2" label small> Em breve</v-chip></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="controleOficiosMenu()">
              <v-list-item-action >
                <file-text-icon size="1.5x" class="custom-class"></file-text-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Ofícios <v-chip class="ml-2" label small> Em breve</v-chip></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="controleMocoesMenu()">
              <v-list-item-action >
                <navigation-icon size="1.5x" class="custom-class"></navigation-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Moções <v-chip class="ml-2" label small> Em breve</v-chip></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="controleUsuariosMenu()">
              <v-list-item-action >
                <user-icon size="1.5x" class="custom-class"></user-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Usuários</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
        </v-list-item-group>
      </v-list>
</template>
<script>
import mixpanel from 'mixpanel-browser'
export default {
  data(){
    return {
        items: [
        {
          action: "mdi-cash-multiple",
          active: true,
          items: [
            { title: "Pessoas", path:"/financeiro/contas-a-receber", icon:"mdi-cash-plus" },
            { title: "Atendimentos", path:"/financeiro/contas-a-pagar", icon:"mdi-cash-minus" },
            
          ],
          title: "Painel",
        },    
      ],
    }
  },
  methods:{
    dashAtendimentosMenu(){
      mixpanel.track('click', {
        nome_botao: 'menu_lateral',
        item_menu: 'painel_atendimentos'
      })
      this.$router.push({ name: "DashboardAtendimento" }).catch(() => {});
      
    },
    dashPessoasMenu(){
      mixpanel.track('click', {
        nome_botao: 'menu_lateral',
        item_menu: 'painel_pessoas'
      })
      this.$router.push({ 
        name: "DashboardPessoa"
      })
      .catch(() => {});
    },
    controlePessoasMenu(){
      mixpanel.track('click', {
        nome_botao: 'menu_lateral',
        item_menu: 'controle_pessoas'
      })
      this.$router.push({ 
        name: "ControlePessoas"
      })
      .catch(() => {});
    },
    controleAtendimentosMenu(){
      mixpanel.track('click', {
        nome_botao: 'menu_lateral',
        item_menu: 'controle_atendimentos'
      })
      this.$router.push({ 
        name: "ControleAtendimentos"
      })
      .catch(() => {});
    },
    agendaMenu(){
      mixpanel.track('click', {
        nome_botao: 'menu_lateral',
        item_menu: 'agenda'
      })
      this.$router.push({ 
        name: "Agenda"
      })
      .catch(() => {});
    },
    controleTemplatesMenu(){
      mixpanel.track('click', {
        nome_botao: 'menu_lateral',
        item_menu: 'controle_templates'
      })
      this.$router.push({ 
        name: "ControleDeTemplates"
      })
      .catch(() => {});
    },
    controleCelulasMenu(){
      mixpanel.track('click', {
        nome_botao: 'menu_lateral',
        item_menu: 'controle_celulas'
      })
      this.$router.push({ 
        name: "ControleDeCelulas"
      })
      .catch(() => {});
    },
    controleVisitasMenu(){
      mixpanel.track('click', {
        nome_botao: 'menu_lateral',
        item_menu: 'controle_visitas'
      })
      this.$router.push({ 
        name: "ControleDeVisitas"
      })
      .catch(() => {});
    },
    controleUsuariosMenu(){
      mixpanel.track('click', {
        nome_botao: 'menu_lateral',
        item_menu: 'controle_usuarios'
      })
      this.$router.push({ 
        name: "ControleDeUsuarios"
      })
      .catch(() => {});
    },
    controleOficiosMenu(){
      mixpanel.track('click', {
        nome_botao: 'menu_lateral',
        item_menu: 'controle_oficios'
      })
      this.$router.push({ 
        name: "ControleDeOficios"
      })
      .catch(() => {});
    },
    controleMocoesMenu(){
      mixpanel.track('click', {
        nome_botao: 'menu_lateral',
        item_menu: 'controle_mocoes'
      })
      this.$router.push({ 
        name: "ControleDeMocoes"
      })
      .catch(() => {});
    },


  }
}
</script>

<style scoped>
.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled),
.v-icon {
    color: #9899ad !important;
}
.v-item--active.v-list-item--active.v-list-item.v-list-item--link,
.v-list .v-list-item--active .v-icon {
  color: #00a82d !important;
}
.md-card .md-card-header{

}

</style>