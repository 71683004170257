<template>
  <v-row >
  <v-snackbar
      v-model="snackbar"
      :vertical="false"
      :timeout="timeout"
      top="top"
      :color="tipo"
  
      multi-line
      right

    >
      {{ mensagem }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Ok
        </v-btn>
      </template>
    </v-snackbar>
    
    <v-dialog  v-model="abrirJanela" persistent max-width="500px">
      <v-card class="pl-5 pr-5 pb-5 pt-2">
        <!-- <v-card-title class=""> Alterar informações do usuário </v-card-title> -->       
        <v-card-text>
          <v-row>
            <v-col class="text-center" cols="12" sm6="6" xs="12">
              <v-avatar color="#ededed" size="85" class="mr-4 mt-3">
                <span class="iniciais"> {{ iniciaisDoUsuario }}</span>
              </v-avatar>
              </v-col>
      
            <v-col cols="12" sm6="6" xs="12">
              <v-text-field
                label="Nome"
                v-model="model.nome"
                autocomplete="off"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm6="6" xs="12">
              <v-text-field
                disabled
                label="E-mail"
                v-model="model.email"
                :rules="[
                  (v) =>
                    !v ||
                    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                    'E-mail não é valido',
                ]"
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm6="6" xs="12">
              <v-switch
                v-model="alterarSenha"
                label="Deseja alterar sua Senha?"
                autocomplete="off"
              ></v-switch>
            </v-col>

            <v-col v-if="alterarSenha" cols="12" sm6="6" xs="12">
              <v-text-field
                label="Senha Atual"
                v-model="senhaAtual"
                autocomplete="off"
                :append-icon="value ? 'visibility_off' : 'visibility'"
                @click:append="() => (value = !value)"
                :type="value ? 'password' : 'text'"
              ></v-text-field>

              <v-text-field
                label="Nova senha"
                v-model="novaSenha"
                autocomplete="off"
                :append-icon="valueNovo ? 'visibility_off' : 'visibility'"
                @click:append="() => (valueNovo = !valueNovo)"
                :type="valueNovo ? 'password' : 'text'"
              ></v-text-field>
            </v-col>
          </v-row>

  
        </v-card-text>
          <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary"  @click="salvarAlteracoes"> Salvar </v-btn>
          <v-btn text @click="fecharJanela"> Cancelar </v-btn>
        </v-card-actions>
       
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import mixinMensagen from "../mixin/mixin-mensagem";
import mixinGeral from "../mixin/mixin-geral";
import apiUsuario from "../api/usuario-api";

export default {
  name: "AlteracaoStatus",
  mixins: [mixinMensagen, mixinGeral],
  model: {
    prop: "abrirJanela",
    event: "onChange",
  },
  data() {
    return {
      janelaAberta: this.abrirJanela,
      model: this.usuario,
      value: 'password',
      valueNovo: 'password',
      novaSenha: undefined,
      senhaAtual: undefined,
      alterarSenha: false

    };
  },
  watch:{
    model(teste){
      console.log(teste);
    },

    abrirJanela(valor){
      this.abrirJanela = valor;
    }
  },
  props: {
    abrirJanela: { type: Boolean, default: false },
    usuario: { type: Object, default: () => {} },
  },
  computed:{

    iniciaisDoUsuario(){
      var palavras = this.model.nome && this.model.nome.toLowerCase().split(" ") || '';
      if(!Array.isArray(palavras)){
        return '';
      }
      palavras = palavras.filter(p => p.length > 2);
      let inicial = palavras.map(p =>  p[0].toUpperCase());
      return inicial.join("");
    }
  },

  methods: {
    fecharJanela() {
      this.janelaAberta = false;
      this.$emit("onChange", this.janelaAberta);
    },
    salvarAlteracoes(){
      if(this.alterarSenha && (!this.senhaAtual || !this.novaSenha)){
        this.dispararMensagem(`Informe sua senha atual e a nova senha para alteração.`, "error");
        return;
      }

      // aqui atualizar a senha no backend
      apiUsuario.atualizarPerfil(this.usuario, this.senhaAtual, this.novaSenha)
      .then(resposta => {
        console.log(resposta);
        this.dispararMensagem(`Perfil atualizado com sucesso`, "success");
        this.abrirJanela = false;
      })
      .catch(error => {
        this.dispararMensagem(`${error}`, "error");
      })


    }
  },
};
</script>
<style scoped>
/* .v-card__title.headline {
  justify-content: center;
  align-content: center;
} */
div.v-card__text {
  margin-top: 15px;
  text-align: end;
}

span.iniciais {
    font-size: 13px;
    font-weight: 600;
    color: #005800;
}

</style>
