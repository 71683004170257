<template>
  <v-container class="mt-8">
    <v-row class="py-10" justify="center">
      <v-col>
      <div class="mensagem-embreve">
          <v-img
          height="120px"
          width="120px"
          class="mx-auto"
          alt="Recurso em desenvolvimento"
          src="../assets/img/SVG/aguardando.svg"
        ></v-img>
        <h1 class="titulo-embreve py-5">Este recurso está em desenvolvimento</h1>
        <p class="texto-embreve pb-5">Em breve estará disponível para utilização.</p>
        <v-btn
          class="btn-secundario"
          elevation="0"
          @click="saibaMais()"
          >Saiba mais</v-btn>      
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import mixpanel from 'mixpanel-browser'

export default {
  name: "ControleDeOficios",
  components: {},
  data() {
    return {
      loading: false,
    };
  },
  computed: {},

  methods: {
    saibaMais(){
      mixpanel.track('click', {
        nome_botao: 'btn_saiba_mais',
      })
      window.open("https://www.gestaopoliticafacil.com.br/#recursos", '_blank');
    }
  },
  mounted() {mixpanel.track("page_view")},
};
</script>
<style scoped>
.mensagem-embreve{
  text-align: center;
}
.titulo-embreve{
  color: #00A82D;
}
.texto-embreve{
  font-size: 15px;
}
</style>
