export default class EnderecoModel {
    constructor(obj) {
        obj = obj || {};
        this.id = obj.id;
        this.cep = obj.cep;
        this.rua = obj.rua;
        this.numero = obj.numero;
        this.complemento = obj.complemento;
        this.bairro = obj.bairro;
        this.cidade = obj.cidade;
        this.uf = obj.uf;
    }

    modeloValido(){
        return true;
    }

}
