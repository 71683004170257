const mixinGeral = {
    data(){
        return{
      
        }
    },
    computed:{
        mostrarRota() {
          return this.$router.currentRoute.name != "Login" &&
                 this.$router.currentRoute.name != "Assinatura";
        },
        isDark () {
          return (this.$vuetify.theme.dark)
            ? 'dark'
            : 'light'
        }
      },
    
    methods:{
    }
}

export default mixinGeral