<template>
  <v-container class="scroller area-filtro-pesquisa-orcamento" :id="id">
    <v-row class="header-bar-sticky">
      <v-col cols="12">
        <span class="title titulo-2">{{ titulo }}</span>
      </v-col>

      <v-col cols="5">
        <v-text-field
          :id="'textfield-codigo-' + id"
          label="Código"
          v-model="filtrosPesquisa.codigo"
        ></v-text-field>
      </v-col>
      <v-col cols="7">
        <v-select
          :id="'select-lista-status-' + id"
          class="select-status"
          label="Status"
          :item-text="(o) => o.descricao"
          :item-value="(o) => o.valor"
          clearable
          multiple
          v-model="filtrosPesquisa.listaDeStatus"
          :items="listaDeStatusParaFiltro"
        >
          <template v-slot:selection="{ item, index }">
            <span
              v-if="filtrosPesquisa.listaDeStatus.length === 1 && index === 0"
              >{{ item.descricao }}</span
            >
            <span
              v-if="filtrosPesquisa.listaDeStatus.length > 1 && index === 0"
              class="grey--text caption"
            >
              ({{ filtrosPesquisa.listaDeStatus.length }} selecionados)
            </span>
          </template>
        </v-select>
      </v-col>
      
      <!-- <v-col cols="12">
        <pesquisa-pessoa
          :id="'pesquisa-pessoa-cliente-'+id"
          @onChange="atribuirFiltroSolicitante"
          label="Solicitante"
          placeholder
          atributoExibicao="codigoNome"
          :habilitarPesquisaAvancada="false"
          :categoriasDePessoa="['CLIENTE']"
        ></pesquisa-pessoa>
      </v-col> -->

      <v-col cols="12">
        <v-select
          :id="'select-periodo-emissao-' + id"
          label="Período de cadastro"
          v-model="periodoDeCadastroSelecionado"
          :items="periodoDeEmissaoLista"
          item-text="descricao"
          item-value="valor"
          return-object
          @change="periodoEmissaoAlterado"
        ></v-select>
      </v-col>

      <v-col cols="12" v-show="exibirDatasEmissaoPersonalizadas">
        <date-picker
          :id="'datepicker-data-cadastro-inicial-' + id"
          label="Data de cadastro inicial"
          hint
          v-model="filtrosPesquisa.dataInicialCadastro"
          data-minima="2000-01-01"
        ></date-picker>
      </v-col>

      <v-col cols="12" v-show="exibirDatasEmissaoPersonalizadas">
        <date-picker
          :id="'datepicker-data-cadastro-final-' + id"
          label="Data de cadastro final"
          :horarioPadrao="horarioFinalPadrao"
          :mensagemDeErro="validardataFinalCadastro()"
          hint
          v-model="filtrosPesquisa.dataFinalCadastro"
          :data-minima="filtrosPesquisa.dataInicialCadastro"
        ></date-picker>
      </v-col>

      <v-col cols="12">
        <v-row align-center justify-space-between wrap>
          <v-row
            :id="'link-avancado-' + id"
            @click="exibirFiltrosAvancados = !exibirFiltrosAvancados"
            justify-space-between
            align-center
            class="cursor-pointer"
          >
            <v-col xs1>
              <v-icon >
                {{
                  exibirFiltrosAvancados
                    ? "keyboard_arrow_down"
                    : "keyboard_arrow_right"
                }}
              </v-icon>
            </v-col>
            <v-col xs4>
              <strong><span >Avançado</span></strong> 
            </v-col>
            <v-col xs6> </v-col>
          </v-row>

          <v-col cols="12" v-show="exibirFiltrosAvancados">
            <v-divider />

                  <v-col cols="12">
        <v-select
          :id="'select-lista-categorias-' + id"
          class="select-status"
          label="Categorias"
          :item-text="(o) => o.descricao"
          :item-value="(o) => o.valor"
          clearable
          multiple
          v-model="filtrosPesquisa.listaDeCategorias"
          :items="listaDeCategoriasParaFiltro"
        >
          <template v-slot:selection="{ item, index }">
            <span
              v-if="filtrosPesquisa.listaDeCategorias.length === 1 && index === 0"
              >{{ item.descricao }}</span
            >
            <span
              v-if="filtrosPesquisa.listaDeCategorias.length > 1 && index === 0"
              class="grey--text caption"
            >
              ({{ filtrosPesquisa.listaDeCategorias.length }} selecionados)
            </span>
          </template>
        </v-select>
      </v-col>
      
            <v-col xs12>
              <date-picker
                :id="'datepicker-referencia-inicial-' + id"
                label="Data resolução inicial"
                hint
                v-model="filtrosPesquisa.dataInicialResolucao"
                data-minima="2000-01-01"
              ></date-picker>
            </v-col>

            <v-col xs12>
              <date-picker
                :id="'datepicker-referencia-final-' + id"
                label="Data resolução final"
                :horarioPadrao="horarioFinalPadrao"
                hint
                :mensagemDeErro="validarDataReferenciaFinal()"
                v-model="filtrosPesquisa.dataFinalResolucao"
                :data-minima="filtrosPesquisa.dataInicialResolucao"
              ></date-picker>
            </v-col>
          </v-col>
        </v-row>

        <v-row wrap class="footer-bar-sticky text-center">
          <v-flex my-3 xs12>
            <v-divider />
          </v-flex>
          <v-flex>
            <v-btn
              class="btn-tertiary"
              text
              @click="onFiltrar"
              :id="'btn-filtrar-' + id"
              >Filtrar</v-btn
            >
          </v-flex>
          <v-flex>
            <v-btn
             
              class="btn-secundary"
              text
              @click="onLimpar"
              :id="'btn-limpar-' + id"
              >Limpar</v-btn
            >
          </v-flex>
        </v-row>
      </v-col>
    </v-row>

    <!-- </v-row> -->
  </v-container>
</template>

<script>
// import PesquisaPessoa from "@/components/comum/PesquisaPessoa.vue";
import {
  TIPOS_DE_CONTRATO_LISTA,
  PERIODO_EMISSAO,
  PERIODO_EMISSAO_LISTA,
} from "@/constants/configuracoes-constants";

import DatePicker from "@/components/comum/Datepicker";
import dataUtils from "@/utils/data.js";
import FiltrosPesquisaAtendimentoModel from "@/model/filtros-pesquisa-atendimento-model";
// import FiltrosPesquisaAtendimentoModel from "@/models/faturamento/orcamento-locacao/filtros-pesquisa-orcamento-model";
// import { OPCOES_STORE_CONTROLE_LOCACAO } from "@/store/modules/controle-locacao";
// import PessoaModel from '../../../../../models/geral/pessoa/pessoa-model';

export default {
  components: {
    DatePicker,
    // PesquisaPessoa
  },

  model: {
    prop: "filtrosPesquisa",
    event: "onFiltrar",
  },

  props: {
    id: {
      type: String,
      required: false,
      default: () => "filtros-pesquisa-atendimento",
    },
    listaDeStatusParaFiltro: { type: Array, required: true },
    listaDeCategoriasParaFiltro: { type: Array, required: true },
    filtrosPesquisa: {
      type: FiltrosPesquisaAtendimentoModel,
      required: false,
      default: new FiltrosPesquisaAtendimentoModel(),
    },
    periodoDeEmissao: { type: Object, required: false, default: undefined },
    titulo: { type: String, required: false, default: "Filtrar atendimentos" },
  },

  watch: {
    periodoDeEmissao() {
      this.periodoDeCadastroSelecionado = this.periodoDeEmissao;
      this.preencherDatasEmissao();
    },
    "filtrosPesquisa.idCliente": function (cliente) {
      cliente || (this.cliente = undefined);
    },
    "filtrosPesquisa.dataInicialCadastro": function () {
      this.alterarPeriodoDeEmissaoAtivo();
    },
    "filtrosPesquisa.dataFinalCadastro": function () {
      this.alterarPeriodoDeEmissaoAtivo();
    },
  },

  data: () => ({
    codigo: undefined,
    listaStatus: [],
    listaDeCategorias: [],
    cliente: undefined,
    dataInicialCadastro: undefined,
    dataFinalCadastro: undefined,
    tipoContrato: undefined,
    dataInicialReferencia: undefined,
    dataFinalReferencia: undefined,
    dataInicioContrato: undefined,
    dataTerminoContrato: undefined,
    exibirFiltrosAvancados: false,
    exibirDatasEmissaoPersonalizadas: false,
    tiposDeContratoParaFiltro: TIPOS_DE_CONTRATO_LISTA,
    periodoDeEmissaoLista: PERIODO_EMISSAO_LISTA,
    periodoDeEmissaoOpcoes: PERIODO_EMISSAO,
    periodoDeCadastroSelecionado: PERIODO_EMISSAO.ESSE_MES,
    timeZoneEmMilissegundos: new Date().getTimezoneOffset() * 60000,
    horarioFinalPadrao: "23:59:59",
  }),

  updated() {
    // if (this.filtrosPesquisa.metadados.cliente) {
    //   this.cliente = this.filtrosPesquisa.metadados.cliente;
    // }

    // if (this.filtrosPesquisa.metadados.periodoEmissao) {
    //   this.periodoDeCadastroSelecionado = this.filtrosPesquisa.metadados.periodoEmissao;
    //   this.preencherDatasEmissao();
    // }
    // if (this.periodoDeEmissao) {
    //     this.periodoDeCadastroSelecionado = this.periodoDeEmissao;
    //     this.preencherDatasEmissao();
    // }   
  },

  methods: {
    limparPeriodoCadastro() {
      this.periodoDeCadastroSelecionado = undefined;
      this.filtrosPesquisa.dataInicialCadastro = undefined;
      this.filtrosPesquisa.dataFinalCadastro = undefined;
      // this.filtrosPesquisa.metadados.periodoEmissao = undefined;
    },

    alterarPeriodoDeEmissaoAtivo() {
      (!this.filtrosPesquisa.dataInicialCadastro ||
        !this.filtrosPesquisa.dataFinalCadastro) &&
        (this.periodoDeCadastroSelecionado = undefined);
    },

    atribuirFiltroSolicitante(solicitante) {
      if (solicitante) {
        this.filtrosPesquisa.idSolicitante = solicitante.id;
        // this.filtrosPesquisa.metadados.cliente =  new PessoaModel(cliente);
      } else {
        this.filtrosPesquisa.idSolicitante = undefined;
        // this.filtrosPesquisa.metadados.cliente = undefined;
      }
    },

    periodoEmissaoAlterado($event) {
      // if ($event) {
      //   this.filtrosPesquisa.metadados.periodoEmissao = $event;
      // }

      this.exibirDatasEmissaoPersonalizadas =
        ($event &&
          $event.valor == this.periodoDeEmissaoOpcoes.SELECIONAR_DATA.valor) ||
        false;
      this.filtrosPesquisa.dataInicialCadastro = undefined;
      this.filtrosPesquisa.dataFinalCadastro = undefined;
    },

    preencherDatasEmissao() {
      if (!this.periodoDeCadastroSelecionado) return;

      var dataInicial = new Date();
      var dataFinal = new Date();

      switch (this.periodoDeCadastroSelecionado.valor) {
        case PERIODO_EMISSAO.ULTIMOS_3_DIAS.valor: {
          dataInicial.setDate(dataInicial.getDate() - 3);
          break;
        }
        case PERIODO_EMISSAO.ESSA_SEMANA.valor: {
          dataInicial.setDate(dataInicial.getDate() - dataInicial.getDay());
          break;
        }
        case PERIODO_EMISSAO.SEMANA_PASSADA.valor: {
          dataFinal.setDate(dataFinal.getDate() - dataFinal.getDay() - 1);
          dataInicial = new Date(dataFinal);
          dataInicial.setDate(dataFinal.getDate() - 6);
          break;
        }
        case PERIODO_EMISSAO.ESSE_MES.valor: {
          dataInicial.setDate(1);
          break;
        }
        case PERIODO_EMISSAO.SELECIONAR_DATA.valor: {
          dataInicial = new Date(this.filtrosPesquisa.dataInicialCadastro);
          dataFinal = new Date(this.filtrosPesquisa.dataFinalCadastro);
          return; // TODO: Descobrir o motivo desse return (sem ele a data inicial cai 1 dia ao filtrar [wtf])
        }
      }

      this.filtrosPesquisa.dataInicialCadastro = dataUtils.formatarDataEHoraIso(
        dataInicial
      );
      this.filtrosPesquisa.dataFinalCadastro = dataUtils.formatarDataEHoraIso(
        dataFinal,
        this.horarioFinalPadrao
      );
    },

    validar() {
      let mensagemErro =
        this.validardataFinalCadastro() ||
        this.validarDataReferenciaFinal() ||
        this.validarDataContratoFinal();
      return !mensagemErro;
    },

    validardataFinalCadastro() {
      let dataFinalCadastroInvalida = !dataUtils.dataFinalMaiorOuIgualDataInicial(
        this.filtrosPesquisa.dataInicialCadastro,
        this.filtrosPesquisa.dataFinalCadastro
      );

      if (dataFinalCadastroInvalida) return "Deve ser maior que a data inicial";
      return;
    },

    validarDataReferenciaFinal() {
      let dataReferenciaFinalInvalida = !dataUtils.dataFinalMaiorOuIgualDataInicial(
        this.filtrosPesquisa.dataInicialReferencia,
        this.filtrosPesquisa.dataFinalReferencia
      );

      if (dataReferenciaFinalInvalida)
        return "Deve ser maior que a data inicial";
      return;
    },

    validarDataContratoFinal() {
      let dataContratoFinalInvalida = !dataUtils.dataFinalMaiorOuIgualDataInicial(
        this.filtrosPesquisa.dataInicioContrato,
        this.filtrosPesquisa.dataTerminoContrato
      );

      if (dataContratoFinalInvalida) return "Deve ser maior que a data inicial";
      return;
    },

    onFiltrar() {
      this.preencherDatasEmissao();
      if (!this.validar()) {
        return;
      }
      this.$emit("onFiltrar", this.filtrosPesquisa);
    },

    onLimpar() {
      this.$emit("onFiltrar", new FiltrosPesquisaAtendimentoModel());
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep
  .select-status.v-input.v-text-field.v-select
  .v-input__control
  .v-input__slot
  .v-select__slot
  .v-input__append-inner {
  padding: 0;
}

.area-filtro-pesquisa-orcamento {
  max-height: 500px;
  padding-bottom: 20px;
  overflow-x: hidden;
  overflow-y: overlay;
}

.cursor-pointer {
  cursor: pointer;
}

.container {
    padding-right: 12px;
    padding-left: 12px;
    display: flex;
    justify-content: space-between;
}
/* .btn-primary{

  border: 1px solid #00A82D;
  color: #00A82D;
} */

.btn-secundary{
  /* background: #eee; */
  color: #00A82D;
  font-size: 13px;
}
</style>