import api from './api'

function obterTodos(){
    return  new Promise((resolve, reject) =>{
        return api.get('/visitas')
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
}

function obterPorId(id){
    return  new Promise((resolve, reject) =>{
        return api.get('/visitas/' + id)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
}

function cadastrar(visita){
    visita = visita || {};
    return  new Promise((resolve, reject) =>{
      return api.post('/visitas', visita)
      .then(response => resolve(response))
      .catch(error => reject(error))
    });
}

function atualizar(visita){
    visita = visita || {};
    return  new Promise((resolve, reject) =>{
        return api.put(`/visitas/${visita.id}`, visita)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
}

function deletar(id){
    return  new Promise((resolve, reject) =>{
        return api.delete('/visitas/' + id)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
}

export default {
    obterTodos,
    obterPorId,
    cadastrar,
    atualizar,
    deletar,
}