<template>
  <v-container class="mt-6">
    <div style="width: 100%">
      <v-snackbar
        v-model="snackbar"
        :vertical="false"
        :timeout="timeout"
        top="top"
        :color="tipo"
        elevation="5"
        multi-line
        right
        shaped
      >
        {{ mensagem }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false">OK</v-btn>
        </template>
      </v-snackbar>

      <div v-if="loading" class="loading text-center">
        <v-progress-circular
          :size="70"
          :width="5"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <h1 class="loading-titulo">Carregando</h1>
      </div>

      <div v-if="!loading">
        <v-layout>
          <v-flex>
            <v-layout wrap>
              <v-col class="pl-0 mt-2 ml-0 mb-0" cols="12" xs="12" sm="12">
                <h1 class="titulo mb-0">Controle de atendimentos</h1>
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12" class="pl-0 ml-0" sm="6">
                <v-btn
                  class="btn-primario ml-0"
                  color="primary"
                  elevation="0"
                  @click="adicionarAtentimento"
                >
                  <v-icon class="mr-2 icone">mdi-plus</v-icon>
                  Adicionar</v-btn
                >
              </v-col>
              <v-col class="pl-0 ml-0 pr-0 mr-0 alinhamento" cols="12" sm="6">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      @click="exibirFiltros = !exibirFiltros"
                      icon
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <span>{{
                    exibirFiltros ? "Esconder filtros" : "Mostrar filtros"
                  }}</span>
                </v-tooltip>
                <!-- Adicionar filtro assim que possivel -->
                <!-- <v-btn
                class="btn-tertiary width-icon mr-0 ml-4 my-2"
                text
                @click="exibirFiltros = !exibirFiltros"
              >
                <v-icon small class="size-icon-filter" v-show="!exibirFiltros"
                  >arrow_back_ios</v-icon
                >
                Filtros
                <v-icon
                  small
                  class="size-icon-filter ml-2"
                  v-show="exibirFiltros"
                  >arrow_forward_ios</v-icon
                >
              </v-btn> -->
              </v-col>
              <v-card width="100%">
                <v-card-title class="pt-0">
                  <v-col cols="12" xs="12" sm="1">
                    <v-tooltip color="#343434" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <download-excel
                          class="btn btn-default"
                          :data="atendimentosPlanilha"
                          worksheet="Atendimentos"
                          name="atendimentos.xls"
                        >
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            text
                            class="btn-tertiary"
                          >
                            <v-icon small class="mr-1">
                              mdi-microsoft-excel
                            </v-icon>
                            Exportar
                          </v-btn>
                        </download-excel>
                      </template>
                      <span>Exportar atendimentos para excel</span>
                    </v-tooltip>
                  </v-col>
                  <v-spacer></v-spacer>

                  <v-col cols="12" xs="12" sm="2">
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Pesquisar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-card-title>
                <v-data-table
                  :headers="colunas"
                  :items="atendimentos"
                  :search="search"
                  show-expand
                  single-expand
                  sort-by="id"
                  sort-desc
                  :items-per-page="5"
                >
                  <template v-slot:item.status.descricao="{ item }">
                    <v-chip
                      class="geral"
                      :color="obterCorPorStatus(item.status)"
                      text-color="white"
                    >
                      {{ item.status.descricao }}
                    </v-chip>
                  </template>
                  <template v-slot:item.criadoEm="{ item }">
                    {{ item.criadoEm | data }}
                  </template>
                  <template v-slot:item.dataDeResolucao="{ item }">
                    {{ item.dataDeResolucao | data }}
                  </template>
                  <template v-slot:item.acoes="{ item }">
                    <v-menu
                      offset-y
                      offset-overflow
                      left
                      slide-y
                      @click.stop="() => {}"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn
                          class="btn-outline"
                          elevation="0"
                          @click="visualizarPareceres(item)"
                          >Visualizar</v-btn
                        >
                        <v-btn class="btn-opcoes" text v-on="on">
                          <span class="align-text-icon">Opções</span>
                          <v-icon class="seta-para-cima">arrow_drop_up</v-icon>
                          <v-icon class="seta-para-baixo"
                            >arrow_drop_down</v-icon
                          >
                        </v-btn>
                      </template>

                      <v-list class="lista-do-menu">
                        <v-list-item
                          v-for="opcao in opcoes"
                          :key="opcao.id"
                          @click="opcao.metodo(item)"
                        >
                          <v-list-item-title>{{
                            opcao.titulo
                          }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>

                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length" class="bloco-expansivel-td">
                      <div class="detalhes-atendimento">
                        <div class="resumo">
                          <strong class="primary--text">Resumo: </strong
                          >{{ item.resumo }}
                        </div>

                        <div class="primary--text">
                          <strong>Solicitação:</strong>
                          <v-textarea
                            readonly
                            auto-grow
                            solo
                            rows="3"
                            v-model="item.solicitacao"
                            label=""
                          ></v-textarea>
                        </div>
                      </div>
                    </td>
                  </template>
                </v-data-table>
              </v-card>
            </v-layout>
          </v-flex>

          <v-flex v-show="exibirFiltros" xs12 sm3 grow>
            <v-layout fill-height>
              <v-flex shrink>
                <v-divider vertical class="mx-3"></v-divider>
              </v-flex>
              <v-flex xm12>
                <div v-if="true">
                  <filtros-pesquisa-atendimento
                    xm12
                    ref="filtros"
                    v-model="filtroPesquisaAtendimento"
                    @onFiltrar="filtrarAtendimentos"
                    :periodoDeEmissao="periodoDeEmissao"
                    :listaDeStatusParaFiltro="listaDeStatusConstantes"
                    :listaDeCategoriasParaFiltro="listaDeCategorias"
                  ></filtros-pesquisa-atendimento>
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>

        <v-row>
          <!-- Modal cadastro -->
          <v-dialog v-model="dialog" persistent max-width="1280px">
            <v-card width="100%">
              <v-card-title
                :class="
                  !!(isDark === 'dark') ? 'titulo-modal-dark' : 'titulo-modal'
                "
              >
                <span class="headline">
                  <span class="headline">
                    {{ !modoEdicao ? "Adicionar " : "Editar " }} atendimento
                  </span>
                  <v-chip
                    class="ma-1 mt-0"
                    :color="obterCorPorStatus(atendimento.status)"
                    text-color="white"
                    active
                  >
                    {{ atendimento.status.descricao }}
                  </v-chip>
                </span>
              </v-card-title>
              <v-card-text>
                <v-row class="pa-2">
                  <v-col cols="12">
                    <v-card elevation="0">
                      <v-tabs>
                        <v-tab>
                          <v-icon left> mdi-ballot </v-icon>
                          Principal
                        </v-tab>
                        <v-tab>
                          <v-icon left> mdi-lock-pattern </v-icon>
                          Detalhes
                        </v-tab>
                        <v-tab>
                          <v-icon left> mdi-account-details </v-icon>
                          Outros
                        </v-tab>

                        <!-- Tab Principal -->
                        <v-tab-item>
                          <v-card flat>
                            <v-card-text>
                              <div class="tamanho-tab-cadastro">
                                <v-row>
                                  <v-col cols="12" sm="6" md="5">
                                    <v-text-field
                                      label="Resumo"
                                      v-model="atendimento.resumo"
                                      outlined
                                      dense
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="3" md="4">
                                    <pesquisa-pessoa
                                      label="Solicitante"
                                      v-model="atendimento.solicitante"
                                      placeholder
                                      atributoExibicao="codigoNome"
                                      :habilitarPesquisaAvancada="false"
                                      :categoriasDePessoa="['CLIENTE']"
                                   

                                    ></pesquisa-pessoa>
                                  </v-col>
                                  <v-col cols="12" sm="3" md="3">
                                    <v-autocomplete
                                      :items="listaDeCategorias"
                                      label="Categoria"
                                      item-text="descricao"
                                      v-model="atendimento.categoria"
                                      return-object
                                      clearable
                                      outlined
                                      dense
                                    ></v-autocomplete>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12" sm="12" md="12">
                                    <v-textarea
                                      auto-grow
                                      rows="6"
                                      v-model="atendimento.solicitacao"
                                      label="Solicitação"
                                      outlined
                                      dense
                                    ></v-textarea>
                                  </v-col>
                                </v-row>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-tab-item>

                        <!-- Tab Detalhes -->
                        <v-tab-item>
                          <v-card flat>
                            <v-card-text>
                              <div class="tamanho-tab-cadastro">
                                <v-row>
                                  <v-col cols="12" sm="3" md="4">
                                    <date-picker
                                      label="Data de resolução"
                                      v-model="atendimento.dataDeResolucao"
                                      data-minima="2000-01-01"
                                    >
                                    </date-picker>
                                  </v-col>

                                  <v-col cols="12" sm="3" md="3">
                                    <v-text-field
                                      v-model="atendimento.protocolo"
                                      label="Protocolo"
                                      class="tipo-numero"
                                      outlined
                                      dense
                                    >
                                    </v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="5">
                                    <v-autocomplete
                                      :items="listaDeResponsaveis"
                                      label="Responsável"
                                      item-text="nome"
                                      v-model="atendimento.responsavel"
                                      return-object
                                      clearable
                                      outlined
                                      dense
                                    ></v-autocomplete>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="12">
                                    <v-textarea
                                      auto-grow
                                      outlined
                                      dense
                                      rows="6"
                                      v-model="atendimento.observacao"
                                      label="Observação"
                                    ></v-textarea>
                                  </v-col>
                                </v-row>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-tab-item>

                        <!-- Tab Outros -->
                        <v-tab-item>
                          <v-card flat>
                            <v-card-text>
                              <div class="tamanho-tab-cadastro">
                                <v-row>
                                  <v-col
                                    class="text-center primary--text"
                                    cols="12"
                                    sm="12"
                                  >
                                    <v-file-input
                                      @change="selecionarAnexos"
                                      @blur="selecionarAnexos"
                                      @click:clear="
                                        atendimento.anexos = undefined
                                      "
                                      chips
                                      deletable-chips
                                      small-chips
                                      multiple
                                      show-size
                                      v-model="file"
                                      accept="*"
                                      label="Adicionar anexos"
                                      hint="É possível selencionar vários anexos de uma única vez."
                                      persistent-hint
                                      outlined
                                      dense
                                    ></v-file-input>
                                  </v-col>

                                  <v-col
                                    v-if="anexosVinculados.length"
                                    cols="12"
                                  >
                                    <v-card class="mx-auto" max-width="100%">
                                      <v-list subheader>
                                        <v-subheader
                                          >Anexos adicionados</v-subheader
                                        >

                                        <v-list-item
                                          v-for="anexo in anexosVinculados"
                                          :key="anexo.id"
                                        >
                                          <v-list-item-title
                                            v-text="anexo.nomeArquivo"
                                          ></v-list-item-title>

                                          <v-list-item-icon>
                                            <v-tooltip bottom>
                                              <template
                                                v-slot:activator="{ on, attrs }"
                                              >
                                                <v-btn
                                                  v-bind="attrs"
                                                  v-on="on"
                                                  icon
                                                  @click="
                                                    exibirAnexoTela(anexo)
                                                  "
                                                >
                                                  <svg width="24" height="24">
                                                    <path
                                                      fill-rule="nonzero"
                                                      d="M12 4.5C7 4.5 2.7 7.6 1 12a11.8 11.8 0 0 0 22 0c-1.7-4.4-6-7.5-11-7.5zM12 17a5 5 0 1 1 0-10 5 5 0 0 1 0 10zm0-8a3 3 0 1 0 0 6 3 3 0 0 0 0-6z"
                                                    />
                                                  </svg>
                                                </v-btn>
                                              </template>
                                              <span>Visualizar</span>
                                            </v-tooltip>
                                          </v-list-item-icon>
                                          <v-list-item-icon>
                                            <v-tooltip bottom>
                                              <template
                                                v-slot:activator="{ on, attrs }"
                                              >
                                                <v-btn
                                                  v-bind="attrs"
                                                  v-on="on"
                                                  icon
                                                  @click="removerAnexo(anexo)"
                                                >
                                                  <v-icon
                                                    >mdi-delete-forever</v-icon
                                                  >
                                                </v-btn>
                                              </template>
                                              <span>Remover</span>
                                            </v-tooltip>
                                          </v-list-item-icon>
                                        </v-list-item>
                                        <v-divider inset></v-divider>
                                      </v-list>
                                    </v-card>
                                  </v-col>
                                </v-row>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-tab-item>
                      </v-tabs>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  class="btn-primario"
                  @click="salvar"
                  elevation="0"
                  >Salvar
                </v-btn>

                <v-btn
                  class="btn-terciario ml-0"
                  elevation="0"
                  :disabled="desabilitarBotoes"
                  @click="cancelar"
                  >Cancelar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>

      <cadastro-de-parecer
        :visivel="abrirModalAdicionarParecer"
        @fechar-modal="abrirModalAdicionarParecer = false"
        v-model="atendimento"
        @onChange="atualizarAtendimentoComPareceres"
      >
      </cadastro-de-parecer>

      <visualizacao-de-pareceres
        :visivel="abrirModalVisualizarParecer"
        @fechar-modal="abrirModalVisualizarParecer = false"
        v-model="atendimento"
        :mostrarCarregamento="this.loading"
      >
      </visualizacao-de-pareceres>
    </div>
  </v-container>
</template>
<script>
//APIs
import apiAtendimentos from "../api/atentimento-api";
import apiCategorias from "../api/categoria-api";
import apiStatus from "../api/status-api";
import apiPessoas from "../api/pessoa-api";
import apiAnexos from "../api/anexos-api";

//Models
import AtendimentoModel from "../model/atendimento-model";
import CategoriaModel from "../model/categoria-model";
import StatusModel from "../model/status-model";
import PessoaModel from "../model/pessoa-model";
import FiltrosPesquisaAtendimentoModel from "@/model/filtros-pesquisa-atendimento-model";

import mixinMensagen from "../mixin/mixin-mensagem";
import mixinGeral from "../mixin/mixin-geral";
import moment from "moment";
import DatePicker from "@/components/comum/Datepicker";
import CadastroDeParecer from "@/components/CadastroDeParecer";
import FiltrosPesquisaAtendimento from "@/components/comum/FiltrosPesquisaAtendimento";
import VisualizacaoDePareceres from "@/components/VisualizacaoDePareceres";
import JsonExcel from "vue-json-excel";
import PesquisaPessoa from "@/components/comum/PesquisaPessoa.vue";
import dataUtils from "@/utils/data.js";

import mixpanel from 'mixpanel-browser'
import {
  URLS,
  PERIODO_EMISSAO,
  STATUS_ATENDIMENTO_LISTA,
} from "@/constants/configuracoes-constants";

export default {
  name: "controle-usuarios",
  mixins: [mixinMensagen, mixinGeral],
  components: {
    DatePicker,
    CadastroDeParecer,
    VisualizacaoDePareceres,
    FiltrosPesquisaAtendimento,
    downloadExcel: JsonExcel,
    PesquisaPessoa,
  },
  filters: {
    data(valor) {
      if (!valor) return "";
      return moment(valor).format("DD/MM/YYYY");
    },
    celular(valor) {
      if (!valor) return "";

      let r = valor.replace(/\D/g, "");
      r = r.replace(/^0/, "");
      if (r.length > 10) {
        r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
      } else if (r.length > 5) {
        r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
      } else if (r.length > 2) {
        r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
      } else {
        r = r.replace(/^(\d*)/, "($1");
      }
      return r;
    },
  },

  data() {
    return {
      file: undefined,
      abrirModalAlterarStatus: false,
      abrirModalAdicionarParecer: false,
      abrirModalVisualizarParecer: false,
      dialog: false,
      loading: false,
      modoEdicao: false,
      desabilitarBotoes: false,
      exibirFiltros: false,
      search: "",
      colunas: [
        { text: "Código", value: "id", align: "center" },
        { text: "Solicitante", value: "solicitante.nome", align: "left" },
        { text: "Status", value: "status.descricao", align: "center" },
        { text: "Categoria", value: "categoria.descricao", align: "left" },
        { text: "Data de cadastro", value: "criadoEm", align: "center" },
        {
          text: "Data de Resolução",
          value: "dataDeResolucao",
          align: "center",
        },
        { text: "", value: "acoes", sortable: false, align: "right" },
      ],

      atendimento: new AtendimentoModel(),
      atendimentos: [],
      opcoes: [],
      listaDeCategorias: [],
      listaDePessoas: [],
      listaDeResponsaveis: [],
      listaDeStatus: [],
      anexos: [],
      anexosVinculados: [],
      filtroPesquisaAtendimento: new FiltrosPesquisaAtendimentoModel(),
      periodoDeEmissao: undefined,
      listaDeStatusConstantes: STATUS_ATENDIMENTO_LISTA,
      atendimentosPlanilha: [],
    };
  },
  mounted() {
    mixpanel.track("page_view");
  },
  watch: {
    // "atendimento.status": (status) => {
    //   console.log(status);
    //   this.atualizarAtendimentoNaLista(this.atendimento);
    // }
  },
  created() {
    this.inicializar();
    this.periodoDeEmissao = PERIODO_EMISSAO.ESSE_MES;
  },
  methods: {
    async inicializar() {
      this.loading = true;
      // Não precisa ser sincrono.
      apiCategorias.obterTodos().then((resposta) => {
        this.listaDeCategorias = resposta.data.map(
          (c) => new CategoriaModel(c)
        );
      });

      //TODO as pessoas devem ser obtidas dinamicamente....
      // Fazer isso na proxima versão.
      apiStatus.obterTodos().then((resposta) => {
        this.listaDeStatus = resposta.data.map((c) => new StatusModel(c));
      });

      // Não precisa ser sincrono.
      apiPessoas.obterTodos().then((resposta) => {
        this.listaDePessoas = resposta.data.map((c) => new PessoaModel(c));
        this.listaDeResponsaveis = this.listaDePessoas.filter(
          (p) => p.membroDaEquipe
        );
      });

      await apiAtendimentos
        .obterPorFiltros(
          new FiltrosPesquisaAtendimentoModel({
            dataInicialCadastro: dataUtils.formatarDataEHoraIso(
              new Date(new Date().setDate(1))
            ),
            dataFinalCadastro: dataUtils.formatarDataEHoraIso(
              new Date(),
              "23:59:59"
            ),
          })
        )
        .then((response) => {
          this.atendimentos = response.data.map((a) => new AtendimentoModel(a));
          this.preencherTabelaParaExportacao();
          this.limparCampos();
          this.loading = false;
        })
        .catch((erro) => {
          this.dispararMensagem(erro.response.data.mensagem, "error");
          this.loading = false;
        });
      this.opcoes = this.montarOpcoes();
      this.loading = false;
    },

    preencherTabelaParaExportacao() {
      this.atendimentosPlanilha = this.atendimentos.map((a) => {
        return {
          Codigo: a.id,
          Solicitante: a.solicitante.nome,
          Resumo: a.resumo,
          Status: a.status.descricao,
          "Data De Cadastro":
            a.criadoEm && a.criadoEm && moment(a.criadoEm).format("DD/MM/YYYY"),
          "Data de Resolucao":
            a.dataDeResolucao &&
            a.criadoEm &&
            moment(a.dataDeResolucao).format("DD/MM/YYYY"),
          Solicitação: a.solicitacao,
        };
      });
    },
    montarOpcoes() {
      return [
        { id: 1, titulo: "Editar", metodo: this.editarAtendimento },
        { id: 2, titulo: "Duplicar", metodo: this.duplicarAtendimento },
        { id: 3, titulo: "Adicionar parecer", metodo: this.efetuarParecer },
        // {
        //   id: 4,
        //   titulo: "Visualizar pareceres",
        //   metodo: this.visualizarPareceres,
        // },
        // { id: 5, titulo: "Alterar Status", metodo: this.atualizarStatus },
      ];
    },

    efetuarParecer(atendimento) {
      this.atendimento = new AtendimentoModel(atendimento);
      this.abrirModalAdicionarParecer = true;
    },

    visualizarPareceres(atendimento) {
      mixpanel.track("click",{
        nome_botao: 'btn_visualizar_pareceres',
        atendimento_id: atendimento.id
      });
      this.obterAtendimentoPorId(atendimento.id);
      this.abrirModalVisualizarParecer = true;
    },

    atualizarAtendimentoComPareceres(atendimento) {
      this.atualizarAtendimentoNaLista(new AtendimentoModel(atendimento));
    },
    adicionarAtentimento() {
      mixpanel.track("click",{
        nome_botao: 'btn_adicionar_atendimento'
      });
      this.atendimento = new AtendimentoModel();
      this.dialog = true;
      this.anexosVinculados = [];
    },

    salvar() {
      !this.modoEdicao
        ? this.cadastrarAtendimento()
        : this.atualizarAtendimento();
    },

    dataURLtoFile(dataurl, filename) {
      if (!dataurl) return undefined;

      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },

    async editarAtendimento(atendimento) {
      this.modoEdicao = true;
      this.anexosVinculados = [];

      this.atendimento.anexos = [];
      this.anexos = [];

      this.obterAtendimentoPorId(atendimento.id);
      this.obterAnexosDoAtendimento(atendimento.id);
      this.dialog = true;
    },

    obterAtendimentoPorId(idAtendimento) {
      this.loading = true;

      apiAtendimentos
        .obterPorId(idAtendimento)
        .then((resposta) => {
          this.atendimento = new AtendimentoModel(resposta.data.shift());
        })
        .catch((error) => {
          this.dispararMensagem(error.response.data.mensagem, "error");
        })
        .finally((this.loading = false));
    },

    cancelar() {
      mixpanel.track("click",{
        nome_botao: 'btn_cancelar',
      });
      this.dialog = false;
      this.atendimento = new AtendimentoModel();
      this.file = undefined;
      this.modoEdicao = false;
    },

    limparCampos() {
      this.atendimento = new AtendimentoModel();
      this.anexos = [];
      this.anexosVinculados = [];
    },

    duplicarAtendimento(atendimento) {
      this.modoEdicao = false;
      this.atendimento = new AtendimentoModel(atendimento);
      this.obterAtendimentoPorId(this.atendimento.id);
      this.dialog = true;
    },
    cadastrarAtendimento() {
      this.modoEdicao = false;
      this.desabilitarBotoes = true;

      apiAtendimentos
        .cadastrar(this.atendimento)
        .then((response) => {

          this.atendimento = new AtendimentoModel(response.data);
          this.atendimentos.push(this.atendimento);

          if (this.anexos.length) {
            this.adicionarAnexos(this.atendimento);
          }

          this.dispararMensagem(`Cadastrado efetuado com sucesso!`, "success");

          this.limparCampos();
          this.dialog = false;
          this.desabilitarBotoes = false;
          mixpanel.track("cadastrou_atendimento",{
            atendimento_id: response.data.id
          });

        })
        .catch((erro) => {
          this.dispararMensagem(erro.response.data.mensagem, "error");
          this.desabilitarBotoes = false;
        });
    },
    atualizarAtendimento() {
      this.modoEdicao = true;
      this.desabilitarBotoes = true;
      apiAtendimentos
        .atualizar(this.atendimento)
        .then((response) => {
          if (this.anexos.length) {
            this.adicionarAnexos(this.atendimento);
          }
          mixpanel.track("atualizou_atendimento",{
            atendimento_id: this.atendimento.id
          });


          this.dispararMensagem(
            `O atendimento foi atualizado(a) com sucesso!`,
            "success"
          );

          this.atualizarAtendimentoNaLista(new AtendimentoModel(response.data));
          this.limparCampos();
          this.dialog = false;
          this.desabilitarBotoes = false;
        })
        .catch((erro) => {
          this.dispararMensagem(erro.response.data.mensagem, "error");
          this.desabilitarBotoes = false;
        });
    },

    deletarAtendimento(atendimento) {
      apiAtendimentos
        .deletar(atendimento)
        .then(() => {
          mixpanel.track("excluiu_atendimento",{
            atendimento_id: atendimento.id
          });

          this.dispararMensagem(`Atendimento deletado com sucesso!`, "success");
          this.atualizarAtendimentoNaLista(
            new AtendimentoModel(atendimento),
            true
          );
        })
        .catch((erro) =>
          this.dispararMensagem(erro.response.data.mensagem, "error")
        )
        .finally((this.dialog = false));
    },

    atualizarAtendimentoNaLista(atendimento, deletarDaLista) {
      if (!atendimento || !atendimento.id) return;

      var index = this.atendimentos
        .map((p) => parseInt(p.id))
        .indexOf(parseInt(atendimento.id));
      !deletarDaLista
        ? this.atendimentos.splice(index, 1, atendimento)
        : this.atendimentos.splice(index, 1);
    },

    obterCorPorStatus(status) {
      switch (parseInt(status.id)) {
        case 1:
          return "grey";
        case 2:
          return "blue";
        case 3:
          return "green";
        case 4:
          return "orange";
        case 5:
          return "red";
      }
    },

    // Parte de anexos
    selecionarAnexos(fileStream) {
      if (!Array.isArray(fileStream) || !fileStream.length) return;

      let self = this;

      fileStream.map((file) => {
        self.anexos.push({
          nome: file.name,
          tamanho: file.size,
          extensao: file.type,
          tipo: "ATENDIMENTO",
          file: file,
        });
      });
    },

    async adicionarAnexos(atendimento) {
      let formData = new FormData();

      formData.append("idOrigem", atendimento.id);
      formData.append("nomeOrigem", "ATENDIMENTO");

      for (let anexo of this.anexos) formData.append("arquivos[]", anexo.file);

      // Botar uma validação aqui assim que possível.
      await apiAnexos.cadastrar(formData);
      this.atendimento.anexos = undefined;
    },

    obterAnexosDoAtendimento(idAtendimento) {
      apiAnexos
        .obterPeloIdAtendimento(idAtendimento)
        .then((resposta) => {
          if (!Array.isArray(resposta.data)) {
            return;
          }

          resposta.data.map((a) => {
            let anexo = {
              id: a.id,
              nomeArquivo: a.caminho.split("/").reverse()[0],
              idAtendimento: a.idOrigem,
              caminho: a.caminho,
              criadoEm: a.criadoEm,
              atualizadoEm: a.atualizadoEm,
            };

            this.anexosVinculados.push(anexo);
          });
        })
        .catch((error) => {
          this.dispararMensagem(
            `Não foi possível obter os anexos - ` + error,
            "error"
          );
        });
    },

    exibirAnexoTela(arquivo) {
      if (!arquivo) return;
      if (!arquivo.caminho) return;

      let preview = null;
      let fileContainer = document.createElement("div");
      fileContainer.classList.add("file-container");

      let caminhoFormatado = `${URLS.BASE}/${arquivo.caminho.replace(
        "uploads",
        ""
      )}`;

      let ext = arquivo.caminho.toString().split(".");
      ext = ext.reverse()[0].toString().toLowerCase();

      switch (ext) {
        default:
        case "png":
        case "jpg":
        case "jpeg":
          preview = document.createElement("img");
          preview.src = caminhoFormatado;
          break;
        case "svg":
          preview = document.createElement("object");
          preview.data = caminhoFormatado;
          preview.type = "image/svg+xml";
          break;
        case "pdf":
          preview = document.createElement("embed");
          preview.src = caminhoFormatado;
          preview.style.cssText =
            "position:fixed; top:0; left:0; bottom:0; right:0; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden; z-index:999999;";
          preview.type = "application/pdf";
          break;
      }
      if (ext !== "pdf") preview.classList.add("zoom");

      let win = window.open("");
      win.document.write(`
              <style>
                body {
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  padding: 150px;
                  background: #c6c6c6;
                }
                .file-container {
                  display: flex;
                  object-fit: contain;
                  width: 100%;
                  height: 100%;
                }
                .zoom {
                  /* min-width: 400px;
                   min-height: 400px; */
                  margin: 0 auto;
                  transition: transform 4s;
                }
                .zoom:hover {
                  transform: scale(1.5);
                }
              </style>
              <body>
              </body>
            `);
      fileContainer.appendChild(preview);
      win.document.body.appendChild(fileContainer);
    },

    removerAnexo(anexo) {
      apiAnexos
        .remover(anexo)
        .then(() => {
          let indice = this.anexosVinculados.map((a) => a.id).indexOf(anexo.id);
          this.anexosVinculados.splice(indice, 1);
          this.dispararMensagem(
            `Anexo ${anexo.nomeArquivo} removido com sucesso!`,
            "success"
          );
        })
        .catch(() => {
          this.dispararMensagem(
            `Não foi possível remover o anexo ${anexo.nomeArquivo}`,
            "error"
          );
        });
    },

    filtrarAtendimentos(filtros) {
      if (!filtros.filtroEstaoaPreenchidosParaPesquisa()) return;
      apiAtendimentos
        .obterPorFiltros(filtros)
        .then((response) => {
          this.atendimentos = response.data.map((a) => new AtendimentoModel(a));
          this.preencherTabelaParaExportacao();

          if (response.data.length == 1) {
            this.atendimento = response.data.map(
              (a) => new AtendimentoModel(a)
            )[0];
          }

          this.limparCampos();
        })
        .catch((error) => this.dispararMensagem(`${error}`, "error"));
    },
  },
};
</script>
<style scoped>
.tamanho-tab-cadastro {
  min-height: 300px;
}
div.tipo-numero input {
  text-align: right;
}

/* div.v-card-text{
  padding-bottom: 0;
} */
div.v-card__text {
  padding-bottom: 0;
}

.alinhamento {
  text-align: end;
}
div.detalhes-atendimento {
  margin: 15px;
}

div.resumo {
  margin-bottom: 10px;
}
</style>
