<template>
  <v-container class="mt-6">
    <div v-if="loading" class="loading text-center">
      <h1 class="primary--text">Recuperando as informações...</h1>
      <v-progress-circular
        :size="120"
        :width="8"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>

    <v-row v-if="!loading" class="mb-5">
      <v-col class="my-5" cols="12" xs="12" sm="12">
        <h1 class="titulo mb-0">Painel de atendimentos</h1>
        <v-divider></v-divider>
      </v-col>

      <v-col cols="12" class="py-0">
        <v-row class="mt-1">
          <v-col cols="12" sm="4" >
            <div v-if="!loading" class="md-layout-item px-0">
              <stats-card
                :class="!!(isDark === 'dark') ? 'dark' : ''"
                data-background-color="grey"
              >
                <template slot="header">
                  <check-square-icon
                    size="3x"
                    class="custom-class"
                  ></check-square-icon>
                </template>

                <template slot="content">
                  <h3 class="category">Total de atendimentos</h3>
                  <h3 class="title category">
                    {{ metadadosAtendimento.totalDeAtendimentos }}
                  </h3>
                </template>
              </stats-card>
            </div>
          </v-col>

          <v-spacer></v-spacer>
          <v-col class="pb-0 pt-0" cols="12" sm="2">
            <v-autocomplete
              outlined
              small
              return-object
              item-text="descricao"
              v-model="periodoEscolhido"
              label="Escolher período"
              :items="periodosDashBoard"
              dense
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-col>

      <v-col class="pa-0" cols="12" sm="6">
        <div class="sem-dados" v-if="!metadadosAtendimento.totalDeAtendimentos">
          <span class="mensagem-sem-dados"
            >Você não tem atendimentos neste período</span
          >
        </div>
        <div
          class="espacamento-com-borda"
          :class="!!(isDark === 'dark') ? 'dark' : ''"
        >
          <Doughnut
            ref="chartPorStatus"
            :chart-data="dataPorStatus"
            :options="optionsPorStatus"
          ></Doughnut>
        </div>
      </v-col>

      <v-col class="pa-0" cols="12" sm="6">
        <div class="sem-dados" v-if="!metadadosAtendimento.totalDeAtendimentos">
          <span class="mensagem-sem-dados"
            >Você não tem atendimentos neste período</span
          >
        </div>
        <div
          class="espacamento-com-borda"
          :class="!!(isDark === 'dark') ? 'dark' : ''"
        >
          <BarChart
            ref="chartPorCategoria"
            :chart-data="dataPorCategoria"
            :options="optionsPorCategoria"
          ></BarChart>
        </div>
      </v-col>

      <v-col class="pa-0" cols="12" sm="6">
        <div class="sem-dados" v-if="!metadadosAtendimento.totalDeAtendimentos">
          <span class="mensagem-sem-dados"
            >Você não tem atendimentos neste período</span
          >
        </div>
        <div
          class="espacamento-com-borda"
          :class="!!(isDark === 'dark') ? 'dark' : ''"
        >
          <BarChart
            ref="chartPorSolicitante"
            :chart-data="dataPorSolicitante"
            :options="optionsPorSolicitante"
          ></BarChart>
        </div>
      </v-col>

      <v-col class="pa-0" cols="12" sm="6">
        <div class="sem-dados" v-if="!metadadosAtendimento.totalDeAtendimentos">
          <span class="mensagem-sem-dados"
            >Você não tem atendimentos neste período</span
          >
        </div>
        <div 
          class="espacamento-com-borda"
          :class="!!(isDark === 'dark') ? 'dark' : ''"
        >
          <Doughnut
            ref="chartPorUsuario"
            :chart-data="dataPorUsuario"
            :options="optionsPorUsuario"
          ></Doughnut>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import apiDashboard from "@/api/dashboard-api";
import { StatsCard } from "@/components";
import { PERIODO_DASHBOARD } from "@/constants/configuracoes-constants";
import Doughnut from "@/components/graficos/Doughnut.vue";
import BarChart from "@/components/graficos/BarChart.vue";
import mixinGeral from "@/mixin/mixin-geral";
import MetadadosAtendimento from "@/model/metadados-atendimento";
import mixpanel from "mixpanel-browser";

export default {
  name: "DashBoard",
  mixins: [mixinGeral],
  components: {
    StatsCard,
    Doughnut,
    BarChart,
  },
  data() {
    return {
      loading: true,
      metadadosAtendimento: new MetadadosAtendimento(),
      periodosDashBoard: PERIODO_DASHBOARD,
      periodoEscolhido: PERIODO_DASHBOARD[4],
      optionsPorStatus: {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          animateRotate: false,
        },
        legend: {
          display: true,
        },
        title: {
          display: true,
          text: "Atendimentos por status",
        },
        plugins: {
          datalabels: {
            textAlign: "center",
            font: {
              weight: "bold",
              size: 11,
            },
            formatter: (value, ctx) => {
              let sum = 0;
              let dataArr = ctx.chart.data.datasets[0].data;
              dataArr.map((data) => {
                sum += parseFloat(data);
              });
              let percentage = ((value * 100) / sum).toFixed(2) + "%";
              return percentage;
            },
            color: "white",
          },
        },
      },
      optionsPorCategoria: {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          animateRotate: true,
        },
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: "Atendimentos por categorias",
        },
        scales: {
          yAxes: [
            {
              display: true,
              ticks: {
                beginAtZero: true,
                steps: 5,
                stepValue: 1,
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            textAlign: "center",
            font: {
              weight: "bold",
              size: 12,
            },
            color: "white",
          },
        },
      },
      optionsPorSolicitante: {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          animateRotate: true,
        },
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: "Atendimentos por solicitante ",
        },
     
        scales: {
          yAxes: [
            {
              display: true,
              ticks: {
                beginAtZero: true,
                steps: 5,
                stepValue: 1,
              },
            },
          ],
        },
        plugins: {
          
          datalabels: {
            textAlign: "center",
            font: {
              weight: "bold",
              size: 12,
            },
            color: "white",
          },
        },
      },
      optionsPorUsuario: {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          animateRotate: true,
        },
        legend: {
          display: true,
        },
        title: {
          display: true,
          text: "Atendimentos por usuário",
        },
        plugins: {
          datalabels: {
            textAlign: "center",
            font: {
              weight: "bold",
              size: 11,
            },
            formatter: (value, ctx) => {
              let sum = 0;
              let dataArr = ctx.chart.data.datasets[0].data;
              dataArr.map((data) => {
                sum += parseFloat(data);
              });
              let percentage = ((value * 100) / sum).toFixed(2) + "%";
              return percentage;
            },
            color: "white",
          },
        },
      },
      dataPorStatus: {
        labels: [],
        datasets: [
          {
            label: "Atendimentos por status",
            borderColor: "transparent",
            backgroundColor: [
              "#90CAF9",
              "#FFCC80",
              "#EF9A9A",
              "#A5D6A7",
              "#B0BEC5",
            ],
            data: [0],
          },
        ],
      },
      dataPorCategoria: {
        labels: [],
        datasets: [
          {
            // label: "Categoria",
            borderColor: "transparent",
            backgroundColor: [
              "#EF9A91",
              "#F48FB1",
              "#CE93D1",
              "#B39DD1",
              "#9FA8D1",
              "#90CAF1",
              "#81D4F1",
              "#80DEE1",
              "#80CBC1",
              "#A5D6A1",
              "#C5E1A1",
              "#E6EE91",
              "#FFF591",
              "#FFE081",
              "#FFCC81",
              "#FFAB91",
              "#BCAAA1",
              "#EEEEE1",
              "#B0BEC1",
              "#EF9A91",
              "#F48FB1",
              "#CE93D1",
              "#B39DD1",
              "#9FA8D1",
              "#90CAF1",
              "#81D4F1",
              "#80DEE1",
              "#80CBC1",
              "#A5D6A1",
              "#C5E1A1",
              "#E6EE91",
              "#FFF591",
              "#FFE081",
              "#FFCC81",
              "#FFAB91",
              "#BCAAA1",
              "#EEEEE1",
              "#B0BEC1",
              "#1ABC9C",
              "#E74C3C",
              "#CFD8DC",
              "#EF9A91",
              "#F48FB1",
              "#CE93D1",
              "#B39DD1",
              "#9FA8D1",
              "#90CAF1",
              "#EF9A9A",
              "#90CAF9",
              "#A5D6A7",
              "#FFE0B2",
              "#EF9A91",
              "#F48FB1",
              "#CE93D1",
              "#B39DD1",
              "#9FA8D1",
              "#90CAF1",
              "#81D4F1",
              "#80DEE1",
              "#80CBC1",
              "#A5D6A1",
              "#C5E1A1",
              "#E6EE91",
              "#FFF591",
              "#FFE081",
              "#FFCC81",
              "#FFAB91",
              "#BCAAA1",
              "#EEEEE1",
              "#B0BEC1",
              "#EF9A91",
              "#F48FB1",
              "#CE93D1",
              "#B39DD1",
              "#9FA8D1",
              "#90CAF1",
              "#81D4F1",
              "#80DEE1",
              "#80CBC1",
              "#A5D6A1",
              "#C5E1A1",
              "#E6EE91",
              "#FFF591",
              "#FFE081",
              "#FFCC81",
              "#FFAB91",
              "#BCAAA1",
              "#EEEEE1",
              "#B0BEC1",
            ],
            data: [0],
          },
        ],
      },
      dataPorSolicitante: {
        labels: [],
        datasets: [
          {
            // label: "Atendimentos por solicitante",
            borderColor: "transparent",
            backgroundColor: [
              "#EF9A91",
              "#F48FB1",
              "#CE93D1",
              "#B39DD1",
              "#9FA8D1",
              "#90CAF1",
              "#81D4F1",
              "#80DEE1",
              "#80CBC1",
              "#A5D6A1",
              "#C5E1A1",
              "#E6EE91",
              "#FFF591",
              "#FFE081",
              "#FFCC81",
              "#FFAB91",
              "#BCAAA1",
              "#EEEEE1",
              "#B0BEC1",
            ],
            data: [0],
          },
        ],
      },
      dataPorUsuario: {
        labels: [],
        datasets: [
          {
            label: "Atendimentos por usuário",
            borderColor: "transparent",
            backgroundColor: [
              "#E57373",
              "#7986CB",
              "#CFD8DC",
              "#FFE082",
              "#BCAAA4",
              "#81D4FA",
              "#81C784",
              "#FFB74D",
              "#E57373",
              "#CFD8DC",
              "#EF9A91",
              "#F48FB1",
              "#CE93D1",
              "#B39DD1",
              "#9FA8D1",
              "#90CAF1",
              "#81D4F1",
              "#80DEE1",
              "#80CBC1",
              "#A5D6A1",
              "#C5E1A1",
              "#E6EE91",
              "#FFF591",
              "#FFE081",
              "#FFCC81",
              "#FFAB91",
              "#BCAAA1",
              "#EEEEE1",
              "#B0BEC1",
            ],
            data: [0],
          },
        ],
      },
      chartData: {
        labels: [
          "Pendentes",
          "Andamento",
          "Resolvidos",
          "Insatisfeitos",
          "Cancelados",
        ],
        datasets: [
          {
            label: "Atendimentos",

            backgroundColor: [
              "#546e7a",
              "#12B8CD",
              "#4caf50",
              "#ff8f00",
              "#ef5350",
            ],
            data: [0],
          },
        ],
      },
    };
  },
  computed: {
    temValorParaApresentar() {
      return !!(
        this.totalizadores.pendente ||
        this.totalizadores.andamento ||
        this.totalizadores.resolvido ||
        this.totalizadores.insatisfeito ||
        this.totalizadores.cancelado
      );
    },
  },
  watch: {
    periodoEscolhido() {
      mixpanel.track('click', {
        nome_botao: 'Selecionar período',
        periodo_escolhido: this.periodoEscolhido.descricao
      })
      this.obterMetadados();

    },
  },
  mounted() {
    this.obterMetadados();
    mixpanel.track("page_view");
  },
  methods: {
    updateChart() {
      this.$refs.chartPorStatus.update();
      this.$refs.chartPorCategoria.update();
      this.$refs.chartPorSolicitante.update();
      this.$refs.chartPorUsuario.update();
    },

    atualizarPorStatus() {
      this.dataPorStatus.datasets[0].data = [];
      this.dataPorStatus.labels = [];

      this.metadadosAtendimento.totalAtendimentosPorStatus.map((e) => {
        this.dataPorStatus.labels.push(e.descricao);
        this.dataPorStatus.datasets[0].data.push(
          parseFloat(e.atendimentos.length)
        );
      });
    },

    atualizarPorCategoria() {
      this.dataPorCategoria.datasets[0].data = [];
      this.dataPorCategoria.labels = [];

      this.metadadosAtendimento.totalAtendimentosPorCategoria.map((e) => {
        this.dataPorCategoria.labels.push(e.descricao);
        this.dataPorCategoria.datasets[0].data.push(
          parseFloat(e.atendimentos.length)
        );
      });
    },

    atualizarPorSolicitante() {
      this.dataPorSolicitante.datasets[0].data = [];
      this.dataPorSolicitante.labels = [];

      this.metadadosAtendimento.totalAtendimentosPorSolicitante.map((e) => {
        this.dataPorSolicitante.labels.push(e.nomeSolicitante);
        this.dataPorSolicitante.datasets[0].data.push(
          parseFloat(e.atendimentos.length)
        );
      });
    },

    atualizarPorUsuario() {
      this.dataPorUsuario.datasets[0].data = [];
      this.dataPorUsuario.labels = [];

      this.metadadosAtendimento.totalAtendimentosPorUsuario.map((e) => {
        this.dataPorUsuario.labels.push(e.nomeUsuario);
        this.dataPorUsuario.datasets[0].data.push(
          parseFloat(e.atendimentos.length)
        );
      });
    },

    updateAmount() {
      this.atualizarPorStatus();
      this.atualizarPorCategoria();
      this.atualizarPorSolicitante();
      this.atualizarPorUsuario();

      this.updateChart();
    },
    obterMetadados() {
      this.loading = true;
      apiDashboard
        .obterTotalizadoresDeAtendimento(this.periodoEscolhido.valor)
        .then((response) => {
          this.metadadosAtendimento = new MetadadosAtendimento(response.data);
          this.loading = false;
          this.updateAmount();
          mixpanel.people.set({
            $qt_atendimentos: this.metadadosAtendimento.totalDeAtendimentos
          })
        })
        .catch((erro) => {
          this.dispararMensagem(erro.response.data.mensagem, "error");
          this.loading = false;
        })
        .finally((this.loading = false));
    },
  },
};
</script>
<style scoped>
.quantidade-votos {
  font-size: 18px;
  font-weight: 200;
}

.category {
  font-size: 1.2em;
}
.md-card {
  box-shadow: 0px 1px 10px 0px rgb(0 0 0 / 10%);
  border-radius: 8px;
}

.sem-dados {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}

.mensagem-sem-dados {
  display: flex;
  position: absolute;
  margin-top: 110px;
  font-size: 16px;
  font-weight: 600;
  color: #484848;
}

.espacamento-com-borda {
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 55px;
  margin: 15px;
  box-shadow: 0px 1px 10px 0px rgb(0 0 0 / 10%);
  background-color: #fff;
}
.espacamento-com-borda.dark,
.md-card.dark {
  background: #1b1b1b;
}

.totalizador-valor {
  font-size: 22px;
  font-weight: 600;
  color: #686868;
}
</style>
