export default class VisitaModel {
    constructor(obj) {
        obj = obj || {};
        this.id = obj.id;
        this.descricao = obj.descricao;
        this.data = obj.data;
        this.idCliente = obj.idCliente;
        this.observacao = obj.observacao;
        this.uf = obj.uf;
        this.cidade = obj.cidade;
        this.bairro = obj.bairro;
        this.complemento = obj.complemento;
        this.criadoEm = obj.criadoEm;
        this.atualizadoEm = obj.atualizadoEm;
    }
    modeloValido(){
        return !!(this.descricao && this.data);
    }
}
