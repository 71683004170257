import TipoAgendamentoModel from './tipo-agendamento-model';
import UsuarioModel from './usuario-model';
export default class AgendamentoModel {
    
    constructor(obj){
        obj = obj || {};
        this.id = obj.id;
        this.descricao = obj.descricao;
        this.observacao = obj.observacao;
        this.idUsuario = obj.idUsuario;
        this.usuario = new UsuarioModel(obj.usuario);
        this.idTipoAgendamento = obj.idTipoAgendamento;
        this.tipoAgendamento = new TipoAgendamentoModel(obj.tipoAgendamento);
        this.dataInicial = obj.dataInicial;
        this.dataFinal = obj.dataFinal;
        this.diaInteiro = obj.diaInteiro || false;
        this.criadoEm = obj.criadoEm;
        this.atualizadoEm = obj.atualizadoEm;
    }

    modeloValidoParaCadadastro(){
        return !!(this.descricao && 
                  this.tipoAgendamento && this.tipoAgendamento.id &&
                  this.dataInicial && 
                  this.dataFinal && 
                  this.usuario && this.usuario.id);
    }
}