<template>
  <v-dialog v-model="visivel" persistent max-width="45%">
    <v-card>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col class="d-flex text-center">
              <v-card class="pt-1 mx-auto" flat max-width="100%">
                <v-card-text>
                  <v-avatar
                    class="mb-6"
                    v-if="true"
                    size="90"
                    justify="center"
                    color="#ddd"
                  >
                    <span>Foto</span>
                    <!-- <v-img
                      :src="`https://avataaars.io/?accessoriesType=Kurt&avatarStyle=Circle&clotheColor=Gray01&clotheType=BlazerShirt&eyeType=Surprised&eyebrowType=Default&facialHairColor=Red&facialHairType=Blank&graphicType=Selena&hairColor=Red&hatColor=Blue02&mouthType=Twinkle&skinColor=Pale&topType=LongHairCurly`"
                      class="mb-6"
                    ></v-img> -->
                  </v-avatar>
                  <h3 class="headline mb-2">{{ model.nome }}</h3>
                  <div class="blue--text mb-2">{{ model.email }}</div>
                  <div class="blue--text subheading font-weight-bold">
                    {{ model.apelido }}
                  </div>
                </v-card-text>
                <v-divider></v-divider>
                <v-row class="text-center" tag="v-card-text">
                  <v-col cols="12">
                    <strong>Bairro: </strong> {{ model.endereco.bairro }}
                  </v-col>
                  <!-- <v-col cols="12">
                    <strong>WhatsApp: </strong> {{ model.whatsapp | telefone }}
                  </v-col> -->
                  <v-col cols="12">
                    <strong>Celular: </strong> {{ model.celular | telefone }}
                  </v-col>
                  <v-col cols="12">
                    <strong>Rede Social: </strong>
                    <a :href="`${model.redeSocialPrincipal}`" target="_blank">{{
                      model.redeSocialPrincipal
                    }}</a>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn elevation="0" @click="fecharModal()"
          >Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PessoaModel from "../model/pessoa-model";

export default {
  name: "VisualizarDetalhesPessoa",
  model: {
    prop: "itemSelecionado",
    event: "onChange",
  },
  props: {
    itemSelecionado: { type: PessoaModel, default: () => new PessoaModel() },
    visivel: { type: Boolean, default: false },
  },
  data() {
    return {
      model: this.itemSelecionado,
    };
  },

  filters: {
    telefone(valor) {
      if(!valor) return '';

      var r = valor.replace(/\D/g, "");
      r = r.replace(/^0/, "");
      if (r.length > 12) {
        r = r.replace(/^(\d\d)(\d{2})(\d{5})(\d{4}).*/, "(+$1 $2) $3-$4");
      } else if (r.length > 10) {
        r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
      } else if (r.length > 5) {
        r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
      } else if (r.length > 2) {
        r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
      } else {
        r = r.replace(/^(\d*)/, "($1");
      }
      return r;
    },
  },

  watch: {
    itemSelecionado() {
      this.model = this.itemSelecionado;
      this.$emit("onChange", this.model);
    },
  },

  methods: {
    fecharModal() {
      this.visivel = false;
      this.$emit("fechar-modal", this.visivel);
    },
  },
};

</script>
<style lang="scss">
.v-card > *:first-child:not(.v-btn):not(.v-chip),
.v-card > .v-card__progress + *:not(.v-btn):not(.v-chip),
div.container {
  padding-bottom: 0;
}
</style>
