'use strict';

import moment from 'moment';

function aplicarMascaraEmDataIso(data, valorDataVazia) {
  if (data) {
    return moment(data).locale('pt-br').format('DD/MM/YYYY');
  }

  return (valorDataVazia) ? valorDataVazia : null;
}

function aplicarMascaraDataHoraEmDataIso(data, valorDataVazia) {
  if (data) {
    return moment(data).locale('pt-br').format('DD/MM/YYYY HH:mm:ss');
  }

  return (valorDataVazia) ? valorDataVazia : null;
}


function aplicarMascaraDataHoraSemSegundos(data, valorDataVazia) {
  if (data) {
    return moment(data).locale('pt-br').format('DD/MM/YYYY HH:mm');
  }

  return (valorDataVazia) ? valorDataVazia : null;
}
function formatarDataEHoraIso(data, horario) {
  return `${moment(data).locale('pt-br').format('YYYY-MM-DD')}T${horario || '00:00:00'}`;
}

function dataFinalMaiorOuIgualDataInicial(dataInicial, dataFinal) {
  return !dataInicial || (dataInicial == '') ||
    !dataFinal || (dataFinal == '') ||
    (new Date(dataInicial) <= new Date(dataFinal));
}


function aplicarMascaraHoraSemSegundos(data, valorDataVazia) {
  if (data) {
    return moment(data).locale('pt-br').format('HH:mm');
  }

  return (valorDataVazia) ? valorDataVazia : null;
}


export default {
  dataFinalMaiorOuIgualDataInicial,
  aplicarMascaraDataHoraEmDataIso,
  aplicarMascaraDataHoraSemSegundos,
  formatarDataEHoraIso,
  aplicarMascaraEmDataIso,
  aplicarMascaraHoraSemSegundos
};