
import api from './api'


function obterTotalizadoresDeAtendimento(periodoDashboard){
    return  new Promise((resolve, reject) =>{
        return api.get(`/dashboard/atendimentos?periodo=${periodoDashboard}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
}

function obterTotalizadoresDePessoas(periodoDashboard){
    return  new Promise((resolve, reject) =>{
        return api.get(`/dashboard/pessoas?periodo=${periodoDashboard}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
}

export default {
    obterTotalizadoresDeAtendimento,
    obterTotalizadoresDePessoas
}