import api from './api'

function cadastrar(cliente){
  cliente = cliente || {};
  cliente.senha = cliente.senha && btoa(cliente.senha)
  return  new Promise((resolve, reject) =>{
    return api.post('/cliente/assinante', cliente)
    .then(response => resolve(response))
    .catch(error => reject(error))
  });
}

export default {
  cadastrar  
}