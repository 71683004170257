
import api from './api'

function obterTodos(){
    return  new Promise((resolve, reject) =>{
        return api.get('/tipos-de-contatos')
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
}

function obter(id){
    return  new Promise((resolve, reject) =>{
        return api.get(`/tipos-de-contatos/${id}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
}

export default {
    obterTodos,
    obter
}