<template>
  <v-container class="mt-6">
    <div style="width: 100%">
      <v-snackbar
        v-model="snackbar"
        :vertical="false"
        :timeout="timeout"
        top="top"
        :color="tipo"
        elevation="5"
        multi-line
        right
        shaped
      >
        {{ mensagem }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false">OK</v-btn>
        </template>
      </v-snackbar>

      <div v-if="loading" class="loading text-center">
        <v-progress-circular
          :size="70"
          :width="5"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <h1 class="loading-titulo">Carregando</h1>
      </div>

      <div v-if="!loading">
        <v-layout>
          <v-flex>
            <v-layout wrap>
              <v-col class="pl-0 mt-2 ml-0 mb-0" cols="12" xs="12" sm="12">
                <h1 class="titulo mb-0">Controle de visitas</h1>
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12" class="pl-0 ml-0" sm="6">
                <v-btn
                  class="btn-primario ml-0"
                  color="primary"
                  elevation="0"
                  @click="adicionarVisita"
                >
                  <v-icon class="mr-2 icone">mdi-plus</v-icon>
                  Adicionar</v-btn
                >
              </v-col>
              <v-card width="100%">
                <v-card-title class="pt-0">
                  <v-col cols="12" xs="12" sm="1">
                    <v-tooltip color="#343434" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <download-excel
                          class="btn btn-default"
                          :data="visitaPlanilha"
                          worksheet="Visitas"
                          name="visitas.xls"
                        >
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            text
                            class="btn-tertiary"
                          >
                            <v-icon small class="mr-1">
                              mdi-microsoft-excel
                            </v-icon>
                            Exportar
                          </v-btn>
                        </download-excel>
                      </template>
                      <span>Exportar visitas para excel</span>
                    </v-tooltip>
                  </v-col>
                  <v-spacer></v-spacer>

                  <v-col cols="12" xs="12" sm="2">
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Pesquisar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-card-title>
                <v-data-table
                  :headers="colunas"
                  :items="visitas"
                  :search="search"
                  show-expand
                  single-expand
                  sort-by="id"
                  sort-desc
                  :items-per-page="5"
                >
                  <template v-slot:[`item.criadoEm`]="{ item }">
                    {{ item.criadoEm | data }}
                  </template>
                  <template v-slot:[`item.acoes`]="{ item }">
                    <v-menu
                      offset-y
                      offset-overflow
                      left
                      slide-y
                      @click.stop="() => {}"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn class="btn-opcoes" text v-on="on">
                          <span class="align-text-icon">Opções</span>
                          <v-icon class="seta-para-cima">arrow_drop_up</v-icon>
                          <v-icon class="seta-para-baixo">arrow_drop_down</v-icon>
                        </v-btn>
                      </template>

                      <v-list class="lista-do-menu">
                        <v-list-item
                          v-for="opcao in opcoes"
                          :key="opcao.id"
                          @click="opcao.metodo(item)"
                        >
                          <v-list-item-title>{{
                            opcao.titulo
                          }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>

                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length" class="bloco-expansivel-td">
                      <div class="detalhes-visita">
                        <div v-if="item.descricao">
                          <strong class="primary--text">Descrição:<br> </strong
                          >{{ item.descricao }}
                        </div>
                        <div v-if="item.complemento && item.bairro && item.cidade && item.uf">
                          <strong class="primary--text">Endereço:<br> </strong
                          >{{item.complemento}} - {{ item.bairro }} - {{ item.cidade }}/{{item.uf}}
                        </div>
                        <div v-if="item.observacao">
                          <strong class="primary--text">Observação:<br></strong>
                          {{ item.observacao }}
                        </div>
                        
                      </div>
                    </td>
                  </template>
                </v-data-table>
              </v-card>
            </v-layout>
          </v-flex>
        </v-layout>

        <v-row>
          <!-- Modal cadastro -->
          <v-dialog v-model="modalAdicionarVisita" persistent max-width="1280px">
            <v-card width="100%">
              <v-card-title
                :class="
                  !!(isDark === 'dark') ? 'titulo-modal-dark' : 'titulo-modal'
                "
              >
                <span class="headline">
                  <span class="headline">
                    {{ !modoEdicao ? "Adicionar " : "Editar " }} visita
                  </span>
                </span>
              </v-card-title>
              <v-card-text>
                <v-row class="pa-2">
                  <v-col cols="12">
                    <v-card elevation="0">
                      <v-tabs>
                        <v-tab>
                          <v-icon left> mdi-ballot </v-icon>
                          Principal
                        </v-tab>
                        <v-tab>
                          <v-icon left> mdi-map-marker </v-icon>
                          Local
                        </v-tab>

                        <!-- Tab Principal -->
                        <v-tab-item>
                          <v-card flat>
                            <v-card-text>
                              <div class="tamanho-tab-cadastro">
                                <v-row>
                                  <v-col cols="12" sm="12" md="9">
                                    <v-text-field
                                      label="Descrição"
                                      v-model="visita.descricao"
                                      outlined
                                      dense
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="3">
                                    <date-picker
                                      label="Data"
                                      v-model="visita.data"
                                      data-minima="2000-01-01"
                                    >
                                    </date-picker>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12" sm="12" md="12">
                                    <v-textarea
                                      auto-grow
                                      rows="6"
                                      v-model="visita.observacao"
                                      label="Observação"
                                      outlined
                                      dense
                                    ></v-textarea>
                                  </v-col>
                                </v-row>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-tab-item>

                        <!-- Tab endereço -->
                        <v-tab-item>
                          <v-card flat>
                            <v-card-text>
                              <div class="tamanho-tab-cadastro">
                                <v-row>
                                  <v-col cols="12" sm="6" md="2">
                                    <v-autocomplete
                                      :items="listaDeUFs"
                                      label="UF"
                                      autocomplete="off"
                                      clearable
                                      v-model="visita.uf"
                                      outlined
                                      dense
                                    ></v-autocomplete>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="5">
                                    <v-autocomplete
                                      :items="listaDeCidades"
                                      label="Cidade"
                                      autocomplete="off"
                                      clearable
                                      outlined
                                      dense
                                      v-model="visita.cidade"
                                    ></v-autocomplete>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="5">
                                    <v-text-field
                                      v-model="visita.bairro"
                                      autocomplete="off"
                                      label="Bairro"
                                      outlined
                                      dense
                                    >
                                    </v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="12">
                                    <v-text-field
                                      outlined
                                      dense
                                      v-model="visita.complemento"
                                      label="Complemento"
                                      autocomplete="off"
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-tab-item>
                      </v-tabs>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  class="btn-primario"
                  @click="salvar"
                  elevation="0"
                  :disabled="desabilitarBotoes"
                >
                  Salvar
                </v-btn>

                <v-btn
                  class="btn-terciario ml-0"
                  elevation="0"
                  :disabled="desabilitarBotoes"
                  @click="cancelar"
                  >Cancelar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div> 

    </div>

    <alteracao-status
      idBotaoPrimario="btn-confirmar-cancelar-visita"
      idBotaoSecundario="btn-desfazer-cancelar-visita"
      v-model="abrirJanelaExclusao"
      :callbackPrimario="callbackExcluirVisita"
      :mensagem="mensagemExclusao"
    />
  </v-container>
</template>
<script>

//APIs
import apiVisita from "../api/visita-api";
import apiIBGE from "../api/ibge-api";

//Models
import VisitaModel from "../model/visita-model";

// Mixins e Componentes
import mixinMensagen from "../mixin/mixin-mensagem";
import mixinGeral from "../mixin/mixin-geral";
import DatePicker from "@/components/comum/Datepicker";
import JsonExcel from "vue-json-excel";
import AlteracaoStatus from '@/components/JanelaConfirmacao.vue'

// Outros
import moment from "moment";
import mixpanel from "mixpanel-browser";

export default {
  name: "controle-visitas",
  mixins: [mixinMensagen, mixinGeral],
  components: {
    DatePicker,
    downloadExcel: JsonExcel,
    "alteracao-status": AlteracaoStatus,
  },
  filters: {
    data(valor) {
      if (!valor) return "";
      return moment(valor).format("DD/MM/YYYY");
    },
  },

  data() {
    return {
      modalAdicionarVisita: false,
      loading: false,
      modoEdicao: false,
      desabilitarBotoes: false,
      listaDeUFs: [],
      listaDeCidades: [],
      search: "",
      colunas: [
        { text: "Código", value: "id", align: "center" },
        { text: "Descrição", value: "descricao", align: "left" },
        { text: "Data de cadastro", value: "criadoEm", align: "center" },
        { text: "", value: "acoes", sortable: false, align: "right" },
      ],

      visita: new VisitaModel(),
      visitas: [],
      opcoes: [],
      abrirJanelaExclusao: false,
      callbackExcluirVisita: () => {},
      mensagemExclusao: "Deseja realmente excluir esta visita?",
      visitaPlanilha: [],
    };
  },
  watch: {
    "visita.uf": function (uf) {
      this.obterObterCidadesPorUF(uf);
    },
  },
  mounted() {
    this.loading = true;
    this.obterTodasVisitas();
    this.obterUFs();
    this.opcoes = this.montarOpcoes();
    mixpanel.track('page_view')
  },
  methods: {
    obterTodasVisitas() {
      apiVisita.obterTodos()
          .then((resposta) => {
              this.visitas = resposta.data.map((v) => new VisitaModel(v));
              this.preencherTabelaParaExportacao();
          })
          .catch((error) => {
            console.log(error)
              this.dispararMensagem(error.mensagem, "error");
          })
          .finally(() => {
              this.loading = false;
          });
    },

    preencherTabelaParaExportacao() {
      this.visitaPlanilha = this.visitas.map((a) => {
        return {
          Codigo: a.id,
          "Descrição": a.descricao,
          Data: a.data && moment(a.data).format("DD/MM/YYYY"),
          "Observação": a.observacao,
          "Data De Cadastro":
            a.criadoEm && moment(a.criadoEm).format("DD/MM/YYYY"),
        };
      });
    },
    montarOpcoes() {
      return [
        { id: 1, titulo: "Editar", metodo: this.editarVisita },
        { id: 2, titulo: "Excluir", metodo: this.deletar },
      ];
    },

    async editarVisita(visita) {
      this.modoEdicao = true;
      this.obterVisitaPorId(visita.id);
      this.modalAdicionarVisita = true;
    },

    adicionarVisita() {
      this.modoEdicao = false;
      this.visita = new VisitaModel();
      this.modalAdicionarVisita = true;
      mixpanel.track("click",{
        nome_botao: 'btn_adicionar_visita',
      });
    },

    salvar() {
      !this.modoEdicao
        ? this.cadastrarVisita()
        : this.atualizarVisita();
    },

    obterVisitaPorId(idVisita) {
      this.loading = true;

      apiVisita
        .obterPorId(idVisita)
        .then((resposta) => {
          this.visita = new VisitaModel(resposta.data);
        })
        .catch((error) => {
          this.dispararMensagem(error.response.data.mensagem, "error");
        })
        .finally((this.loading = false));
    },

    cancelar() {
      this.modalAdicionarVisita = false;
      this.visita = new VisitaModel();
      this.modoEdicao = false;

      mixpanel.track("click",{
        nome_botao: 'btn_cancelar',
      });
    },

    obterUFs() {
      apiIBGE.obterEstados().then((resposta) => {
        this.listaDeUFs = resposta.data.map((uf) => uf.sigla).sort();
      });
    },

    obterObterCidadesPorUF(uf) {
      apiIBGE.obterMunicipios(uf).then((resposta) => {
        this.listaDeCidades = resposta.data.map((c) => c.nome).sort();
      });
    },

    limparCampos() {
      this.visita = new VisitaModel();
    },

    cadastrarVisita() {
      this.modoEdicao = false;
      this.desabilitarBotoes = true;

      apiVisita
        .cadastrar(this.visita)
          .then((response) => {
            this.visitas.push(new VisitaModel(response.data));
            this.preencherTabelaParaExportacao();
            this.dispararMensagem(`Cadastrado efetuado com sucesso!`, "success");

            this.limparCampos();
            this.modalAdicionarVisita = false;
            mixpanel.track("cadastrou_visita",{
              visita_id: response.data.id,
            });
          })
          .catch((erro) => {
            this.dispararMensagem(erro.response.data.mensagem, "error");
          })
          .finally(() => {
            this.desabilitarBotoes = false;
          });
    },
    atualizarVisita() {
      this.modoEdicao = true;
      this.desabilitarBotoes = true;

      apiVisita
        .atualizar(this.visita)
        .then((response) => {
          this.dispararMensagem(
            `A visita foi atualizada com sucesso!`,
            "success"
          );
          mixpanel.track("atualizou_visita",{
              visita_id: this.visita.id,
            });
          this.atualizarVisitaNaLista(new VisitaModel(response.data));
          this.limparCampos();
          this.modalAdicionarVisita = false;
        })
        .catch((erro) => {
          this.dispararMensagem(erro.response.data.mensagem, "error");
        })
        .finally(() => {
          this.desabilitarBotoes = false;
        });
    },

    deletar(visita) {
      this.abrirJanelaExclusao = true;
      this.visita = new VisitaModel(visita);
      this.callbackExcluirVisita = this.deletarVisita;
    },

    deletarVisita() {
      apiVisita
        .deletar(this.visita.id)
        .then(() => {
          this.dispararMensagem(`Visita deletada com sucesso!`, "success");
          this.atualizarVisitaNaLista(this.visita, true);
          this.abrirJanelaExclusao = false;
          mixpanel.track("excluiu_visita",{
              visita_id: this.visita.id,
            });
        })
        .catch((erro) =>
          this.dispararMensagem(erro.response.data.mensagem, "error")
        )
        .finally((this.modalAdicionarVisita = false));
    },

    atualizarVisitaNaLista(visita, deletarDaLista) {
      if (!visita || !visita.id) return;

      var index = this.visitas.map((p) => parseInt(p.id)).indexOf(parseInt(visita.id));
      
      !deletarDaLista
        ? this.visitas.splice(index, 1, visita)
        : this.visitas.splice(index, 1);
      
      this.preencherTabelaParaExportacao();
    },

  },
};
</script>
<style scoped>
.tamanho-tab-cadastro {
  min-height: 300px;
}

div.descricao {
  margin-bottom: 10px;
}
</style>
