
export default class MetadadosAtendimento {
    constructor(metadados){
        metadados = metadados || [];
        this.totalDeAtendimentos = metadados.length;
        this.totalAtendimentosPorStatus = this._preencherPorStatus(metadados);
        this.totalAtendimentosPorSolicitante = this._preencherPorSolicitante(metadados);
        this.totalAtendimentosPorCategoria = this._preencherPorCategoria(metadados);
        this.totalAtendimentosPorUsuario = this._preencherPorUsuario(metadados);
    }

    _preencherPorStatus(metadados){
        let porStatus = [];
        metadados.map(e => {
            if(!porStatus.some(ed => ed.idStatus == e.idStatus)){
                porStatus.push({
                    idStatus: e.idStatus,
                    descricao: e.descricaoStatus,
                    atendimentos: [e]
                });
            }else{
                porStatus.map(r => {
                    if(r.idStatus== e.idStatus){
                        r.atendimentos.push(e);
                    }
                });
            }
        });

        return porStatus;
    }

    _preencherPorSolicitante(metadados){
        let porSolicitante = [];
        metadados.map(e => {
            if(!porSolicitante.some(ed => ed.idSolicitante == e.idSolicitante)){
                porSolicitante.push({
                    idSolicitante: e.idSolicitante,
                    nomeSolicitante: e.nomeSolicitante,
                    atendimentos: [e]
                });
            }else{
                porSolicitante.map(r => {
                    if(r.idSolicitante== e.idSolicitante){
                        r.atendimentos.push(e);
                    }
                });
            }
        });

        // Ordemando em ordem decrescente.
        porSolicitante.sort((a, b) => a.atendimentos.length - b.atendimentos.length).reverse();
        porSolicitante = porSolicitante.slice(0, 20);

        return porSolicitante;
    }

    _preencherPorCategoria(metadados){
        let porCategoria = [];
        metadados.map(e => {
            if(!porCategoria.some(ed => ed.idCategoria == e.idCategoria)){
                porCategoria.push({
                    idCategoria: e.idCategoria,
                    descricao: e.descricaoCategoria,
                    atendimentos: [e]
                });
            }else{
                porCategoria.map(r => {
                    if(r.idCategoria== e.idCategoria){
                        r.atendimentos.push(e);
                    }
                });
            }
        });

        return porCategoria;
    }

    _preencherPorUsuario(metadados){
        let porUsuario = [];
        metadados.map(e => {
            if(!porUsuario.some(ed => ed.idUsuario == e.idUsuario)){
                porUsuario.push({
                    idUsuario: e.idUsuario,
                    nomeUsuario: e.nomeUsuario,
                    emailUsuario: e.emailUsuario,
                    atendimentos: [e]
                });
            }else{
                porUsuario.map(r => {
                    if(r.idUsuario== e.idUsuario){
                        r.atendimentos.push(e);
                    }
                });
            }
        });

        return porUsuario;
    }
}
