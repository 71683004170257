<template>
  <v-app :id="id.replace(/[^a-z0-9]/gi, '-').toLowerCase() + '-app'">
    <v-container >
      <v-layout wrap>
        <v-flex xs12 sm12 md12>
          <editor-tinymce
            v-model="textoDoEditor"
            :id="id.replace(/[^a-z0-9]/gi, '-').toLowerCase()"
            @onFocusIn="focusIn"
            @onFocusOut="focusOut"
            :init="init"
            :initial-value="inicial"
            :disabled="desabilitarEditor"
          >
          </editor-tinymce>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import "tinymce/tinymce";

// Plugins
import "tinymce/plugins/advlist";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/autolink";
import "tinymce/plugins/autosave";
import "tinymce/plugins/bbcode";
import "tinymce/plugins/codesample";
import "tinymce/plugins/colorpicker";
import "tinymce/plugins/lists";
import "tinymce/plugins/link";
import "tinymce/plugins/charmap";
import "tinymce/plugins/print";
import "tinymce/plugins/preview";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/visualblocks";
import "tinymce/plugins/code";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/fullpage";
import "tinymce/plugins/insertdatetime";
import "tinymce/plugins/media";
import "tinymce/plugins/table";
import "tinymce/plugins/paste";
import "tinymce/plugins/wordcount";
import "tinymce/plugins/autoresize";
import "tinymce/plugins/template";
import "tinymce/plugins/image";
import "tinymce/plugins/help";
import "tinymce/plugins/pagebreak";
import "@/plugins/editor-tinymce/svg-material-icons-editor.js";
 import variaveisEditor from "@/plugins/editor-tinymce/inserir-variaveis-editor.js";

export default {
  model: {
    prop: "entrada",
    event: "onChange",
  },
  props: {
    inicial: {
      type: String,
    },
    entrada: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: "editor-tinemce-bimer",
    },
    editorToolbar: {
      type: String,
      default: `inserirdados undo redo pagebreak formatselect 
              fontselect fontsizeselect bold italic underline 
              alignleft aligncenter alignright 
              alignjustify outdent indent numlist bullist 
              forecolor backcolor removeformat table image charmap preview `,
    },
    templatesDoEditor: {
      type: Array,
      default: () => [],
    },
    variaveisDoEditor: {
      type: Object,
      default: () => {},
    },
    alturaDoEditor: {
      type: Number,
      default: 550,
    },
    desabilitarEditor: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    // Isso forca que as variaveis sejam carregadas antes da inicializacao do componentes em si
    // Foi necessario pois as callbacks do vue nao estavam sendo o suficiente. O editor estava sendo carregado
    // antes mesmo do request terminar.
    "editor-tinymce": ((resolve) => {
       variaveisEditor.init()
      .then(() => resolve(Editor))
    })

    // "editor-tinymce": Editor,
  },
  watch: {
    entrada(novoValor) {
      this.setNovoValorNoEditor(novoValor);
    },

    textoDoEditor(valor) {
      this.change(valor);
    },
  },

  data() {
    return {
      textoDoEditor: "",
      init: {
        extended_valid_elements: "span[nome-variavel|style|contenteditable]",
        selector: "textarea",
        theme: "silver",
        icons: "material",
        height: this.alturaDoEditor,
        branding: false,
        relative_urls: false,
        // TODO: Escondido provisoriamente até escolher a rotina de variáveis.
        // external_plugins: {
        //   inserirdados: "",
        //   "inserirdados-auto-complete": "",
        // },
        plugins: [
          "print advlist autolink lists link image charmap print preview",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code wordcount pagebreak image emoticons help",
        ],
        menubar: true,
        contextmenu: true,
        automatic_uploads: true,
        a11y_advanced_options: true,
        file_picker_types: "image",
        /* and here's our custom image picker*/
        // file_picker_callback: function (cb, value, meta) {
        //   var input = document.createElement("input");
        //   input.setAttribute("type", "file");
        //   input.setAttribute("accept", "image/*");

        //   input.onchange = function () {
        //     var file = this.files[0];

        //     var reader = new FileReader();
        //     reader.onload = function () {
        //       /*
        //   Note: Now we need to register the blob in TinyMCEs image blob
        //   registry. In the next release this part hopefully won't be
        //   necessary, as we are looking to handle it internally.
        // */
        //       var id = "blobid" + new Date().getTime();
        //       var blobCache = tinymce.activeEditor.editorUpload.blobCache;
        //       var base64 = reader.result.split(",")[1];
        //       var blobInfo = blobCache.create(id, file, base64);
        //       blobCache.add(blobInfo);

        //       /* call the callback and populate the Title field with the file name */
        //       cb(blobInfo.blobUri(), { title: file.name });
        //     };
        //     reader.readAsDataURL(file);
        //   };

        //   input.click();
        // },
        // contextmenu: "copy | inserttable  cell row column deletetable",
        toolbar: `${this.editorToolbar}`,
        templates: this.templatesDoEditor,
        template_replace_values: this.variaveisDoEditor,
        language: "pt_BR",
        table: { title: 'Table', items: 'inserttable | cell row column | tableprops deletetable' },
    help: { title: 'Help', items: 'help' },
        content_style: "@media print{@page{size:auto;margin:0;}}",
      },
    };
  },

  methods: {
    //TODO: Fiz desta forma pois nao consegui de outra forma acessar o compomente via CSS.
    //O tinymce nao cria o editor dentro do textarea, ele cria um iframe fora do contexto.
    //futuramente com mais calma, encontrar uma forma de  alterar o focus de maneira mais elegante.
    focusIn() {
      document
        .querySelector("div.tox-sidebar-wrap > .tox-edit-area")
        .classList.add("focus");
      document
        .querySelector(".tox-tinymce > div.tox-statusbar")
        .classList.add("focus");
    },

    focusOut() {
      document
        .querySelector("div.tox-sidebar-wrap > .tox-edit-area")
        .classList.remove("focus");
      document
        .querySelector(".tox-tinymce > div.tox-statusbar")
        .classList.remove("focus");
    },

    setNovoValorNoEditor(novoValor) {
      this.textoDoEditor = novoValor || "";
    },

    change(novoValor) {
      this.$emit("onChange", novoValor ? novoValor : undefined);
    },
  },
};
</script>

<style lang="scss">
%borda-esquerda-direita-defaul {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

%ajustes-divs-internas-toolbar {
  background-color: #fff;
  box-shadow: 0 0 12px 0 rgba(50, 50, 50, 0.1);
  padding: 5px;
}

.tox-menu.tox-collection.tox-collection--list {
    border-top: 2px solid #10235A;
} 

.tox.tox-tinymce {
  border: none;
}

div.tox.tox-tinymce {
  border-left: none;
  border-right: none;
   width: 100%;
}
/*
[dir] .tox-toolbar .tox-toolbar__group:first-child{
   width: 100%;
}
*/
.tox-toolbar {
  border: none;
}


div.tox-toolbar__group{
  width: 100%;
}

.tox-toolbar .tox-toolbar__group:first-child {
  border: none;
  @extend %ajustes-divs-internas-toolbar;
}

div .tox .tox-toolbar {
  margin: 10px 2px 10px 2px;
  border: none;
  background: none;
  background-color: #fff;
  width: 100%;
  padding-right: 4px;
}

div.tox-statusbar {
  @extend %borda-esquerda-direita-defaul;
}

div.tox .tox-toolbar__primary {
  background-image: none;
}

div.tox-sidebar-wrap > .tox-edit-area {
  box-shadow: 0 0 12px 0 rgba(50, 50, 50, 0.12);
  margin-left: 2px;
  margin-right: 2px;
}

div.tox-sidebar-wrap > .tox-edit-area.focus {
  box-shadow: 0 0 2px 0 primary;
  margin-left: 2px;
  margin-right: 2px;
}

.tox-tinymce > div.tox-statusbar {
  box-shadow: 0 0 12px 0 rgba(50, 50, 50, 0.12);
  margin-left: 2px;
  margin-right: 2px;
  margin-bottom: 4px;
  border-top: none;
}
.tox-tinymce > div.tox-statusbar.focus {
  box-shadow: 0 0 2px 0 primary;

  margin-left: 2px;
  margin-right: 2px;
  margin-bottom: 4px;
  border-top: none;
}
.tox.tox-silver-sink.tox-tinymce-aux{
  z-index: 999999;
}

div.tox:not([dir="rtl"]) .tox-toolbar__group:not(:last-of-type) {
  border-right: none;
}

.tox-menu.tox-collection.tox-collection--list {
  border-top: 2px solid primary;
}

.tox-statusbar > .tox-statusbar__text-container > .tox-statusbar__path {
  display: none;
} 

.tox .tox-autocompleter .tox-autocompleter-highlight{
  background: primary;
  color: #343434;
}

.tox:not(.tox-tinymce-inline) .tox-editor-header {
  margin: 2px;
}


element.style {
    padding: 0;
}

#editor-tinemce-bimer-app>div>div{
  padding: 0;
}

div.tox .tox-dialog {
  max-width: 550px;
}

@media only screen and (max-width: 1263px) {
  .tox-toolbar .tox-toolbar__group:first-child {
    margin-right: 5px;
  }
}


</style>
