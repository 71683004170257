import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import VueGoogleCharts from 'vue-google-charts'
import VueMask from 'v-mask'
import money from 'v-money';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import "chartjs-plugin-datalabels";

// Plugins
import GlobalComponents from "./globalComponents";
import GlobalDirectives from "./globalDirectives";
import Notifications from "./components/NotificationPlugin";

// MaterialDashboard plugin
import MaterialDashboard from "./material-dashboard";

// Tracking e Monitoramento
import mixpanel from 'mixpanel-browser'
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

import Chartist from "chartist";

Vue.prototype.$Chartist = Chartist;

Vue.use(MaterialDashboard);
Vue.use(GlobalComponents);
Vue.use(GlobalDirectives);
Vue.use(Notifications);

import { ClockIcon, 
  ArchiveIcon, 
  SmileIcon, 
  FrownIcon,
  XCircleIcon,
  UsersIcon,
  CheckSquareIcon,
  CalendarIcon,
  Edit3Icon,
  FileTextIcon,
  NavigationIcon,
  UserIcon,
  MoonIcon,
  SunIcon,
  ActivityIcon,
  GridIcon,
  PieChartIcon,
  EyeIcon } from 'vue-feather-icons'

Vue.component("clock-icon", ClockIcon);
Vue.component("archive-icon", ArchiveIcon);
Vue.component("smile-icon", SmileIcon);
Vue.component("frown-icon", FrownIcon);
Vue.component("x-circle-icon", XCircleIcon);
Vue.component("users-icon", UsersIcon);
Vue.component("check-square-icon", CheckSquareIcon);
Vue.component("calendar-icon", CalendarIcon);
Vue.component("edit-3-icon", Edit3Icon);
Vue.component("file-text-icon", FileTextIcon);
Vue.component("navigation-icon", NavigationIcon);
Vue.component("user-icon", UserIcon);
Vue.component("moon-icon", MoonIcon);
Vue.component("sun-icon", SunIcon);
Vue.component("activity-icon", ActivityIcon);
Vue.component("grid-icon", GridIcon);
Vue.component("pie-chart-icon", PieChartIcon);
Vue.component("eye-icon", EyeIcon);

Sentry.init({
  Vue,
  dsn: "https://de82766be55b42ddb693a4c210aa8fcc@o1319164.ingest.sentry.io/4504029590913024",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["production", "app.gestaopoliticafacil.com.br", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  trackComponents: ["Header", "Navigation", "Footer"],
  hooks: ["create", "mount"],
});

Vue.use(VueMask);
Vue.use(VueGoogleCharts)
Vue.use(money, {precision: 2}); 

Vue.config.productionTip = false;
mixpanel.init('8ec7f3d8519c8189a57c64e0eb116918', {debug:false})
Vue.use(mixpanel)
new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
