import UsuarioModel from './usuario-model';
import TipoDeContatoModel from './tipo-de-contato-model';
import StatusModel from './status-model';

export default class ParecerModel {
    constructor(obj){
        obj = obj || {};
        this.id = obj.id;
        this.descricao = obj.descricao;
        this.tipoDeContato = new TipoDeContatoModel(obj.tipoDeContato);
        this.usuario = new UsuarioModel(obj.usuario);
        this.statusAtendimento = new StatusModel(obj.statusAtendimento);
        this.idAtendimento = obj.idAtendimento;
        this.pessoaDeContato = obj.pessoaDeContato;
        this.criadoEm = obj.criadoEm;
        this.atualizadoEm = obj.atualizadoEm;
    }

    modeloValido(){
        return !!(this.descricao);
    }
}
