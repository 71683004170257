'use strict';

// TODO: Variáveis comentadas por enquanto, será liberado em breve!
// import variaveisInvestidor from './variaveis-investidor';
// import variaveisContrato from './variaveis-contrato';
// import variaveisFavorecido from './variaveis-favorecido';
// import variaveisDatas from './variaveis-datas';

function obterVariaveisSistema(contrato){
  contrato = contrato || {};
  console.log(contrato);
  return [].concat(
    // variaveisInvestidor.obter(contrato), 
    // variaveisContrato.obter(contrato),
    // variaveisFavorecido.obter(contrato),
    // variaveisDatas.obter()
  );
}

export default { obterVariaveisSistema };