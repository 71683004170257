<script>
import { Bar, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Bar  ,
  mixins: [reactiveProp],
  props:['chartData','options'],

  methods: {
    update() {
        this.$data._chart.update()
      }
  },
  mounted () {
    this.renderChart(this.chartData, this.options)
  },
  watch: {
    chartData () {
        this.update();
    },
    options(){
      this.update();
    }
  }
};
</script>