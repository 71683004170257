import axios from 'axios';

const URL_BASE = "https://servicodados.ibge.gov.br"

function obterEstados() {
  return new Promise((resolve, reject) => {
    axios.get(`${URL_BASE}/api/v1/localidades/estados`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function obterMunicipios(selectUF) {
  return new Promise((resolve, reject) => {
    axios.get(`${URL_BASE}/api/v1/localidades/estados/${selectUF}/municipios`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

export default {
  obterEstados,
  obterMunicipios
};
