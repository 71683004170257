import { STATUS_ATENDIMENTO } from '../constants/configuracoes-constants'
export default class StatusModel {
    constructor(obj){
        obj = obj || {};
        this.id = obj.id || STATUS_ATENDIMENTO.PENDENTE.id;
        this.descricao = obj.descricao || STATUS_ATENDIMENTO.PENDENTE.descricao;
        this.criadoEm = obj.criadoEm;
        this.atualizadoEm = obj.atualizadoEm;
    }

    modeloValido(){
        return !!(this.descricao);
    }
}
