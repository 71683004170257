<template>
  <v-row justify="center">
    <v-dialog v-model="abrirJanela" persistent max-width="600">
      <v-card>
        <v-card-title v-html="mensagem" class="">
        </v-card-title>
        <v-card-text>
          <v-btn
            @click="callbackPrimario"
            color="primary"
            >
            {{ textoBotaoPrimario }}
          </v-btn>
          <v-btn
            @click="fecharJanela"
            >
            {{ textoBotaoSecundario }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>

export default {
  name: "AlteracaoStatus",
  model: {
    prop: "abrirJanela",
    event: "onChange",
  },
  data() {
    return {
      janelaAberta: this.abrirJanela,
    };
  },
  props: {
    mensagem: { type: String, default: "Confirma a alteração do status?" },
    textoBotaoPrimario: { type: String, default: "Sim" },
    textoBotaoSecundario: { type: String, default: "Não" },
    abrirJanela: { type: Boolean, default: false },
    callbackPrimario: { type: Function, default: () => {} },
    // callbackSecundario: { type: Function, default: ()=> {}},
    idBotaoPrimario: { type: String, default: "" },
    idBotaoSecundario: { type: String, default: "" },
  },

  methods: {
    fecharJanela() {
      this.janelaAberta = false;
      this.$emit("onChange", this.janelaAberta);
    },

  },
};
</script>
<style scoped>
/* .v-card__title.headline {
  justify-content: center;
  align-content: center;
} */
div.v-card__text {
  margin-top: 15px;
  text-align: end;
}
</style>
