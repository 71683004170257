<template>
  <v-container class="mt-8">
    <div style="width: 100%">
      <v-snackbar
        v-model="snackbar"
        :vertical="false"
        :timeout="timeout"
        top="top"
        :color="tipo"
        elevation="5"
        multi-line
        right
        shaped
      >
        {{ mensagem }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false">OK</v-btn>
        </template>
      </v-snackbar>

      <div v-if="loading" class="loading text-center">
        <v-progress-circular
          :size="70"
          :width="5"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <h1 class="loading-titulo">Carregando</h1>
      </div>

      <div v-if="!loading">
        <v-row>
          <v-flex>
            <v-layout wrap>
              <v-col class="pl-0 ml-0 mb-0" cols="12" xs="12" sm="12">
                <h1 class="titulo mb-0">Controle de Usuarios</h1>
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12" class="pl-0 ml-0" sm="6">
                <v-btn
                  class="btn-primario ml-0"
                  color="primary"
                  elevation="0"
                  @click="dialog = !dialog"
                >
                  <v-icon class="mr-2 icone">mdi-plus</v-icon>
                  Adicionar</v-btn
                >
              </v-col>

              <v-card width="100%">
                <v-card-title class="pt-0">
                  <v-spacer></v-spacer>
                  <v-col cols="12" xs="12" sm="2">
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Pesquisar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-card-title>
                <v-data-table
                  :headers="colunas"
                  :items="usuarios"
                  :search="search"
                  sort-by="id"
                  sort-desc
                >
                  <template v-slot:item.criadoEm="{ item }">
                    {{ item.criadoEm | data }}
                  </template>
                  <template v-slot:item.totalAtendimentos="{ item }">
                    <v-chip class="totalizador" color="info" text-color="white">
                      {{ item.totalAtendimentos }}
                    </v-chip>
                  </template>

                  <template v-slot:item.acoes="{ item }">
                    <v-menu
                      offset-y
                      offset-overflow
                      left
                      slide-y
                      @click.stop="() => {}"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn class="btn-opcoes" text v-on="on">
                          <span class="align-text-icon">Opções</span>
                          <v-icon class="seta-para-cima">arrow_drop_up</v-icon>
                          <v-icon class="seta-para-baixo"
                            >arrow_drop_down</v-icon
                          >
                        </v-btn>
                      </template>

                      <v-list class="lista-do-menu">
                        <v-list-item
                          v-for="opcao in opcoes"
                          :key="opcao.id"
                          @click="opcao.metodo(item)"
                        >
                          <v-list-item-title>{{
                            opcao.titulo
                          }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                </v-data-table>
              </v-card>
            </v-layout>
          </v-flex>
        </v-row>

        <v-dialog v-model="dialog" persistent max-width="750px">
          <v-card>
            <v-card-title     :class="
                !!(isDark === 'dark') ? 'titulo-modal-dark' : 'titulo-modal'
              ">
              <span class="headline">
                {{ !modoEdicao ? "Adicionar " : "Editar " }} usuário
              </span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <form autocomplete="off">
                  <v-row class="mt-2">
                    <!-- <v-col cols="12">
                    <span class="headline">
                      {{ !modoEdicao ? "Adicionar " : "Editar " }} usuário
                    </span>
                  </v-col> -->
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="usuario.nome"
                        label="Nome"
                        autocomplete="off"
                        outlined
                        dense
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="usuario.email"
                        label="E-mail"
                        autocomplete="off"
                        type="email"
                        outlined
                        dense
                      >
                      </v-text-field>
                    </v-col>

                    <v-col cols="12" sm="">
                      <v-text-field
                        v-model="usuario.funcao"
                        label="Função"
                        autocomplete="off"
                        outlined
                        dense
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-autocomplete
                        :items="[
                          { id: 1, descricao: 'Administrador' },
                          { id: 2, descricao: 'Assessor' },
                        ]"
                        label="Perfil"
                        v-model="usuario.perfil"
                        item-text="descricao"
                        return-object
                        clearable
                        autocomplete="off"
                        outlined
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field
                        label="Senha"
                        v-model="usuario.senha"
                        :append-icon="value ? 'visibility_off' : 'visibility'"
                        @click:append="() => (value = !value)"
                        :type="value ? 'password' : 'text'"
                        :rules="[
                          (v) =>
                            v.trim().length >= 6 ||
                            'Senha deve ter mínimo de 6 caracteres',
                        ]"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </form>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                text
                class="btn-tertiary"
                :disabled="!this.usuario.modeloValido() || desabilitarBotoes"
                @click="salvar"
                >Salvar</v-btn
              >
              <v-btn
                :disabled="desabilitarBotoes"
                text
                class="btn-quaternary"
                @click="cancelar"
                >Cancelar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>

      <janela-confirmacao
        v-model="abrirJanelaConfirmacao"
        :callbackPrimario="callbackConfirmarExclusao"
        :mensagem="mensagemDeExclusao"
      />
    </div>
  </v-container>
</template>
<script>
import apiUsuario from "../api/usuario-api";
import UsuarioModel from "../model/usuario-model";
import mixinMensagen from "../mixin/mixin-mensagem";
import mixinGeral from "../mixin/mixin-geral";
import moment from "moment";

import mixpanel from "mixpanel-browser";
import JanelaConfirmacao from "@/components/JanelaConfirmacao";

export default {
  name: "controle-usuarios",
  mixins: [mixinMensagen, mixinGeral],
  components: {
    JanelaConfirmacao,
  },
  filters: {
    data(valor) {
      return moment(valor).format("DD/MM/YYYY");
    },
  },
  data() {
    return {
      value: "password",
      file: undefined,
      dialog: false,
      loading: true,
      modoEdicao: false,
      exibirFiltros: false,
      desabilitarBotoes: false,
      abrirJanelaConfirmacao: false,
      mensagemDeExclusao: "",
      search: "",
      colunas: [
        { text: "Código", value: "id" },
        { text: "", value: "foto", align: "rigth" },
        { text: "Nome", value: "nome", align: "left" },
        { text: "E-mail", value: "email", align: "left" },
        { text: "Perfil", value: "perfil.descricao" },
        { text: "Data de cadastro", value: "criadoEm", align: "center" },
        { text: "", value: "acoes", sortable: false },
      ],
      usuario: new UsuarioModel(),
      usuarios: [],
      opcoes: [],
      callbackConfirmarExclusao: () => {},
    };
  },
  mounted() {
    mixpanel.track("page_view");
    this.opcoes = this.montarOpcoes();
    apiUsuario
      .obterTodos()
      .then((response) => {
        this.usuarios = response.data.map((u) => new UsuarioModel(u));

        this.limparCampos();
        this.loading = false;
      })
      .catch((erro) => {
        this.dispararMensagem(erro.response.data.mensagem, "error");
        this.loading = false;
      });
  },
  methods: {
    montarOpcoes() {
      return [
        { id: 1, titulo: "Editar", metodo: this.editarUsuario },
        { id: 2, titulo: "Excluir", metodo: this.excluirUsuario },
      ];
    },

    salvar() {
      !this.modoEdicao ? this.cadastrarUsuario() : this.atualizarUsuario();
    },

    dataURLtoFile(dataurl, filename) {
      if (!dataurl) return undefined;

      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },

    editarUsuario(usuario) {
      this.modoEdicao = true;
      this.usuario = new UsuarioModel(usuario);
      this.file = this.dataURLtoFile(usuario.foto, "foto.png");
      this.dialog = true;

      mixpanel.track("click",{
        nome_botao: 'btn_editar_usuario',
      });
    },

    cancelar() {
      this.dialog = false;
      this.usuario = new UsuarioModel();
      this.file = undefined;
      this.modoEdicao = false;
    },
    selecionarImagem(fileStream) {
      var self = this;
      var file = fileStream;

      if (file.size > 50000) {
        this.dispararMensagem(
          `Selecione uma imagem com tamanho inferior a 50KB`,
          "error"
        );
        self.file = undefined;
        self.usuario.foto = undefined;
        return;
      }
      var imageType = /image.*/;

      if (file.type.match(imageType)) {
        var reader = new FileReader();

        reader.onload = function () {
          self.usuario.foto = reader.result;
        };

        reader.readAsDataURL(file);
      } else {
        self.file = undefined;
        self.usuario.foto = undefined;
        self.dispararMensagem(
          "Selecione um arquivo de imagem nos formatos .png ou .jpg",
          "error"
        );
      }
    },

    limparCampos() {
      this.usuario = new UsuarioModel();
    },

    cadastrarUsuario() {
      this.modoEdicao = false;
      this.desabilitarBotoes = true;
      let usuario = Object.assign({}, this.usuario);
      apiUsuario
        .cadastrar(usuario)
        .then((response) => {
          this.dispararMensagem(
            `Usuário cadastrado efetuado com sucesso!`,
            "success"
          );
          this.usuarios.push(new UsuarioModel(response.data));
          this.limparCampos();
          this.dialog = false;
          this.desabilitarBotoes = false;

          mixpanel.track("cadastrou_usuario",{
            usuario_id: response.data.id,
          });
        })
        .catch((erro) => {
          this.dispararMensagem(erro.response.data.mensagem, "error");
          this.desabilitarBotoes = false;
        });
    },
    atualizarUsuario() {
      this.modoEdicao = true;
      this.desabilitarBotoes = true;
      let usuario = Object.assign({}, this.usuario);
      apiUsuario
        .atualizar(usuario)
        .then((response) => {
          this.dispararMensagem(
            `${this.usuario.email} foi atualizado(a) com sucesso!`,
            "success"
          );
          this.atualizarUsuarioNaLista(new UsuarioModel(response.data));
          this.limparCampos();
          this.dialog = false;
          this.desabilitarBotoes = false;
          mixpanel.track("atualizou_usuario",{
            usuario_id: response.data.id,
          });
        })
        .catch((erro) => {
          this.dispararMensagem(erro.response.data.mensagem, "error");
          this.desabilitarBotoes = false;
        });
    },

    excluirUsuario(usuario) {
      this.usuario = new UsuarioModel(usuario);
      this.mensagemDeExclusao = `Deseja realmente excluir a usuario &nbsp; <strong>${usuario.email}</strong>?`;
      this.callbackConfirmarExclusao = this.deletarUsuario;
      this.abrirJanelaConfirmacao = true;
    },

    deletarUsuario() {
      apiUsuario
        .deletar(this.usuario)
        .then(() => {
          this.dispararMensagem(
            `O ${this.usuario.email} foi deletada com sucesso!`,
            "success"
          );
          this.atualizarUsuarioNaLista(this.usuario, true);
          mixpanel.track("excluiu_usuario",{
            usuario_id: this.usuario.id,
          });
        })
        .catch((erro) =>
          this.dispararMensagem(erro.response.data.mensagem, "error")
        )
        .finally((this.abrirJanelaConfirmacao = false));
    },

    atualizarUsuarioNaLista(usuario, deletarDaLista) {
      var index = this.usuarios
        .map((p) => parseInt(p.id))
        .indexOf(parseInt(usuario.id));
      !deletarDaLista
        ? this.usuarios.splice(index, 1, usuario)
        : this.usuarios.splice(index, 1);
    },
  },
};
</script>

<style scoped>
.tamanho-tab-cadastro {
  min-height: 300px;
}
.alinhamento {
  text-align: end;
}
form {
  width: 100%;
}

.mensagem-validacao {
  color: red;
}

.endereco-expansivel {
  border: 1px solid #ddd;
  width: 100%;
  margin: 2px;
  background: #fff;
}
.totalizador {
  padding: 6px;
  height: 21px;
}
</style>
