<template>
  <v-container class="mt-6">
    <div v-if="loading" class="loading text-center">
      <h1 class="primary--text">Recuperando as informações...</h1>
      <v-progress-circular
        :size="120"
        :width="8"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>

    <v-row v-if="!loading" class="mb-5">
      <v-col class="my-5" cols="12" xs="12" sm="12">
        <h1 class="titulo mb-0">Painel de pessoas</h1>
        <v-divider></v-divider>
      </v-col>

      <!-- Periodo para escolher -->
      <v-col cols="12" class="py-0">
        <v-row class="mt-1">
          <v-col cols="12" sm="4">
            <div v-if="!loading" class="md-layout-item px-0">
              <stats-card
                :class="!!(isDark === 'dark') ? 'dark' : ''"
                data-background-color="grey"
              >
                <template slot="header">
                  <users-icon size="3x" class="custom-class"></users-icon>
                </template>

                <template slot="content">
                  <h3 class="category">Total de pessoas</h3>
                  <h3 class="title category">
                    {{ metadadosPessoa.totalDePessoas }}
                  </h3>
                </template>
              </stats-card>
            </div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="pb-0 pt-0" cols="12" sm="2">
            <v-autocomplete
              outlined
              small
              return-object
              item-text="descricao"
              v-model="periodoEscolhido"
              label="Escolher período"
              :items="periodosDashBoard"
              dense
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-col>

      <!-- Gráfico por tipos de pessoas -->
      <v-col class="pa-0" cols="12" sm="6">
        <div class="sem-dados" v-if="!metadadosPessoa.totalDePessoas">
          <span class="mensagem-sem-dados"
            >Você não tem pessoas cadastradas neste período</span
          >
        </div>
        <div
          class="espacamento-com-borda"
          :class="!!(isDark === 'dark') ? 'dark' : ''"
        >
          <Doughnut
            ref="chartDoughnutPorTipo"
            :chart-data="dataPorTipo"
            :options="opticoesPorTipoPessoa"
          ></Doughnut>
        </div>
      </v-col>

      <!-- Gráfico por faixa etária -->

      <v-col class="pa-0" cols="12" sm="6">
        <div class="sem-dados" v-if="!metadadosPessoa.totalDePessoas">
          <span class="mensagem-sem-dados"
            >Você não tem pessoas cadastradas neste período</span
          >
        </div>
        <div
          class="espacamento-com-borda"
          :class="!!(isDark === 'dark') ? 'dark' : ''"
        >
          <BarChart
            ref="chartPorFaixaEtaria"
            :chart-data="dataPorFaixaEtaria"
            :options="optionsPolarPorFaixaEtaria"
          ></BarChart>
        </div>
      </v-col>

      <!-- Gráfico por cidade-->
      <v-col class="pa-0" cols="12" sm="6">
        <div class="sem-dados" v-if="!metadadosPessoa.totalDePessoas">
          <span class="mensagem-sem-dados"
            >Você não tem pessoas cadastradas neste período</span
          >
        </div>
        <div
          class="espacamento-com-borda"
          :class="!!(isDark === 'dark') ? 'dark' : ''"
        >
          <BarChart
            ref="chartPorCidade"
            :chart-data="dataPorCidade"
            :options="optionsBarPorCidade"
          ></BarChart>
        </div>
      </v-col>

      <!-- Gráfico por sexo-->
      <v-col class="pa-0" cols="12" sm="6">
        <div class="sem-dados" v-if="!metadadosPessoa.totalDePessoas">
          <span class="mensagem-sem-dados"
            >Você não tem pessoas cadastradas neste período</span
          >
        </div>
        <div
          class="espacamento-com-borda"
          :class="!!(isDark === 'dark') ? 'dark' : ''"
        >
          <Doughnut
            ref="chartPorSexo"
            :chart-data="dataPorSexo"
            :options="optionsPiePortotalPessoasSexo"
          ></Doughnut>
        </div>
      </v-col>

      <!-- Gráfico por bairro-->
      <v-col class="pa-0" cols="12" sm="12">
        <div
          class="espacamento-com-borda"
          :class="!!(isDark === 'dark') ? 'dark' : ''"
        >
          <BarChart
            ref="chartPorBairro"
            :chart-data="dataPorBairro"
            :options="optionsBarPorBairro"
          ></BarChart>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import apiDashboard from "@/api/dashboard-api";
import { PERIODO_DASHBOARD } from "@/constants/configuracoes-constants";
import Doughnut from "@/components/graficos/Doughnut.vue";
import { StatsCard } from "@/components";
// import PieChart from "@/components/graficos/PieChart.vue";
// import PolarChart from "@/components/graficos/PolarChart.vue";
import BarChart from "@/components/graficos/BarChart.vue";
import mixinGeral from "@/mixin/mixin-geral";
import MetadadosPessoa from "@/model/metadados-pessoa";
import geralUtil from "@/utils/geral";
import mixpanel from 'mixpanel-browser'
export default {
  name: "DashBoard",
  mixins: [mixinGeral],
  components: {
    StatsCard,
    Doughnut,
    // PieChart,
    // PolarChart,
    BarChart,
  },

  data() {
    return {
      styles: { with: "100%" },
      loading: true,
      metadadosPessoa: new MetadadosPessoa(),
      periodosDashBoard: PERIODO_DASHBOARD,
      periodoEscolhido: PERIODO_DASHBOARD[4],
      opticoesPorTipoPessoa: {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          animateRotate: false,
        },
        legend: {
          display: true,
        },
        title: {
          display: true,
          text: "Percentual por tipo",
        },
        plugins: {
          datalabels: {
            textAlign: "center",
            font: {
              weight: "bold",
              size: 11,
            },
            formatter: (value, ctx) => {
              let sum = 0;
              let dataArr = ctx.chart.data.datasets[0].data;
              dataArr.map((data) => {
                sum += parseFloat(data);
              });
              let percentage = ((value * 100) / sum).toFixed(2) + "%";
              return percentage;
            },
            color: "white",
          },
        },
      },
      optionsPiePortotalPessoasSexo: {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          animateRotate: false,
        },
        legend: {
          display: true,
        },
        title: {
          display: true,
          text: "Percentual por sexo",
        },
        scales: {
          yAxes: [
            {
              display: true,
              ticks: {
                beginAtZero: true,
                steps: 5,
                stepValue: 1,
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            textAlign: "center",
            font: {
              weight: "bold",
              size: 11,
            },
            formatter: (value, ctx) => {
              let sum = 0;
              let dataArr = ctx.chart.data.datasets[0].data;
              dataArr.map((data) => {
                sum += parseFloat(data);
              });
              let percentage = ((value * 100) / sum).toFixed(2) + "%";
              return percentage;
            },
            color: "white",
          },
        },
      },
      optionsPolarPorFaixaEtaria: {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          animateRotate: true,
        },
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: "Quantidade por faixa etária",
        },
        scales: {
          yAxes: [
            {
              display: true,
              ticks: {
                beginAtZero: true,
                steps: 2,
                stepValue: 1,
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            textAlign: "center",
            font: {
              weight: "bold",
              size: 12,
            },
            color: "white",
          },
        },
      },
      optionsBarPorUfs: {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          animateRotate: true,
        },
        legend: {
          display: true,
        },
        title: {
          display: true,
          text: "Pessoas por UFs",
        },
        plugins: {
          datalabels: {
            textAlign: "center",
            font: {
              weight: "bold",
              size: 12,
            },
            color: "white",
          },
        },
      },
      optionsBarPorCidade: {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          animateRotate: true,
        },
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: "Pessoas por cidade",
        },
        scales: {
          yAxes: [
            {
              display: true,
              ticks: {
                beginAtZero: true,
                steps: 5,
                stepValue: 1,
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            textAlign: "center",
            font: {
              weight: "bold",
              size: 12,
            },
            color: "white",
          },
        },
      },
      optionsBarPorBairro: {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          animateRotate: true,
        },
        // height: '500px',
        // width: '100%',
        // position: 'relative',
        legend: {
          display: false,
          labels: {
            color: "rgb(255, 99, 132)",
          },
        },
        title: {
          display: true,
          text: "Pessoas por bairro",
        },
        scales: {
          yAxes: [
            {
              display: true,
              ticks: {
                beginAtZero: true,
                steps: 5,
                stepValue: 1,
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            textAlign: "center",
            font: {
              weight: "bold",
              size: 12,
            },
            color: "white",
          },
        },
      },
      dataPorTipo: {
        labels: [],
        datasets: [
          {
            label: "Pessoas",
            borderColor: "transparent",
            backgroundColor: ["#1ABC9C", "#E74C3C", "#CFD8DC"],
            data: [0],
          },
        ],
      },
      dataPorSexo: {
        labels: [],
        datasets: [
          {
            label: "Atendimentos",
            borderColor: "transparent",
            backgroundColor: ["#EF9A9A", "#90CAF9", "#A5D6A7", "#FFE0B2"],
            data: [0],
          },
        ],
      },
      dataPorFaixaEtaria: {
        labels: [
          "18 ou menos",
          "19 a 25 anos",
          "26 a 40 anos",
          "41 a 60 anos",
          "60 ou mais",
          "Não informado",
        ],
        datasets: [
          {
            borderColor: "transparent",
            backgroundColor: [
              "#EF9A91",
              "#F48FB1",
              "#CE93D1",
              "#B39DD1",
              "#9FA8D1",
              "#90CAF1",
            ],
            data: [0],
          },
        ],
      },
      dataPorUf: {
        labels: [],
        datasets: [
          {
            label: "Pessoas por UF",
            borderColor: "transparent",
            backgroundColor: [
              "#E91E63",
              "#2196F3",
              "#263238",
              "#009688",
              "#5E35B1",
              "#2E7D32",
              "#C62828",
              "#E040FB",
              "#F57F17",
              "#26A69A",
              "#2979FF",
              "#FFEB3B",
              "#FF1744",
              "#3E2723",
              "#795548",
            ],
            data: [0],
          },
        ],
      },
      dataPorCidade: {
        labels: [],
        datasets: [
          {
            label: "Pessoas por cidade",
            borderColor: "transparent",
            backgroundColor: [
              "#EF9A91",
              "#F48FB1",
              "#CE93D1",
              "#B39DD1",
              "#9FA8D1",
              "#90CAF1",
              "#81D4F1",
              "#80DEE1",
              "#80CBC1",
              "#A5D6A1",
              "#C5E1A1",
              "#E6EE91",
              "#FFF591",
              "#FFE081",
              "#FFCC81",
              "#FFAB91",
              "#BCAAA1",
              "#EEEEE1",
              "#B0BEC1",
              "#EF9A91",
              "#F48FB1",
              "#CE93D1",
              "#B39DD1",
              "#9FA8D1",
              "#90CAF1",
              "#81D4F1",
              "#80DEE1",
              "#80CBC1",
              "#A5D6A1",
              "#C5E1A1",
              "#E6EE91",
              "#FFF591",
              "#FFE081",
              "#FFCC81",
              "#FFAB91",
              "#BCAAA1",
              "#EEEEE1",
              "#B0BEC1",
            ],
            data: [0],
          },
        ],
      },
      dataPorBairro: {
        labels: [],
        datasets: [
          {
            label: "Pessoas por bairro",
            borderColor: "transparent",
            backgroundColor: [
              "#EF9A91",
              "#F48FB1",
              "#CE93D1",
              "#B39DD1",
              "#9FA8D1",
              "#90CAF1",
              "#81D4F1",
              "#80DEE1",
              "#80CBC1",
              "#A5D6A1",
              "#C5E1A1",
              "#E6EE91",
              "#FFF591",
              "#FFE081",
              "#FFCC81",
              "#FFAB91",
              "#BCAAA1",
              "#EEEEE1",
              "#B0BEC1",
              "#EF9A91",
              "#F48FB1",
              "#CE93D1",
              "#B39DD1",
              "#9FA8D1",
              "#90CAF1",
              "#81D4F1",
              "#80DEE1",
              "#80CBC1",
              "#A5D6A1",
              "#C5E1A1",
              "#E6EE91",
              "#FFF591",
              "#FFE081",
              "#FFCC81",
              "#FFAB91",
              "#BCAAA1",
              "#EEEEE1",
              "#B0BEC1",
              "#1ABC9C",
              "#E74C3C",
              "#CFD8DC",
              "#EF9A91",
              "#F48FB1",
              "#CE93D1",
              "#B39DD1",
              "#9FA8D1",
              "#90CAF1",
              "#EF9A9A",
              "#90CAF9",
              "#A5D6A7",
              "#FFE0B2",
              "#EF9A91",
              "#F48FB1",
              "#CE93D1",
              "#B39DD1",
              "#9FA8D1",
              "#90CAF1",
              "#81D4F1",
              "#80DEE1",
              "#80CBC1",
              "#A5D6A1",
              "#C5E1A1",
              "#E6EE91",
              "#FFF591",
              "#FFE081",
              "#FFCC81",
              "#FFAB91",
              "#BCAAA1",
              "#EEEEE1",
              "#B0BEC1",
              "#EF9A91",
              "#F48FB1",
              "#CE93D1",
              "#B39DD1",
              "#9FA8D1",
              "#90CAF1",
              "#81D4F1",
              "#80DEE1",
              "#80CBC1",
              "#A5D6A1",
              "#C5E1A1",
              "#E6EE91",
              "#FFF591",
              "#FFE081",
              "#FFCC81",
              "#FFAB91",
              "#BCAAA1",
              "#EEEEE1",
              "#B0BEC1",
            ],
            data: [0],
          },
        ],
      },
    };
  },
  computed: {
    temValorParaApresentar() {
      return !!(
        this.metadadosPessoa.tiposDePessoas.eleitores ||
        this.metadadosPessoa.tiposDePessoas.simpatizantes ||
        this.metadadosPessoa.tiposDePessoas.naoInformados
      );
    },
  },
  watch: {
    periodoEscolhido() {
      mixpanel.track('click', {
        nome_botao: 'selecionar_periodo',
        periodo_escolhido: this.periodoEscolhido.descricao
      })
      this.obterMetadados();
    },
  },
  mounted() {
    this.obterMetadados();
    mixpanel.track("page_view");
  },
  methods: {
    updateChart() {
      this.$refs.chartDoughnutPorTipo.update();
      this.$refs.chartPorFaixaEtaria.update();
      this.$refs.chartPorSexo.update();
      this.$refs.chartPorCidade.update();
      this.$refs.chartPorBairro.update();
    },

    atualizarPorTipo() {
      this.dataPorTipo.datasets[0].data = [];
      this.dataPorTipo.labels = [];

      this.metadadosPessoa.tiposDePessoas.map((e) => {
        this.dataPorTipo.labels.push(e.tipoDePessoa);
        this.dataPorTipo.datasets[0].data.push(parseFloat(e.pessoas.length));
      });
    },

    atualizarPorSexo() {
      this.dataPorSexo.datasets[0].data = [];
      this.dataPorSexo.labels = [];

      this.metadadosPessoa.totalPessoasSexo.map((e) => {
        this.dataPorSexo.labels.push(e.sexo);
        this.dataPorSexo.datasets[0].data.push(parseFloat(e.pessoas.length));
      });
    },

    atualizarPorFaixaEtaria() {
      this.dataPorFaixaEtaria.datasets[0].data.splice(
        0,
        1,
        parseFloat(
          this.metadadosPessoa.totalPorFaixasDeIdade
            .pessoasComIdadeInferiorOuIgualADezoitoAnos.length
        )
      );

      this.dataPorFaixaEtaria.datasets[0].data.splice(
        1,
        1,
        parseFloat(
          this.metadadosPessoa.totalPorFaixasDeIdade
            .pessoasComIdadeEntreDezenoveEVinteCincoAnos.length
        )
      );

      this.dataPorFaixaEtaria.datasets[0].data.splice(
        2,
        1,
        parseFloat(
          this.metadadosPessoa.totalPorFaixasDeIdade
            .pessoasComIdadeEntreVinteSeisEQuarentaAnos.length
        )
      );

      this.dataPorFaixaEtaria.datasets[0].data.splice(
        3,
        1,
        parseFloat(
          this.metadadosPessoa.totalPorFaixasDeIdade
            .pessoasComIdadeEntreQuarentaEUmSessentaAnos.length
        )
      );

      this.dataPorFaixaEtaria.datasets[0].data.splice(
        4,
        1,
        parseFloat(
          this.metadadosPessoa.totalPorFaixasDeIdade
            .pessoasComIdadeSuperioresASessentaAnos.length
        )
      );

      this.dataPorFaixaEtaria.datasets[0].data.splice(
        5,
        1,
        parseFloat(
          this.metadadosPessoa.totalPorFaixasDeIdade
            .pessoasSemDataDeNascimentoCadastrada.length
        )
      );
    },

    atualizarPorUf() {
      this.dataPorUf.datasets[0].data = [];
      this.dataPorUf.labels = [];

      this.metadadosPessoa.totalPorEndereco.porUfs.map((e) => {
        this.dataPorUf.labels.push(
          geralUtil.converterCadaLetraDeCadaPalavraParaUpperCase(e.uf)
        );
        this.dataPorUf.datasets[0].data.push(parseFloat(e.pessoas.length));
      });
    },

    atualizarPorCidade() {
      this.dataPorCidade.datasets[0].data = [];
      this.dataPorCidade.labels = [];

      this.metadadosPessoa.totalPorEndereco.porCidade.map((e) => {
        this.dataPorCidade.labels.push(
          geralUtil.converterCadaLetraDeCadaPalavraParaUpperCase(e.cidade)
        );
        this.dataPorCidade.datasets[0].data.push(parseFloat(e.pessoas.length));
      });
    },

    atualizarPorBairro() {
      this.dataPorBairro.datasets[0].data = [];
      this.dataPorBairro.labels = [];

      this.metadadosPessoa.totalPorEndereco.porBairro.map((e) => {
        this.dataPorBairro.labels.push(
          geralUtil.converterCadaLetraDeCadaPalavraParaUpperCase(e.bairro)
        );
        this.dataPorBairro.datasets[0].data.push(parseFloat(e.pessoas.length));
      });
    },

    updateAmount() {
      this.atualizarPorTipo();
      this.atualizarPorSexo();
      this.atualizarPorFaixaEtaria();
      this.atualizarPorUf();
      this.atualizarPorCidade();
      this.atualizarPorBairro();

      this.updateChart();
    },
    obterMetadados() {
      this.loading = true;
      apiDashboard
        .obterTotalizadoresDePessoas(this.periodoEscolhido.valor)
        .then((response) => {
          this.metadadosPessoa = new MetadadosPessoa(response.data);
          this.loading = false;
          this.updateAmount();
          mixpanel.people.set({
            $qt_pessoas: this.metadadosPessoa.totalDePessoas
          })
        })
        .catch((erro) => {
          this.dispararMensagem(erro.response.data.mensagem, "error");
          this.loading = false;
        })
        .finally((this.loading = false));
    },
  },
};
</script>
<style scoped>
.quantidade-votos {
  font-size: 18px;
  font-weight: 200;
}

.category {
  font-size: 1.2em;
}
.md-card {
  box-shadow: 0px 1px 10px 0px rgb(0 0 0 / 10%);
  border-radius: 8px;
}

.sem-dados {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}

.mensagem-sem-dados {
  display: flex;
  position: absolute;
  margin-top: 110px;
  font-size: 16px;
  font-weight: 600;
  color: #484848;
}

.espacamento-com-borda {
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 55px;
  margin: 15px;
  box-shadow: 0px 1px 10px 0px rgb(0 0 0 / 10%);
  background-color: #fff;
}
.espacamento-com-borda.dark,
.md-card.dark {
  background: #1b1b1b;
}

.totalizador-valor {
  font-size: 22px;
  font-weight: 600;
  color: #686868;
}
</style>
