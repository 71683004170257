import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import pt from 'vuetify/src/locale/pt.ts';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
    },
    lang: {
        locales: { pt },
        current: 'pt',
    },
    theme: {
        themes: {
          light: {
            primary:'#00A82D', //'#005800',//'#00A82D',//'#165091', //'#154C72',
            secondary: '#3AAA35',
            accent: '#82B1FF',
            info: '#2196F3',
            warning:"#F7BB07",
            error: '#D53343',
            success: '#27A243',
          },
          dark: {
            primary:'#00A82D', //'#005800',//'#00A82D',//'#165091', //'#154C72',
            secondary: '#3AAA35',
            accent: '#82B1FF',
            info: '#2196F3',
            warning:"#F7BB07",
            error: '#D53343',
            success: '#27A243',
          },
        }
    }
});
