import axios from 'axios';
import utilsStorage from '../utils/storage';
import { URLS } from '../constants/configuracoes-constants';

const api = axios.create({
   baseURL: URLS.BASE
})

api.interceptors.request.use(async (config) => {
    var token = await utilsStorage.obterTokenNaStorage();
    config.headers.Authorization = token;
    return config;
});

api.interceptors.response.use((response) => {
    if(response.status === 401){
        utilsStorage.removerAutenticacao();
        
        if(response.data.name != "Autenticacao"){
            setTimeout(() =>{
                window.location.href = '/login';
            }, 2000)
        }   
    }
    if(response.status === 400 || 
       response.status === 403 || 
       response.status === 404 ||
       response.status === 405 ||
       response.status === 500){
        return Promise.reject(response);
    }
    
    return response;
},(error) => {
    if(error.response.status === 401){
        utilsStorage.removerAutenticacao();
        
        if(error.response.data.name != "Autenticacao"){
            setTimeout(() =>{
                window.location.href = '/login';
            }, 2000)
        }       
    }
    return Promise.reject(error);
});

export default api;