'use strict';

import { FILTROS_PESQUISA } from "@/constants/configuracoes-constants";
// import PessoaModel from "@/models/geral/pessoa/pessoa-model";

// class MetadadosFiltroModel {
//   constructor(obj){
//     obj = obj || {};
//     this.cliente = obj.cliente && new PessoaModel(obj.cliente);
//     this.periodoEmissao = obj.periodoEmissao;
 
//   }
// }
export default class FiltrosPesquisaAtendimentoModel {
  constructor(obj) {
    obj = obj || {};
    this.codigo = obj.codigo;
    this.dataInicialCadastro  = obj.dataInicialCadastro;
    this.dataFinalCadastro = obj.dataFinalCadastro;
    this.idSolicitante = obj.idSolicitante;
    this.idResponsavel = obj.idResponsavel;
    this.dataInicialResolucao  = obj.dataInicialResolucao;
    this.dataFinalResolucao = obj.dataFinalResolucao;
    this.listaDeStatus = obj.listaDeStatus || [];
    this.listaDeCategorias =obj. listaDeCategorias || [];
    // this.idEmpresa = obj.idEmpresa;
    // this.idCliente = obj.idCliente;
    // this.listaDeStatus = obj.listaDeStatus || [];
    // this.codigo = obj.codigo;
    // this.dataEmissaoInicial = obj.dataEmissaoInicial;
    // this.dataEmissaoFinal = obj.dataEmissaoFinal;
    // this.identificadorOrcamento = obj.identificadorOrcamento;
    // this.dataInicialReferencia = obj.dataInicialReferencia;
    // this.dataFinalReferencia = obj.dataFinalReferencia;
    // this.dataInicioContrato = obj.dataInicioContrato;
    // this.dataTerminoContrato = obj.dataTerminoContrato;
    // this.buscarOrcamentos = obj.buscarOrcamentos;
    // this.buscarLocacoes = obj.buscarLocacoes;
    // this.buscarLocacoesMovimentadas = obj.buscarLocacoesMovimentadas;
    // this.metadados =  new MetadadosFiltroModel(obj.metadados);
  }

  filtroEstaoaPreenchidosParaPesquisa(){
    return !!(this.codigo || 
              this.dataInicialCadastro ||
              this.dataFinalCadastro ||
              this.idSolicitante ||
              this.idResponsavel ||
              this.dataInicialResolucao ||
              this.dataFinalResolucao || 
              this.listaDeStatus);
  }
  gruposDeFiltrosAtivos() {
    let filtrosAtivos = [];

    this.codigo && filtrosAtivos.push(FILTROS_PESQUISA.CODIGO);
    (this.listaDeStatus.length > 0) && filtrosAtivos.push(FILTROS_PESQUISA.STATUS);
    (this.dataInicialCadastro || this.dataFinalCadastro) && filtrosAtivos.push(FILTROS_PESQUISA.DATA_CADASTRO);
    (this.dataInicialResolucao || this.dataFinalResolucao) && filtrosAtivos.push(FILTROS_PESQUISA.DATA_RESOLUCAO);

    return filtrosAtivos;
  }

  removerFiltrosPorGrupo(grupo) {
    switch(grupo){
      case FILTROS_PESQUISA.CODIGO:
        this.codigo = undefined;
      break;
      case FILTROS_PESQUISA.STATUS:
        this.listaDeStatus = [];
      break;
      case FILTROS_PESQUISA.DATA_CADASTRO:
        this.dataInicialCadastro = undefined;
        this.dataFinalCadastro = undefined;
        // this.metadados.periodoEmissao = undefined;
      break;
  
      case FILTROS_PESQUISA.DATA_RESOLUCAO:
        this.dataInicialResolucao= undefined;
        this.dataFinalResolucao = undefined;
      break;
    }
  }
}