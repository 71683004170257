import PessoaModel from './pessoa-model';
import UsuarioModel from './usuario-model';
import StatusModel from './status-model';
import CategoriaModel from './categoria-model';
import AnexoModel from './anexo-model';
import ParecerModel from './parecer-model';


export default class AtendimentoModel {
    constructor(obj) {
        obj = obj || {};
        this.id = obj.id;
        this.resumo = obj.resumo;
        this.solicitante = new PessoaModel(obj.solicitante);
        this.responsavel = new PessoaModel(obj.responsavel);
        this.status = new StatusModel(obj.status);
        this.usuario = new UsuarioModel(obj.usuario);
        this.categoria = new CategoriaModel(obj.categoria);
        this.solicitacao = obj.solicitacao;
        this.dataDeResolucao = obj.dataDeResolucao;
        this.protocolo = obj.protocolo;
        this.pareceres =  Array.isArray(obj.pareceres) && obj.pareceres.length && obj.pareceres.map(p => new ParecerModel(p)) || [];
        this.observacao = obj.observacao;
        this.anexos = Array.isArray(obj.anexos) && obj.anexos.length && obj.anexos.map(a => new AnexoModel(a)) || [];
        this.idCliente = obj.idCliente;
        this.criadoEm = obj.criadoEm;
        this.atualizadoEm = obj.atualizadoEm;
    }
    modeloValido(){
        return !!(this.nome && this.cpf && this.email && this.celular);
    }
}
