import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes';

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkExactActiveClass: "nav-item active"
});

router.beforeEach((to, from, next) => {
 
  if(to.name == "Assinatura" || to.name == "Login"){
    next();
  } else if(to.matched.some(record => record.meta.requireAuth)){

    if(localStorage.getItem('token') == null){
      next({
        path:'/login',
        params: { nextUrl: to.fullPath }
      });
    }else{
      // var usuario = JSON.parse(atob(localStorage.getItem('usuario-gpf')) || {});

      // if(to.matched.some(record => record.meta.is_admin)) {
      //   if(usuario.perfil.id == 1){
      //       next()
      //   }
      //   else{
      //       next({ name: 'Dashboard'})
      //   }
      // }else {
      //     next()
      // }
      next();
    }
  }else{
    if(localStorage.getItem('token') == null){
      next();
    }else{
      next({name: 'Dashboard'})
    }
  } 
});



export default router
