
import api from './api'

function obterTodos(){
    return  new Promise((resolve, reject) =>{
        return api.get('/atendimentos')
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
}

function obterPorId(id){
    return  new Promise((resolve, reject) =>{
        return api.get('/atendimentos/' + id)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
}
function obterPorFiltros(filtros){
    let filtroDataCadastro = `dataInicialCadastro=${filtros.dataInicialCadastro || ''}&dataFinalCadastro=${filtros.dataFinalCadastro || ''}`;
    let filtroDataResolucao = `dataInicialResolucao=${filtros.dataInicialResolucao || ''}&dataFinalResolucao=${filtros.dataFinalResolucao || ''}`;
    let filtroPorStatus =`${filtros.listaDeStatus.map(e =>`&listaDeStatus=${e}`).toString().replaceAll(',','')}`;
    let filtroPorSolicitante = `idSolicitante=${filtros.idSolicitante || ''}`;
    let filtroPorCategorias =`${filtros.listaDeCategorias.map(c =>`&listaDeCategorias=${c}`).toString().replaceAll(',','')}`;

    let query = `idAtendimento=${filtros.codigo || ''}&${filtroPorSolicitante}&${filtroDataCadastro}&${filtroDataResolucao}${filtroPorStatus}${filtroPorCategorias}`;
    return  new Promise((resolve, reject) =>{
        return api.get(`/atendimentos?${query}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
}

function cadastrar(atentimento){
    atentimento = atentimento || {};
    return  new Promise((resolve, reject) =>{
      return api.post('/atendimentos', atentimento)
      .then(response => resolve(response))
      .catch(error => reject(error))
    });
}

function atualizar(atentimento){
    atentimento = atentimento || {};
    return  new Promise((resolve, reject) =>{
        return api.put(`/atendimentos/${atentimento.id}`, atentimento)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
}

function cadastrarParecer(parecer){
    parecer = parecer || {};
    return  new Promise((resolve, reject) =>{
        return api.post(`/pareceres/atendimento/${parecer.idAtendimento}`, parecer)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
}

export default {
    obterPorId,
    obterTodos,
    cadastrar,
    atualizar,
    cadastrarParecer,
    obterPorFiltros
}