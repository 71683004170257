import EnderecoModel from './endereco-model';


export default class PessoaModel {
    constructor(obj) {
        obj = obj || {};
        this.id = obj.id;
        this.nome = obj.nome;
        this.codigoNome = obj.id && `${obj.id} - ${obj.nome}` || undefined;
        this.apelido = obj.apelido;
        this.sexo = obj.sexo;
        this.tipoDePessoa = obj.tipoDePessoa;
        this.dataDeNascimento = obj.dataDeNascimento;
        this.estadoCivil = obj.estadoCivil;
        this.quantidadeDeFilhos = obj.quantidadeDeFilhos;
        this.religiao = obj.religiao;
        this.celular = obj.celular;
        this.fixo = obj.fixo;
        this.telefoneDoTrabalho = obj.telefoneDoTrabalho;
        this.email = obj.email;
        this.whatsapp = obj.whatsapp;
        this.redeSocialPrincipal = obj.redeSocialPrincipal;
        this.membroDaEquipe = obj.membroDaEquipe || false;
        this.profissao = obj.profissao;
        this.ocupacao = obj.ocupacao;
        this.partido = obj.partido;
        this.endereco = new EnderecoModel(obj.endereco);
        this.pessoaJuridica = obj.pessoaJuridica;
        this.cpfOuCnpj = obj.cpfOuCnpj;
        this.rg = obj.rg;
        this.cnh = obj.cnh;
        this.tipoCNH = obj.tipoCNH;
        this.cartaoSUS = obj.cartaoSUS;
        this.cartaoCidadao = obj.cartaoCidadao;
        this.tituloDeEleitor = obj.tituloDeEleitor;
        this.zonaEleitoral = obj.zonaEleitoral;
        this.secaoEleitoral = obj.secaoEleitoral;
        this.carteiraDeTrabalho = obj.carteiraDeTrabalho;
        this.observacao = obj.observacao;
        this.totalAtendimentos = obj.totalAtendimentos
        this.idCliente = obj.idCliente;
        this.foto = obj.foto;
        this.criadoEm = obj.criadoEm;
        this.atualizadoEm = obj.atualizadoEm;
        
    }
    modeloValido(){
        return !!(this.nome);
    }
}
