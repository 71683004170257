import ControleDeAtendimentos from "../views/ControleDeAtendimentos.vue";
import Login from "../views/Login.vue";
import Assinatura from "../views/Assinatura.vue";
// import Dashboard from "../views/Dashboards/Dashboard.vue";
import DashBoardPessoa from "../views/Dashboards/DashBoardPessoas.vue";
import DashBoardAtendimento from "../views/Dashboards/DashBoardAtendimentos.vue";
import Agenda from "../views/Agenda.vue";
import ControleDePessoas from "../views/ControleDePessoas.vue";
import ControleDeUsuarios from "../views/ControleDeUsuarios.vue";
import ControleDeTemplates from "../views/ControleDeTemplates.vue";
import ModeloTemplate from "../views/ModeloTemplate.vue";
import ControleDeOficios from "../views/ControleDeOficios.vue";
import ControleDeMocoes from "../views/ControleDeMocoes.vue";
import ControleDeCelulas from "../views/ControleDeCelulas.vue";
import { ClockIcon } from 'vue-feather-icons'
import ControleDeVisitas from "../views/ControleDeVisitas.vue"


const routes = [
  {
    path: "/",
    name: "DashboardPessoa",
    component: DashBoardPessoa,
    title: "Painel de pessoas",
    icon: "mdi-view-dashboard",
    meta: { requireAuth: true, is_admin: false },
  },
  {
    path: "/painel-atendimentos",
    name: "DashboardAtendimento",
    component: DashBoardAtendimento,
    title: "Painel de atendimentos",
    icon: "mdi-view-dashboard",
    meta: { requireAuth: true, is_admin: false },
  },
  {
    path: "/controle-pessoas",
    name: "ControlePessoas",
    component: ControleDePessoas,
    title: "Pessoas",
    // icon: "mdi-account-group",
    icon: ClockIcon,
    // icon: "mdi-account-circle",
    meta: { requireAuth: true, is_admin: false },
  },
  {
    path: "/atendimentos",
    name: "ControleAtendimentos",
    component: ControleDeAtendimentos,
    title: "Atendimentos",
    icon: "mdi-chart-box",
    meta: { requireAuth: true, is_admin: false },
  },
  {
    path: "/agenda",
    name: "Agenda",
    component: Agenda,
    title: "Agenda",
    icon: "mdi-calendar",
    meta: { requireAuth: true, is_admin: true },
  },
  {
    path: "/controle-de-celulas",
    name: "ControleDeCelulas",
    component: ControleDeCelulas,
    title: "Celulas",
    icon: "mdi-file-multiple",
    meta: { requireAuth: true, is_admin: true },
  },
  {
    path: "/controle-templates",
    name: "ControleDeTemplates",
    component: ControleDeTemplates,
    title: "Templates",
    icon: "mdi-file-multiple",
    meta: { requireAuth: true, is_admin: true },
  },
  {
    path: "/controle-templates/novo-modelo",
    name: "ModeloTemplate",
    component:  ModeloTemplate,
    title: "Modelo Templates",
    icon: "mdi-file-multiple",
    meta: { requireAuth: true, is_admin: true },
  },
  {
    path: "/controle-templates/:idTemplate",
    name: "EdicaoTemplate",
    component:  ModeloTemplate,
    title: "Edicao Templates",
    icon: "mdi-file-multiple",
    meta: { requireAuth: true, is_admin: true },
  },
  {
    path: "/controle-oficios",
    name: "ControleDeOficios",
    component: ControleDeOficios,
    title: "Oficios",
    icon: "mdi-file-settings",
    meta: { requireAuth: true, is_admin: true },
  },
  {
    path: "/controle-mocoes",
    name: "ControleDeMocoes",
    component: ControleDeMocoes,
    title: "Moções",
    icon: "mdi-file-star",
    meta: { requireAuth: true, is_admin: true },
  },
  {
    path: "/controle-usuarios",
    name: "ControleDeUsuarios",
    component: ControleDeUsuarios,
    title: "Usuarios",
    icon: "mdi-account-box",
    meta: { requireAuth: true, is_admin: true },
  },
  {
    path: "/visitas",
    name: "ControleDeVisitas",
    component: ControleDeVisitas,
    title: "Visitas",
    icon: "mdi-eye",
    meta: { requireAuth: true, is_admin: false },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    title: "login",
    icon: "",
    meta: {},
  },

  {
    path: "/assinatura/nova-assinatura",
    name: "Assinatura",
    component: Assinatura,
    title: "Assinatura",
    icon: "",
    meta: {},
  },  
];

export default routes;
