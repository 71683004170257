import { render, staticRenderFns } from "./MenuStatic.vue?vue&type=template&id=194a0d66&scoped=true&"
import script from "./MenuStatic.vue?vue&type=script&lang=js&"
export * from "./MenuStatic.vue?vue&type=script&lang=js&"
import style0 from "./MenuStatic.vue?vue&type=style&index=0&id=194a0d66&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "194a0d66",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VChip,VDivider,VList,VListItem,VListItemAction,VListItemContent,VListItemGroup,VListItemTitle})
