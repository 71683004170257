import api from './api';

function obterTodos(){
    return new Promise((resolve, reject) => {
        return api.get('/agendamento')
            .then(response => resolve(response))
            .catch(error => reject(error))
    });
}
function obterTiposDeAgendamento(){
    return new Promise((resolve, reject) => {
        return api.get('/tipos-de-agendamento')
            .then(response => resolve(response))
            .catch(error => reject(error))
    });
}


function cadastrar(agendamento){
    agendamento = agendamento || {};
    return  new Promise((resolve, reject) =>{
      return api.post('/agendamento', agendamento)
      .then(response => resolve(response))
      .catch(error => reject(error))
    });
}
function atualizar(agendamento){
    agendamento = agendamento || {};
    return  new Promise((resolve, reject) =>{
      return api.put('/agendamento/'+ agendamento.id, agendamento)
      .then(response => resolve(response))
      .catch(error => reject(error))
    });
}


function deletar(idAgendamento){
      return  new Promise((resolve, reject) =>{
      return api.delete('/agendamento/'+ idAgendamento)
      .then(response => resolve(response))
      .catch(error => reject(error))
    });
}

export default {
    obterTodos,
    obterTiposDeAgendamento,
    cadastrar,
    atualizar,
    deletar
}