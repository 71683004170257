<template>
  <v-container class="mt-6">
     <v-snackbar
        v-model="snackbar"
        :vertical="false"
        :timeout="timeout"
        top="top"
        :color="tipo"
        elevation="5"
        multi-line
        right
        shaped
      >
        {{ mensagem }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false">OK</v-btn>
        </template>
      </v-snackbar>
    <v-row class="mb-6">
      <v-col cols="12" sm="12">
        <h1 class="titulo mb-0"> {{ modoEdicao ? 'Editar template' : 'Adicionar template' }}</h1>
        <v-divider></v-divider>
      </v-col>

      <v-col>
        <v-form ref="form" lazy-validation>
          <v-row>
            <v-col col="12" sm="4">
              <v-text-field
                id="textfield-descricao-tamplate"
                label="Descrição da template"
                v-model="template.descricao"
                :rules="[(v) => !!v || 'A descrição é obrigatória']"
              ></v-text-field>
            </v-col>
            <v-col col="12" sm="3">
              <v-autocomplete
                id="textfield-tipo-tamplate"
                label="Tipo de template"
                v-model="template.tipo"
                :items="['Ofícios', 'Moções', 'Mensagens', 'Outros']"
                :rules="[(v) => !!v || 'O tipo é obrigatório']"
              >
              </v-autocomplete>
            </v-col>

            <v-col col="12" sm="4">
              <v-switch
                v-model="template.principal"
                label="Definir como template principal?"
              ></v-switch>
            </v-col>

            <v-col col="12">
              <v-card width="100%" elevation="8">
                <editor-gestao
                  v-model="template.valor"
                  adicionarPluginInserirDados
                  :alturaDoEditor="800"
                ></editor-gestao>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              style="
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: center;
                align-content: flex-end;
              "
            >
              <v-spacer />
              <v-btn
                color="primary"
                class="btn-primario"
                id="btn-salvar-template"
                @click="salvar"
                :disabled="!template.modeloValido()"
              >
                Salvar
              </v-btn>
              <v-btn
                  class="btn-terciario ml-0"
                elevation="0"
                id="btn-cancelar-template"
                @click="cancelar"
              >
                Cancelar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>

        <alteracao-status
          idBotaoPrimario="btn-confirmar-cancelar-template"
          idBotaoSecundario="btn-desfaser-cancelamento-template"
          v-model="abrirJanela"
          :callbackPrimario="callback"
          :mensagem="mensagem"
        />
      </v-col>
    </v-row>
  </v-container >
</template>

<script>
// Componentes

import Editor from "@/components/comum/EditorTinymceGestao.vue";
import Template from "@/model/template-model";
import apiTemplate from "@/api/template-api";
import mixinMensagen from "../mixin/mixin-mensagem";
import mixinGeral from "../mixin/mixin-geral";
import AlteracaoStatus from '@/components/JanelaConfirmacao.vue'

export default {
  name: "ModeloTemplates",
  mixins: [mixinMensagen, mixinGeral],
  components: {
    "editor-gestao": Editor,
    "alteracao-status": AlteracaoStatus,
  },

  data() {
    return {
      template: new Template(),
      modoEdicao: false,
      abrirJanela: false,
      callback: () => {},
      mensagem: "Deseja cancelar a criação/edição da template?",
    };
  },

  mounted() {
    let id = this.$route.params.idTemplate;
    if (id) {
      this.modoEdicao = true;
      apiTemplate
        .obter(id)
        .then((resposta) => {
          this.template = new Template(resposta.data);
        })
        .catch(() => {
          this.dispararMensagem("Não foi possível obter a template para edição.","error");
        });
    } else {
      this.modoEdicao = false;
    }
  },

  methods: {
    salvar() {
      if (!this.template.modeloValido()) {
        this.dispararMensagem("Os campos descrição e tipo de template e texto são obrigatórios.");
        return;
      }

      if (this.modoEdicao) {
        apiTemplate
          .atualizar(this.template)
          .then(() => {
            this.template = new Template();
            this.voltarParaTelaDeTemplate();
          })
          .catch((erro) => {
            this.dispararMensagem(erro.response.data.mensagem || "Não foi possível atualizar a template.",'error');
          });
        return;
      }

      apiTemplate
        .cadastrar(this.template)
        .then(() => {
          this.template = new Template();
          this.voltarParaTelaDeTemplate();
        })
        .catch((erro) => {
          this.dispararMensagem(erro.response.data.mensagem || "Não foi possível cadastrar a template",'error');
        });
    },

    voltarParaTelaDeTemplate() {
      this.$router.push({
        name: "ControleDeTemplates",
      });
    },
    cancelar() {
      if (!this.template.modeloValido()) {
        this.voltarParaTelaDeTemplate();
        return;
      }

      this.abrirJanela = true;
      this.callback = () => {
        this.voltarParaTelaDeTemplate();
      };
    },
  },
};
</script>

<style lang="scss" scoped>
</style>