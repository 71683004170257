<template>
  <v-app :dark="!!(isDark === 'dark')">
    <Menu v-if="this.$router.currentRoute.name != 'Login' &&
             this.$router.currentRoute.name != 'Assinatura'" />
    <v-main :dark="!!(isDark === 'dark')"  >
    <v-container class="pa-0">
        <router-view />
     </v-container>
    </v-main>

  </v-app>
</template>

<script>

import Menu from './components/Menu';
import mixinGeral  from './mixin/mixin-geral';

export default {
  name: "App",
  mixins:[mixinGeral],

  components: { Menu },
  // computed:{
  //   mostrarRota() {
  //     return this.$router.currentRoute.name != "Login" &&
  //            this.$router.currentRoute.name != "Assinatura";
  //   },
  //   isDark () {
  //     return (this.$vuetify.theme.dark)
  //       ? 'dark'
  //       : 'light'
  //   }
  // },

  data(){
    return {
      emLogin: true,
    }
  },
};
</script>
<style lang="scss">


html { overflow-y: auto !important }

/* Caso não fique legal, remover esse cara. */
*:not(button):not(i){
  font-family: 'Quicksand', sans-serif !important;
}

%edit_and_delete {
  background-color: #ffff;
  color: #00A82D;
  text-transform: initial;
  border-radius: 2px;
  min-width: 85px;
  font-size: 13px;
  cursor: pointer;
  /* border:1px solid #275A7D; */
}
.btn-outline{
  border: 1px solid rgb(225, 225, 225);
}

.v-btn.btn-primary,
.v-btn:not(.v-btn--round).v-size--default  {
  text-transform: none;
  min-width: 110px;
}
.loading {
  margin-top: 100px;
  width: 100%;
}
.v-progress-circular {
  margin: 1rem;
}

body {
  /* font-family: 'Montserrat', sans-serif; */
  font-family: 'Mulish', sans-serif !important;
  font-size: 14px;
}

.v-btn {
  margin: 6px 8px;
  letter-spacing: normal;
  font-weight: 500
}
button.btn-edit-delete.theme--light,
a.btn-edit-delete.theme--light {
  @extend %edit_and_delete;
}

button.btn-edit-delete.theme--light,
a.btn-edit-delete.theme--light:hover,
button.btn-edit-delete-icon.theme--light.v-size--default, 
a.btn-edit-delete-icon.theme--light.v-size--default:hover{
  cursor: pointer;
}

button.btn-edit-delete-icon.theme--light.v-size--default, 
a.btn-edit-delete-icon.theme--light.v-size--default {
  @extend %edit_and_delete;
  height: 28px;
}

.v-btn:focus i.material-icons.seta-para-cima,
.v-btn i.material-icons.seta-para-baixo {
  display: inherit;
}

.v-btn i.material-icons.seta-para-cima,
.v-btn:focus i.material-icons.seta-para-baixo {
  display: none;
}

button.btn-opcoes.v-btn.v-btn--flat.v-btn--text.theme--light,
a.btn-opcoes.theme--light {
  background-color: #fff;
  text-transform: initial;
  border-radius: 2px;
  border: 2px solid  #f2f2f2;
  font-size: 13px;
  padding: 0px;
  font-weight: inherit;
  margin-right: 0px;
  margin-left: 0px;
  height: 28px;
}

button.btn-opcoes.v-btn--active:before,
button.btn-opcoes.v-btn:focus:before,
button.btn-opcoes.v-btn:hover:before {
  background: rgba(0, 0, 0, 0.2);
}

/* Alterando os scrolls */
::-webkit-scrollbar-track {
  background-color: #f2f2f2;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background: #f2f2f2;
}
::-webkit-scrollbar-thumb {
  background: #696969;
  border-radius: 10px;
}

.geral.v-chip:not(.v-chip--active){
  align-items: center;
  justify-content: center;
  min-width: 125px;  
  font-size: 13px;
  height: 25px;
  border-radius: 5px;
  padding: 12px;
}

footer{
  font-size: 11px;
}
.footer{
  width: 100%;
  text-align: end;
}

.titulo {
  color: #686868;
  font-weight: 400;
  font-size: 20px;
}

.titulo-2 {
  color: #A5A5A5;
  /* color:#00A82D; */
}

div.tipo-numero input {
  text-align: right;
}
div > a:hover,div > a:focus {
    color: #00A82D !important;
    text-decoration: none !important;
}

button.btn-tertiary.theme--light, a.btn-tertiary.theme--light {
  background-color: #FAFAFA;
  color: #00A82D;
  text-transform: initial;
  border-radius: 5px;
  border: 1px solid #e6e6e6;
  width: 105px;
  font-size: 13px;
}
button.btn-tertiary.theme--dark, a.btn-tertiary.theme--dark {
  background-color: transparent;
  color: #00A82D;
  text-transform: initial;
  border-radius: 2px;
  border: 1px solid #00A82D;
  width: 105px;
  font-size: 13px;
}

@media (min-width: 1264px){
  .container {
      max-width: 100%;
  }
}


td.bloco-expansivel-td {
    padding: 20px;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    background-color: #f5f5f5;
}


.v-data-table.theme--dark .bloco-expansivel-td,
.v-data-table.theme--dark .endereco-expansivel,
.v-textarea.theme--dark >div.v-input__control>div.v-input__slot{
  background: #121212;

}

div.solicitacao.v-textarea > div.v-input__control > div.v-input__slot textarea{
  cursor: default;
}


.titulo-modal {
  padding-bottom: 10px !important;
  color:#484848 !important;
  background: #ddd !important;
}

.titulo-modal-dark{
  padding-bottom: 10px !important;
  color: #00A82D;
}

// Remove o borda tracejada dos componentes quando estão disabled.
.theme--light.v-text-field.v-input--is-disabled .v-input__slot::before {
  border-image: none;
}

// Remove do textarea desabilitado o travamento do ponteiro, com isso
// é possível scrolar um textarea disabled.
.v-input--is-disabled:not(.v-input--is-readonly) {
    pointer-events: auto;
}

// Deixa as linhas dos inputs e textAreas disabled mais claras.
.v-input--is-disabled.theme--light.v-text-field:not(.v-input--has-state):hover > 
.v-input__control > .v-input__slot:before,
.v-input--is-disabled.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
    border-color: rgba(0, 0, 0, 0.26);
}

/* Trabalhando no textarea */

.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
      box-shadow: 0px 0px 1px -2px rgb(0 0 0 / 13%), 0px 1px 1px 0px rgb(0 0 0 / 8%), 0px 1px 1px -1px rgb(0 0 0 / 12%);
  border:1px solid #eee;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
   color:#343434
}
.theme--light.v-app-bar.v-toolbar.v-sheet{
  background-color: #FBFBFC;
}
.theme--light.v-application{
  // background-color:#fafafa;
}

.btn-opcoes.theme--dark.v-size--default{
  font-weight: 300;
  background: #1b1b1b;
  color: #ddd;
}

.v-data-table.theme--dark  tbody > tr {
  color: #ddd;
}

.loading-titulo{
  font-size: 1.2em;
  font-weight: 400;
}

.v-sheet.v-card:not(.v-sheet--outlined){
  box-shadow: 0px 1px 10px 0px rgb(0 0 0 / 10%);
}

.box {
  background-color: #fff;
  display: table;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
  padding: 0px 20px 20px 20px;
  border-radius: 8px;
}
// Ajuste na snackbar
.v-sheet.v-snack__wrapper.v-sheet--shaped{
  border-radius: 10px;
}


//Removendo uma div branca doida da tab, essa div não tem necessidade e quebra mobile.
.v-tabs:not(.v-tabs--vertical):not(.v-tabs--right) > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__prev {
    display: none;
}
</style>
